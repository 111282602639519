import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import PagePaper from './PagePaper';
import Title from './Title';

import Divider from '@mui/material/Divider';
import {PAGE_CONTACT} from '../const';
import {LoginButton} from './Breadcrumbs';
import {AccordionFaqPage} from './FaqAccordion';
import ResponsiveContainer from './ResponsiveContainer';
import {TypeOcrH4} from './Types';

function SimpleAccordionModules() {
  return (
    <>
      <ResponsiveContainer>
        <AccordionFaqPage name="What resources are there for making my own firmware?">
          Our platforms are built on the Daisy Seed. We have set up some
          examples in the{' '}
          <a
            href="https://github.com/electro-smith/libDaisy"
            target="_blank"
            rel="external noreferrer noopener"
          >
            libDaisy git repository.
          </a>{' '}
          Take a look there for everything you might need, and join the libDaisy
          Forum and Discord if you want to nerd out with some other cool makers!
          <br />
          Special thanks to Eris Fairbanks of{' '}
          <a
            href="https://oamodular.org/"
            target="_blank"
            rel="external noreferrer noopener"
          >
            Olivia Artz Modular
          </a>{' '}
          for creating the Legio example firmware!
        </AccordionFaqPage>
        <AccordionFaqPage name="Is [product] in stock?">
          Yes! If it is not, we will mark it out of stock! We have that
          technology.
        </AccordionFaqPage>
        <AccordionFaqPage name="Are you taking ideas for firmwares/new products?">
          We have lots planned for our platforms! We aren't currently soliciting
          ideas, but we always love to hear from you. Want to talk to us? Hit us
          up at the Contact us link on the Support page above.
        </AccordionFaqPage>
        <AccordionFaqPage name="My Versio or Legio says Antri on it. What's that about?">
          Each platform shares hardware across the firmwares. We call this base
          hardware Antri, either Antri Versio or Antri Legio. The numbered
          panels for these modules say Antri, and whichever hardware you choose,
          the PCBs say Antri.
        </AccordionFaqPage>
        <AccordionFaqPage name="How do I find the serial number on my module?">
          The SN is not printed on the PCB itself. Look for a sticker on the box
          or on the front of the PCB or back of the panel with a 3-4 digit
          number or a code starting with a letter followed by a 3-6 digit number
          (for example, B10020 or C030). It does not include the abbreviation of
          the module (e.g., BIA21 is not the serial number).
        </AccordionFaqPage>
        <AccordionFaqPage name="How do I request a feature for Univer Inter?">
          We'd love to hear what you'd like us to add! We expect to add
          alternate tunings in the coming months, and we have a few other things
          we have on the roadmap, but we want to hear from you. Hit that Contact
          Us button up top and get in touch!
        </AccordionFaqPage>
        <AccordionFaqPage name="How do I configure Cubase/Nuendo to send a MIDI Clock to UI?">
          In the Transport menu, click Project Synchronization Setup.<br></br>
          <br></br>
          In the menu that pops up, select the Destinations tab, and toggle on
          the following checkboxes:
          <ul>
            <li> Univer Inter in MIDI Clock Destinations </li>
            <li> MIDI Clock Follows Project Position </li>
            <li> Always Send Start Message</li>
          </ul>
        </AccordionFaqPage>
      </ResponsiveContainer>
    </>
  );
}
function SimpleAccordionSoftware() {
  return (
    <>
      <ResponsiveContainer>
        <AccordionFaqPage name="I can't install the plugins / the plugins don't show up.">
          That’s not a question, but we can help. Download the installer for
          your OS and run it.<br></br>
          <br></br>
          <i>If the installer is having an issue connecting:</i>
          <br></br>
          Please turn off pop-up blockers and firewalls. These can block your
          internet traffic, which prevent the installer from reaching our
          database and registering your products.<br></br>
          <br></br>
          <i>
            If you can’t find the products once they are installed, see the next
            FAQ!
          </i>
        </AccordionFaqPage>
        <AccordionFaqPage name="Where do the plugins install?">
          Our installer installs the plugins to the following locations
          specified by the formats. The format standards specify where to put
          files, and in turn, where DAWs should look for said files. If you move
          them, the installer will not be able to update them in a new location.
          AAX and AU files may not be moved. <br></br>
          <br></br>Mac: AAX: /Library/Application
          Support/Avid/Audio/Plug-Ins/Noise Engineering/Product Name.aaxplugin
          <br></br>
          AU2: Library/Audio/Plug-Ins/Components/ <br></br>VST3:
          /Library/Audio/Plug-Ins/VST3/ PC:<br></br>AAX: C:/Program Files/Common
          Files/Avid/Audio/Plug-Ins/Noise Engineering/<br></br> VST3: C:/Program
          Files/Common Files/VST3/Noise Engineering/ <br></br> <br></br>In
          Ableton Live, you may need to tell it to look for VST3 plugins and
          rescan. You can do this in the menu under
          Options/Preferences/Plug-Ins.
        </AccordionFaqPage>
        <AccordionFaqPage name="What are the system requirements for the plugins?">
          <b>System requirements:</b>
          <br></br> 64-bit only
          <br></br>Windows 10+ or MacOS 10.14+; Intel and M1 Native<br></br>
          <br></br> Our Intel compatible plugins require the SSE4.1 instruction
          set. This was added in the Intel 45nm Penryn architecture in 2007.
          Compatible AMD processors are Bulldozer, Piledriver, Steamroller and
          Ryzen families.
          <br></br>
          <br></br> In the Macverse any Mac built after 2010 should have the
          required instructions.
          <br></br>
          <br></br> <b>This is a minimum required specification.</b>
          <br></br>
          <br></br>
          For optimal experience we recommend Intel's Haswell or later and AMD's
          Ryzen family. We also recommend Apple's desktop CPUs (M1 and friends).
          <br></br>
          If you are unsure whether our plugins are compatible with your system,
          please download the free bundle from our Customer Portal and try it
          before you purchase any Noise Engineering plugins. If it installs and
          works, all of our plugins will work for you!
        </AccordionFaqPage>
        <AccordionFaqPage name="What DAWs and OS are supported?">
          Our plugins are most likely compatible with any major DAW, but we have
          tested them in Pro Tools, Logic, Bitwig Studio, Ableton Live, Reaper,
          and Cubase.<br></br>
          NI Komplete Kontrol is not yet supported. We have that on the roadmap
          but do not have a timeline.<br></br>
          If you are unsure whether our plugins are compatible with your DAW,
          please download the free bundle from our Customer Portal and try it
          before you purchase any Noise Engineering plugins. If it installs and
          works, all of our plugins will work for you!
        </AccordionFaqPage>
        <AccordionFaqPage name="Where do the presets save?">
          By default, presets are saved to the file paths below. You can save
          your own presets to this path, or to anywhere else on your computer.
          <br></br>
          <br></br>
          Mac: /Users/[name]/Library/Audio/Presets/Noise Engineering/[Product
          Name]<br></br>
          <br></br>
          PC: C:/Users/Public/Documents/Noise Engineering/[Product Name]
        </AccordionFaqPage>{' '}
        <AccordionFaqPage name="How do I uninstall plugins/presets?">
          Download the installer for NE Products from the Plugins tab, click
          online install, and you will be given the option to uninstall your
          plugins (and leave your presets), or uninstall plugins AND presets.
          Choose wisely. You can delete the installer after that.
        </AccordionFaqPage>{' '}
        <AccordionFaqPage name="Do I need an internet connection to run the plugins?">
          The plugins need a connection to download. Once the plugins are
          installed, they do not require an internet connection to operate.
          <br></br>
          <br></br>
          Mac: /Users/[name]/Library/Audio/Presets/Noise Engineering/[Product
          Name]<br></br>
          <br></br>
          PC: C:/Users/Public/Documents/Noise Engineering/[Product Name]
        </AccordionFaqPage>{' '}
      </ResponsiveContainer>
    </>
  );
}
function SimpleAccordionGeneral() {
  return (
    <>
      <ResponsiveContainer>
        <AccordionFaqPage name="Is shipping really that expensive? ">
          We use live shipping rates calculated by the shippers based on
          shipping weight and address. This includes a signature required
          charge. The fee calculated is what we are charged to ship your
          product. There is no handling fee. We have a great network of
          retailers who carry our products and who can also special order
          anything that may interest you if shipping to your location is
          prohibitively expensive.
        </AccordionFaqPage>
        <AccordionFaqPage name="I am a student / artist / educator and would like a discount.">
          We made the Freequel bundle for you! The workflow in the{' '}
          <a
            href="https://noiseengineering.us/products/the-freequel-bundle-sinc-vereor-virt-vereor-ruina"
            target="_blank"
            rel="external noreferrer noopener"
          >
            Freequel bundle
          </a>{' '}
          is exactly the same as it is in our other plugins, so if you're just
          starting out, trying to teach others about sound design, or just want
          to make great sounds, they are a great choice! They are immediate and
          did we mention they are free?<br></br>
          <br></br>
          We are unable to provide free or discounted hardware units at this
          time.<br></br>
          <br></br>
          If you are looking for bulk pricing for a university, please contact
          us below.
        </AccordionFaqPage>
        <AccordionFaqPage name="Are you hiring? Do you have any advice on how to get started?">
          We're not currently accepting applications, but when we are, we’ll
          post on all our social media channels!
          <br></br>
          <br></br>
          We don’t take interns because we don’t have the capacity to really do
          the position justice: It should be an educational role and we just
          don’t have that bandwidth.<br></br>
          <br></br>
          We do have{' '}
          <a
            href="https://noiseengineering.us/blogs/loquelic-literitas-the-blog/so-you-want-to-make-a-module"
            target="_blank"
            rel="external noreferrer noopener"
          >
            a whole blog post
          </a>{' '}
          about our thoughts on getting started in the module business. Take
          what you will from it, what works for one won't work for another.
        </AccordionFaqPage>
        <AccordionFaqPage name="What is the meaning of life, the universe and everything?">
          42.
        </AccordionFaqPage>
      </ResponsiveContainer>
    </>
  );
}
interface TabFaqProps {
  setCurrentTab: (s: string) => void;
}

export default function TabFaq(props: TabFaqProps) {
  return (
    <>
      <Title>FAQ</Title>
      <PagePaper>
        <ResponsiveContainer>
          <Typography sx={{mt: 4, mb: 2, width: '80%'}}>
            We've put together answers to a few of the more common questions we
            get asked. If you don't find an answer here, drop us a line with the
            Contact Us button!
          </Typography>
        </ResponsiveContainer>
        <Grid
          container
          style={{justifyContent: 'center', alignItems: 'center'}}
          columns={{xs: 6, md: 12}}
          sx={{mb: 4, mt: 4}}
        >
          <TypeOcrH4>Module FAQ</TypeOcrH4>
          <Divider
            component="div"
            role="presentation"
            style={{width: '80%'}}
            sx={{bgcolor: 'info.main', mb: 2}}
          />
          <SimpleAccordionModules></SimpleAccordionModules>
          <TypeOcrH4 sx={{mt: 4}}>Software FAQ</TypeOcrH4>{' '}
          <Divider
            component="div"
            role="presentation"
            style={{width: '80%'}}
            sx={{bgcolor: 'info.main', mb: 2}}
          />
          <SimpleAccordionSoftware></SimpleAccordionSoftware>
          <TypeOcrH4 sx={{mt: 4}}>General FAQ</TypeOcrH4>{' '}
          <Divider
            component="div"
            role="presentation"
            style={{width: '80%'}}
            sx={{bgcolor: 'info.main', mb: 2}}
          />
          <SimpleAccordionGeneral></SimpleAccordionGeneral>
        </Grid>
        <Box
          bgcolor="info.main"
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 2,
            width: '100%',
          }}
          sx={{minHeight: 200, mt: 3, mb: 1}}
        >
          <TypeOcrH4
            sx={{mt: 3}}
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="error.main"
          >
            Didn't find an answer?
          </TypeOcrH4>
          <Typography sx={{mt: 3}} color="error.main">
            Reach out to the team and we'll get back to you within two business
            days. <br></br>We're here to help!
          </Typography>{' '}
          <Button
            sx={{mt: 2, mb: 2}}
            variant="contained"
            color="error"
            disableElevation
            style={{borderRadius: 0}}
            onClick={(e: any) => props.setCurrentTab(PAGE_CONTACT)}
          >
            Get in touch
          </Button>
        </Box>{' '}
        <Box display="flex" justifyContent="center">
          <LoginButton setCurrentTab={props.setCurrentTab}></LoginButton>
        </Box>
      </PagePaper>
    </>
  );
}
