import MenuItem from '@mui/material/MenuItem';
import SqTextField from './SqTextField';
import UiLayoutConst from './UiLayoutConst';
import ControlledTooltip from './ControlledTooltip';
import {CCTOOL} from '../const';

interface UiFilterCC14Props {
  filter: number;
  setFilter: (n: number) => void;
  tooltipEnabled: boolean;
}

function UiFilterCC14(props: UiFilterCC14Props) {
  const lay = new UiLayoutConst();

  const valid = props.filter < 128;
  const invalidKey = valid ? 255 : props.filter;

  const ccs = Array.from({length: 32}, (x, i) => i);

  return (
    <ControlledTooltip
      title={CCTOOL}
      placement="top"
      arrow={true}
      tooltipEnabled={props.tooltipEnabled}
    >
      <SqTextField
        select
        label="CC#"
        size="small"
        onChange={(e: any) => {
          props.setFilter(parseInt(e.target.value));
        }}
        sx={{mx: lay.mx, my: lay.my, width: 6 * 17}}
        value={props.filter}
      >
        <MenuItem key={'invalid cc'} value={invalidKey}>
          {'None'}
        </MenuItem>
        {ccs.map((key: any) => {
          const text = key + '-' + (key + 32);
          return (
            <MenuItem key={key + 'cc'} value={key}>
              {text}
            </MenuItem>
          );
        })}
      </SqTextField>
    </ControlledTooltip>
  );
}

export default UiFilterCC14;
