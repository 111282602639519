import SqButton from './SqButton';

interface UiPresetProps {
  name: string;
  url: string;
  load: (conf: Uint8Array) => void;
}

export default function UiPreset(props: UiPresetProps) {
  const presetMargin = 0.5;
  const pbStyle = {minWidth: 125, maxHeight: 25};

  async function onClick(e: any) {
    const result = await fetch(props.url);
    const buffer = await result.arrayBuffer();
    const array = new Uint8Array(buffer);
    props.load(array);
  }

  return (
    <SqButton
      variant="outlined"
      sx={{m: presetMargin, whiteSpace: 'nowrap'}}
      style={pbStyle}
      onClick={onClick}
    >
      {props.name}
    </SqButton>
  );
}
