import React from 'react';


interface PageSelectProps {
    index: string;
    current: string;
};

export default function PageSelect(props: React.PropsWithChildren<PageSelectProps>) {
    if (props.index === props.current) {
        return <>{props.children}</>;
    }
    else {
        return <></>
    }
}