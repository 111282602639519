import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UiConfigEdit from './UiConfigEdit';
import Title from './Title';
import PagePaper from './PagePaper';
import Container from '@mui/material/Container';
import FaqAccordion from './FaqAccordion';
import {TypeOcrH6, TypeOcrB2} from './Types';
import IfNotChrome from './IfNotChrome';
import ResponsiveContainer from './ResponsiveContainer';
import SqButton from './SqButton';

function SimpleAccordion() {
  return (
    <>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          maxWidth: '85%',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 2,
        }}
      >
        <FaqAccordion name="What do I do if I can't get my module to connect?">
          UI can't connect if the DAW or SysEx tool is open. Please close those
          to connect and configure your module. In some cases, USB extenders or
          very long cables may interfere with connectivity. <p></p>
          <p></p>
          Also make sure that WebMIDI has been enabled. Even if you have enabled
          it previously, some browswers will disable WebMIDI on given sites (ask
          us how we know...). To re-enable, click the padlock in the address bar
          of the browser and click the switch labeled "MIDI devices."<p></p>
          <p></p>
          Finally, ensure you are not sending any data via TRS while you are
          flashing your module. This can cause conflict and no one likes
          conflict.
        </FaqAccordion>
        <FaqAccordion name="My UI update freezes while erasing. What do I do?">
          Some USB controllers are not compatible with the Device Firmware
          Update standard. This is a weird but known issue with an easy
          workaround: plug in a hub and then plug the module into the hub. It
          will solve this problem.
        </FaqAccordion>
        <FaqAccordion name="I want to chain two UI. How do I connect a second UI?">
          If you are chaining two UI, the configuration will live on the first
          one, so all you need to do is hook one up and program it. Then connect
          the MIDI Out to the MIDI in of the follower module with a TRS cable,
          and you're all set!<br></br>
          <br></br>
          If you're using Windows, please connect only one UI to the app.
          Windows doesn't know what to do with more than one and you may get
          unexpected results. This ONLY applies to the app. Connect as many UI
          to your DAW as you would like!
        </FaqAccordion>
        <FaqAccordion name="Which MIDI standard does the UI TRS input use?">
          Univer Inter uses MIDI A. The cable that comes with the module is MIDI
          A, but any MIDI A adapter will do!
        </FaqAccordion>
      </Container>{' '}
    </>
  );
}

export default function TabUniverInter() {
  return (
    <>
      <Title>Univer Inter</Title>
      <PagePaper>
        <IfNotChrome>
          <b>
            This feature is only supported on Google Chrome and other
            Chromium-based browsers that support webUSB.
          </b>
        </IfNotChrome>
        <br />
        <TypeOcrB2 sx={{color: 'success.main'}}></TypeOcrB2> <br />
        <TypeOcrB2 sx={{mb: 3}}></TypeOcrB2>
        <UiConfigEdit />
        <ResponsiveContainer>
          <Box sx={{mt: 2}}></Box>
          <Grid
            container
            style={{justifyContent: 'center', alignItems: 'center'}}
            columns={{xs: 3, sm: 6, md: 12}}
            sx={{mb: 2}}
          >
            <Grid
              xs={12}
              style={{display: 'flex', justifyContent: 'space-around'}}
            >
              <SqButton
                sx={{mb: 3, mr: 0}}
                onClick={() =>
                  window.open(
                    'https://www.noiseengineering.us/products/univer-inter' +
                      '?rel="noopener noreferrer"',
                    '_blank'
                  )
                }
              >
                Product Page
              </SqButton>
              <SqButton
                sx={{mb: 3, mr: 0}}
                onClick={() =>
                  window.open(
                    'https://manuals.noiseengineering.us/ui/' +
                      '?rel="noopener noreferrer"',
                    '_blank'
                  )
                }
              >
                Univer Inter Manual
              </SqButton>
            </Grid>
            <Grid xs={3}>
              <ResponsiveContainer maxWidth="85%">
                <Typography component="div" sx={{m: 3, mr: 5}} align="center">
                  <TypeOcrH6 align="center"> Troubleshoot</TypeOcrH6>
                  You have questions? We have answers. Still not sorted? Get in
                  touch with the button at the top of the page.
                </Typography>
              </ResponsiveContainer>
            </Grid>
            <Grid xs={9}>
              <SimpleAccordion></SimpleAccordion>
            </Grid>
          </Grid>
        </ResponsiveContainer>
      </PagePaper>
    </>
  );
}
