import MenuItem from '@mui/material/MenuItem';
import SqTextField from './SqTextField';
import UiLayoutConst from './UiLayoutConst';
import ControlledTooltip from './ControlledTooltip';
import {STARTNOTETOOL} from '../const';

const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];

interface UiNoteSelectProps {
  allowAny: boolean;
  suffix: string;
  semitone: number;
  setSemitone: (s: number) => void;
  tooltipEnabled: boolean;
}

function UiNoteSelectItem(ms: number) {
  return (
    <MenuItem key={ms + 'semi'} value={ms}>
      {notes[ms]}
    </MenuItem>
  );
}

function UiNoteSelect(props: UiNoteSelectProps) {
  const lay = new UiLayoutConst();
  return (
    <ControlledTooltip
      title={STARTNOTETOOL}
      placement="top"
      arrow={true}
      tooltipEnabled={props.tooltipEnabled}
    >
      <SqTextField
        select
        label={props.suffix + ' Note'}
        size="small"
        onChange={(e: any) => {
          props.setSemitone(e.target.value);
        }}
        value={props.semitone}
        sx={{mx: lay.mx, my: lay.my, width: 6 * 15}}
      >
        {UiNoteSelectItem(0)}
        {UiNoteSelectItem(1)}
        {UiNoteSelectItem(2)}
        {UiNoteSelectItem(3)}
        {UiNoteSelectItem(4)}
        {UiNoteSelectItem(5)}
        {UiNoteSelectItem(6)}
        {UiNoteSelectItem(7)}
        {UiNoteSelectItem(8)}
        {UiNoteSelectItem(9)}
        {UiNoteSelectItem(10)}
        {UiNoteSelectItem(11)}
        {props.allowAny && (
          <MenuItem key={'any semi'} value={255}>
            Any
          </MenuItem>
        )}
      </SqTextField>
    </ControlledTooltip>
  );
}

export default UiNoteSelect;
