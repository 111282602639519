import Title from './Title';
import PagePaper from './PagePaper';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import getUserInfo from '../ostium/UserInfo';
import If from './If';
import Image from 'mui-image';

import Installers from './Installers';

import {TypeOcrH6} from './Types';

export default function TabRedeem() {
  const user = getUserInfo();
  const success: boolean = user.redeemRes
    ? user.redeemRes.status === 'success'
    : false;

  return (
    <>
      <Title>Key Redeem</Title>
      <PagePaper>
        <Container sx={{mt: 3, mb: 3}}>
          <Stack
            sx={{mb: 8}}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: 1000,
            }}
          >
            <If value={success}>
              <TypeOcrH6 sx={{mt: 3}} align="center">
                Key redeemed! <br />
              </TypeOcrH6>
              <Stack direction="row" sx={{mt: 3}}>
                <Installers></Installers>
              </Stack>
            </If>

            <If value={!success}>
              <TypeOcrH6 sx={{m: 3}} align="center">
                {user.redeemRes?.message}
                <br />
                <br /> Need assistance? Hit the Support button up top.
              </TypeOcrH6>
            </If>
            <Image
              src="/panels/feetbat.png"
              alt="I am Feetbat. Behold my toes."
              width={400}
            ></Image>
          </Stack>
        </Container>
      </PagePaper>
    </>
  );
}
