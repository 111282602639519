
import Title from './Title';
import PagePaper from './PagePaper';
import Container from '@mui/material/Container';
import { TypeOcrH6 } from './Types';
import Button from '@mui/material/Button';

interface TabResetOnClickProps {
    setPage: (s: string) => void;
}

export default function TabResetOnClick(props: TabResetOnClickProps) {
    return (<>

        <Container>

            <Title>Password reset requested</Title>
            <PagePaper>
                <TypeOcrH6 sx={ { pt: 3 } } align="center"  >
                    Check your email for a link to reset your password. If you have decided you like your current password, then you can just ignore that email and get on with your day.
                </TypeOcrH6>
                <img src="/panels/feetbat.png" alt="I am Feetbat. Behold my toes."></img>
                <Container>
                    <Button size="large" onClick={ () => props.setPage('login') } >Back to Login</Button>
                </Container>
            </PagePaper>
        </Container >
    </>);
}


