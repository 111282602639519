import * as React from 'react';
import Container from '@mui/material/Container';

import PagePaper from './PagePaper';
import If from './If';
import CreateAccount from './CreateAccount';
import LoginIdPw from './LoginIdPw';

import {TypeOcrH5, TypeOcr} from './Types';

import getUserInfo from '../ostium/UserInfo';

export const MODE_LOGIN = 'login';
export const MODE_CREATE = 'create';
export const MODE_DONE = '';

interface TabLoginProps {
  setPage: (s: string) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (b: boolean) => void;
}

export default function TabLogin(props: TabLoginProps) {
  const [email, setEmail] = React.useState('');
  const [pw, setPw] = React.useState('');
  const [pwc, setPwc] = React.useState('');
  const [isLogin, setIsLogin] = React.useState(true);
  const [subtext, setSubtext] = React.useState(' ');
  const [disabled, setDisabled] = React.useState(false);

  getUserInfo().setUpdate(
    () => {
      setEmail('');
      setPw('');
      setPwc('');
      setIsLogin(true);
      setDisabled(false);
      props.setIsLoggedIn(false);
    },
    (s: string) => {
      setSubtext(s);
    },
    (p: string) => {
      props.setPage(p);
    },
    (b: boolean) => {
      setDisabled(b);
    },
    (b: boolean) => {
      props.setIsLoggedIn(b);
    }
  );

  return (
    <>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <PagePaper sx={{mt: '10'}}>
          <TypeOcrH5 sx={{pt: 2, m: 3}} align="center">
            {' '}
            Welcome to the Noise Engineering Customer Portal
          </TypeOcrH5>
          <If value={isLogin}>
            <LoginIdPw
              email={email}
              disabled={disabled}
              pw={pw}
              enableCreate={() => {
                setIsLogin(false);
              }}
              setEmail={setEmail}
              setPw={setPw}
              subtext={subtext}
              setSubtext={setSubtext}
              setPage={props.setPage}
            />
          </If>
          <If value={!isLogin}>
            <CreateAccount
              email={email}
              disabled={disabled}
              pw={pw}
              pwc={pwc}
              enableLogin={() => {
                setIsLogin(true);
              }}
              setEmail={setEmail}
              setPw={setPw}
              setPwc={setPwc}
              subtext={subtext}
              setSubtext={setSubtext}
            />
          </If>
          <TypeOcr>
            Enter your email if you need to change your password.<br></br>
            <br></br> Still need help? Click Support.
          </TypeOcr>
        </PagePaper>
      </Container>
    </>
  );
}
