import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
//import useMediaQuery from '@mui/material/useMediaQuery';

export default function NeTheme(props: any) {
  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = createTheme({
    palette: {
      //mode: prefersDarkMode ? 'dark' : 'light',
      primary: {main: '#71389c'},
      secondary: {main: '#a882c4'},
      error: {main: '#FFFFFF'},
      warning: {main: '#c0c0c0'},
      info: {main: '#000000'},
      success: {main: '#e340a2'},
    },
  });

  //console.log(theme.palette);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
