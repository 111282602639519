import { PUBLIC_RELEASE } from '../const';

export default class Installer {
    release: string;
    os: string;
    use_bucket: boolean;
    bucket: string;
    version: string;
    filename: string;
    code: string;
    url: string;

    equals(that: Installer): boolean {
        return this.release === that.release
            && this.os === that.os
            && this.version === that.version
            && this.code === that.code;
    }

    static uniq(insts: Installer[]): Installer[] {

        const u: Installer[] = [];

        for (const i of insts) {
            let has = false;

            for (const t of u) {
                if (t.equals(i)) {
                    has = true;
                }
            }

            if (!has) {
                u.push(i)
            }
        }

        return u;
    }

    static fromMeta(meta: any) {
        return new Installer(meta.category, meta.os, true, meta.bucket, meta.filename, meta.version, meta.code, meta.url);
    }

    constructor(
        release: string,
        os: string,
        use_bucket: boolean,
        bucket: string,
        filename: string,
        version: string,
        code: string,
        url: string
    ) {
        this.release = release;
        this.os = os;
        this.use_bucket = use_bucket;
        this.bucket = bucket;
        this.version = version;
        this.filename = filename;
        this.code = code;
        this.url = url;
    }

    toString() { return `${this.getPrettyOs()} ${this.version} ${this.getPrettyRelease()}`; }

    getPrettyOs(): string {
        if (this.os === "osx") { return "Mac 10.14+ (Intel 64 and Arm 64)"; }
        if (this.os === "w64") { return "Windows 10+ (Intel 64)"; }
        return this.os;
    }

    getPrettyRelease() {
        if (this.release === "public") { return ""; }
        else { return this.release; }
    }


    static getPublic() {
        let fws: Installer[] = [];

        for (let x of PUBLIC_RELEASE) {
            if (x.format === "inst") {
                fws.push(new Installer(
                    "public",
                    x.os,
                    false,
                    x.bucket,
                    x.filename,
                    x.version,
                    x.code,
                    "release/" + x.file
                ));
            }
        }

        return fws;
    }
};
