import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { UserInfo } from '../ostium/UserInfo'
import getUserInfo from '../ostium/UserInfo'
import fb from '../ostium/firebase';
import {
    signInWithEmailAndPassword,
} from "firebase/auth";
import SqTextField from "./SqTextField";
import { TypeOcrH6 } from "./Types";

interface LoginIdPwProps {
    email: string;
    disabled: boolean;
    pw: string;
    enableCreate: () => void;
    setEmail: (s: string) => void;
    setPw: (s: string) => void;
    setSubtext: (s: string) => void;
    subtext: string;
    setPage: (s: string) => void;
}

export default function LoginIdPw(props: LoginIdPwProps) {
    async function handleLogin(): Promise<void> {
        try {
            await signInWithEmailAndPassword(fb.auth, props.email.trim(), props.pw);
        } catch (e: any) {

            if (e.code === "auth/user-not-found") {
                props.setSubtext(props.email + " User not found. Please create an account.");
                props.enableCreate();
            } else {
                console.log(e);
                props.setSubtext(e.message);
            }
        }
    }

    return (
        <>
            <TypeOcrH6>Login</TypeOcrH6>
            <Stack justifyContent="center" alignItems="center">
                <Typography>{ props.subtext.trim() }</Typography>
                <Box sx={ { height: 5, mb: -1 } } justifyContent="center" alignItems="center">
                    <LinearProgress
                        sx={ { display: getUserInfo().loading ? 'any' : 'none' } }
                        style={ { width: 300 } } />
                </Box>
            </Stack >
            <Grid container spacing={ 2 } sx={ { mb: 3 } } justifyContent="center" alignItems="center">
                <Grid item xs={ 12 } sx={ { height: 30 } }>
                    { }
                </Grid><Grid item xs={ 12 }>
                    <SqTextField
                        autoFocus
                        margin="dense"
                        id="textEmail"
                        name="textEmail"
                        label="Email Address"
                        type="email"
                        variant="outlined"
                        onChange={ (e: any) => props.setEmail(e.target.value) }
                        value={ props.email }
                        disabled={ props.disabled }
                        sx={ { mt: 1, } }
                        style={ { width: '60%' } }
                        onKeyUp={ (e: any) => { if (e.keyCode === 13) { handleLogin(); } } }
                    />
                </Grid><Grid item xs={ 12 }>
                    <SqTextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        onChange={ (e: any) => props.setPw(e.target.value) }
                        value={ props.pw }
                        style={ { width: '60%' } }
                        disabled={ props.disabled }
                        onKeyUp={ (e: any) => { if (e.keyCode === 13) { handleLogin(); } } }
                    />
                </Grid><Grid item xs={ 12 } sm={ 4 }>
                    <Button size="small" onClick={ () => props.enableCreate() } disabled={ props.disabled }>Create Account</Button>
                </Grid><Grid item xs={ 12 } sm={ 4 }>
                    <Button
                        variant="text"
                        size="large"
                        sx={ { m: 0 } }
                        onClick={ () => { UserInfo.resetPassword(props.email); props.setPage("resetclick") } }
                        disabled={ props.email.trim() === "" || !props.email.includes("@") || props.disabled } >
                        Change Password
                    </Button>
                </Grid><Grid item xs={ 12 } sm={ 4 }>
                    <Button size="small" onClick={ () => handleLogin() } disabled={ props.email.trim() === "" || props.pw.trim() === "" || props.disabled }>Login</Button>
                </Grid>
            </Grid >
        </>)
}