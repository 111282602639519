import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

import FIREBASE_CONFIG from './firebase_config';

const conf = FIREBASE_CONFIG;
const app = initializeApp(conf);
const auth = getAuth();
const firestore = getFirestore();
const storage = getStorage();

const fb = {
  conf: conf,
  app: app,
  auth: auth,
  firestore: firestore,
  storage: storage,
  isProd: conf.isProd,
  isDev: conf.isDev,
  isTest: conf.isTest,
};

export default fb;
