import Title from './Title';
import PagePaper from './PagePaper';
import {TypeOcrH6} from './Types';

export default function TabRedirect() {
  return (
    <>
      <Title>You have been logged in!</Title>
      <PagePaper>
        <TypeOcrH6 sx={{pt: 3, m: 2}} align="center">
          Thanks for authenticating! Please return to the installer and follow
          the instructions there to update or install your plugins.
        </TypeOcrH6>
        <img
          src="/panels/feetbat.png"
          alt="I am Feetbat. Behold my toes."
        ></img>
      </PagePaper>
    </>
  );
}
