import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ControlledTooltip from './ControlledTooltip';

import {TypeOcr} from './Types';
import If from './If';
import SqPaper from './SqPaper';
import SqTextField from './SqTextField';
import router from '../ostium/UiMidiRouter';
import UiBendAmount from './UiBendAmount';
import UiClockDiv from './UiClockDiv';
import UiFilterCC from './UiFilterCC';
import UiFilterCC14 from './UiFilterCC14';
import UiEnvEdit from './UiEnvEdit';
import UiMinChannel from './UiMinChannel';
import UiLayoutConst from './UiLayoutConst';
import UiOctaveNote from './UiOctaveNote';
import SqButton from './SqButton';
import UiOutput from '../ostium/UiOutput';
import UiBehavior from '../ostium/UiBehavior';

import {
  behGetByNum,
  behGetFromCat,
  behGetCatNames,
  behGetFirstInCat,
} from '../ostium/UiBehavior';
import {
  CHANNELTOOL,
  DELAYTOOL,
  COPYTOOL,
  PASTETOOL,
  SHAPETOOL,
  LEARNTOOL,
} from '../const';

const channels: any = {
  '1': 0,
  '2': 1,
  '3': 2,
  '4': 3,
  '5': 4,
  '6': 5,
  '7': 6,
  '8': 7,
  '9': 8,
  '10': 9,
  '11': 10,
  '12': 11,
  '13': 12,
  '14': 13,
  '15': 14,
  '16': 15,
};

var cc14Channel = -1;
var cc14Controller = -1;
interface UiOutputEditProps {
  index: number;

  behavior: number;
  channel: number;
  filter: number;
  delay: number;
  scale: number;

  offset: number;
  smooth: number;
  volume: number;

  disabled: boolean;
  voiceNumber: number;

  setOutput: (obj: any) => void;
  tooltipEnabled: boolean;
}

var copyOutput: any = undefined;

function UiOutputAllEdit(props: UiOutputEditProps) {
  const lay = new UiLayoutConst();
  const out = UiOutput.fromObj(props);

  const setProp = (name: string, value: any) => {
    const o: any = out;
    o[name] = value;

    props.setOutput(out);
  };

  const setDefaults = (beh: number) => {
    out.behavior = beh;
    out.defaults(beh);
    props.setOutput(out);
  };

  const setChannel = (s: number) => {
    setProp('channel', s);
  };

  const setDelay = (s: number) => {
    setProp('delay', s);
  };

  const setScale = (s: number) => {
    setProp('scale', s);
  };

  const setFilter = (s: number) => {
    setProp('filter', s);
  };

  const setOffset = (s: number) => {
    setProp('offset', s);
  };

  const setBehavior = (s: number) => {
    setProp('behavior', s);
    if (s !== props.behavior) {
      setDefaults(s);
    }
  };

  const setSmooth = (s: number) => {
    setProp('smooth', s);
  };

  const setVolume = (s: number) => {
    setProp('volume', s);
  };

  const beh = behGetByNum(props.behavior);
  const category = beh.category;
  const name = beh.name;
  const subbeh = behGetFromCat(category);

  const setCategory = (e: any) => {
    const c = e.target.value;
    setBehavior(behGetFirstInCat(c).code);
  };
  const isUsed = (b: UiBehavior) => {
    if (isChained) return b.usedInChained;
    return true;
  };

  const [learning, setLearning] = React.useState(false);

  const learnCallback = (
    type: string,
    channel: number,
    data1: number,
    data2: number
  ) => {
    console.log(type, channel, data1, data2);

    if (type === 'cc') {
      if (name === 'CC') {
        setChannel(channel);
        setFilter(data1);
        setLearning(false);
      } else if (name.includes('14-bit')) {
        if (cc14Channel >= 0) {
          if (cc14Controller + 32 === data1 && cc14Controller < 32) {
            setFilter(cc14Controller);
            cc14Channel = -1;
            setLearning(false);
          }
          if (data1 + 32 === cc14Controller && data1 < 32) {
            setFilter(data1);
            cc14Channel = -1;
            setLearning(false);
          }
        }

        cc14Channel = channel;
        cc14Controller = data1;
      }
    }
    if (type === 'note on') {
      if (category === 'Note' || category === 'Poly' || category === 'MPE') {
        setChannel(channel);
        if (
          name === 'Gate' ||
          name === 'Trigger' ||
          name === 'Velocity Gate' ||
          name === 'Decay'
        ) {
          setFilter(data1);
        } else {
          setOffset(data1);
        }
        setLearning(false);
      }
    }

    if (category === 'Bend' && type === 'bend') {
      setChannel(channel);
      setLearning(false);
    }
  };

  router.setLearnCallback(learning ? learnCallback : undefined);

  const learnClick = () => {
    setLearning(!learning);
  };

  const categoryItems = () => {
    return behGetCatNames(isUsed).map((cat: string) => {
      return (
        <MenuItem key={cat + 'category'} value={cat}>
          {cat}
        </MenuItem>
      );
    });
  };

  const behaviorItems = (voiceNumber: number, currBeh: number) => {
    return subbeh.map((beh: UiBehavior) => {
      return (
        <MenuItem key={'beh' + beh.code} value={beh.code}>
          {voiceNumber === 0 || currBeh !== beh.code
            ? beh.name
            : beh.name + ' (' + voiceNumber + ')'}
        </MenuItem>
      );
    });
  };

  const channelItems = () => {
    return Object.keys(channels).map((value: any) => {
      return (
        <MenuItem key={channels[value]} value={channels[value]}>
          {value}
        </MenuItem>
      );
    });
  };

  const w0 = 4 * 17;
  const w2 = 9 * 17;
  const w3 = 5 * 17;

  const sxw0 = {mx: lay.mx, my: lay.my, width: w0};
  const sxw2 = {mx: lay.mx, my: lay.my, width: w2};
  const sxw3 = {mx: lay.mx, my: lay.my, width: w3};

  const UiCategory = () => {
    return (
      <ControlledTooltip
        title={beh.tooltipCategory}
        placement="top"
        arrow={true}
        tooltipEnabled={props.tooltipEnabled}
      >
        <SqTextField
          select
          label={'Category'}
          onChange={setCategory}
          value={category}
          sx={{...sxw2}}
        >
          {categoryItems()}
        </SqTextField>
      </ControlledTooltip>
    );
  };

  const UiBehavior = () => {
    return (
      <ControlledTooltip
        title={beh.tooltip}
        placement="top"
        arrow={true}
        tooltipEnabled={props.tooltipEnabled}
      >
        <SqTextField
          select
          label={'Behavior'}
          onChange={(e: any) => setBehavior(e.target.value)}
          value={props.behavior}
          sx={sxw2}
        >
          {behaviorItems(props.voiceNumber, props.behavior)}
        </SqTextField>
      </ControlledTooltip>
    );
  };

  const UiChannel = () => {
    return (
      <>
        <If value={beh.hasChannel}>
          <ControlledTooltip
            title={CHANNELTOOL}
            placement="top"
            arrow={true}
            tooltipEnabled={props.tooltipEnabled}
          >
            <SqTextField
              select
              label="Channel"
              onChange={(e: any) => {
                setChannel(e.target.value);
              }}
              value={props.channel}
              sx={{mx: lay.mx, my: lay.my, width: 6 * 17}}
            >
              {channelItems()}
            </SqTextField>
          </ControlledTooltip>
        </If>
        <If
          value={
            (beh.hasShape && category === 'Note') ||
            (beh.hasShape && category === 'MPE') ||
            (beh.hasShape && category === 'Poly')
          }
        >
          <ControlledTooltip
            title={SHAPETOOL}
            placement="top"
            arrow={true}
            tooltipEnabled={props.tooltipEnabled}
          >
            <SqTextField
              select
              label="Shape"
              onChange={(e: any) => {
                setOffset(e.target.value);
              }}
              value={props.offset}
              sx={{mx: lay.mx, my: lay.my, width: 6.5 * 17}}
            >
              <MenuItem value={2}>Up</MenuItem>
              <MenuItem value={3}>Down</MenuItem>
              <MenuItem value={4}>Triangle</MenuItem>
            </SqTextField>
          </ControlledTooltip>
        </If>

        <If value={beh.hasShape && category === 'Sync'}>
          <ControlledTooltip
            title={SHAPETOOL}
            placement="top"
            arrow={true}
            tooltipEnabled={props.tooltipEnabled}
          >
            <SqTextField
              select
              label="Shape"
              onChange={(e: any) => {
                setOffset(e.target.value);
              }}
              value={props.offset}
              sx={{mx: lay.mx, my: lay.my, width: 6.5 * 17}}
            >
              <MenuItem value={0}>Trigger</MenuItem>
              <MenuItem value={1}>Gate</MenuItem>
              <MenuItem value={2}>Up</MenuItem>
              <MenuItem value={3}>Down</MenuItem>
              <MenuItem value={4}>Triangle</MenuItem>
            </SqTextField>
          </ControlledTooltip>
        </If>
      </>
    );
  };
  // alignment boxes to be removed if new layout works
  // <Box width={beh.align} sx={{border: 1}} />
  /*       <If value={!beh.hasShape && !beh.hasChannel}>
          <Box sx={{mx: lay.mx, my: lay.my, width: 6 * 17, border: 1}}></Box>
        </If>
  */
  const UiStartNote = () => {
    return (
      <If value={beh.hasStart}>
        <UiOctaveNote
          allowAny={false}
          suffix="Start"
          offset={props.offset}
          setOffset={setOffset}
          tooltipEnabled={props.tooltipEnabled}
        />
      </If>
    );
  };

  const UiScale = () => {
    return (
      <>
        <If value={beh.hasBendScale}>
          <UiBendAmount
            bend={props.scale}
            setBend={setScale}
            tooltipEnabled={props.tooltipEnabled}
          />
        </If>
        <If value={beh.hasClockDiv}>
          <UiClockDiv
            bend={props.scale}
            setBend={setScale}
            tooltipEnabled={props.tooltipEnabled}
          />
        </If>
      </>
    );
  };

  const UiFilter = () => {
    return (
      <>
        <If value={beh.hasFilterCC && beh.code === 13}>
          <UiFilterCC
            filter={props.filter}
            setFilter={setFilter}
            tooltipEnabled={props.tooltipEnabled}
          />
        </If>
        <If value={beh.hasFilterCC && beh.code === 37}>
          <UiFilterCC14
            filter={props.filter}
            setFilter={setFilter}
            tooltipEnabled={props.tooltipEnabled}
          />
        </If>
        <If value={beh.hasNoteFilter}>
          <UiOctaveNote
            allowAny={true}
            suffix=""
            offset={props.filter}
            setOffset={(s: number) => {
              setFilter(s);
            }}
            tooltipEnabled={props.tooltipEnabled}
          />
        </If>
      </>
    );
  };
  const MinMaxFilter = () => {
    return (
      <>
        <If value={beh.hasMinLength}>
          <UiEnvEdit
            smooth={props.smooth}
            setSmooth={setSmooth}
            tooltipEnabled={props.tooltipEnabled}
          />
        </If>
      </>
    );
  };

  const MinMaxChannel = () => {
    return (
      <>
        <If value={beh.hasChannelMin}>
          <UiMinChannel
            smooth={props.smooth}
            setSmooth={setSmooth}
            volume={props.volume}
            setVolume={setVolume}
            tooltipEnabled={props.tooltipEnabled}
          />
        </If>
      </>
    );
  };

  const delays = [0, 1, 2, 3, 4, 5, 10, 15, 20, 25];

  const DelayItem = (ms: number) => {
    return (
      <MenuItem key={ms + 'delay'} value={ms}>
        {ms + ' ms'}
      </MenuItem>
    );
  };
  const isChained = props.index > 8;

  const UiDelay = () => {
    return (
      <>
        <If value={!isChained}>
          {' '}
          <ControlledTooltip
            title={DELAYTOOL}
            placement="top"
            arrow={true}
            tooltipEnabled={props.tooltipEnabled}
          >
            <SqTextField
              select
              label="Delay"
              size="small"
              onChange={(e: any) => {
                setDelay(e.target.value);
              }}
              value={props.delay}
              sx={{mx: lay.mx, my: lay.my, width: 6 * 17}}
            >
              {delays.map(DelayItem)}
            </SqTextField>
          </ControlledTooltip>
        </If>
        <If value={isChained}>
          <Box sx={{mx: lay.mx, my: lay.my, width: 6 * 17}}></Box>
        </If>
      </>
    );
  };

  const copyButton = () => {
    copyOutput = UiOutput.fromObj(props);
  };

  const pasteButton = () => {
    if (copyOutput) {
      setBehavior(copyOutput.behavior);
      setChannel(copyOutput.channel);
      setDelay(copyOutput.delay);
      setScale(copyOutput.scale);
      setFilter(copyOutput.filter);
      setOffset(copyOutput.offset);
      setSmooth(copyOutput.smooth);
      setVolume(copyOutput.volume);
    }
  };

  return (
    <>
      <SqPaper
        sx={{m: 0, width: '450px', height: 165}}
        elevation={4}
        component="fieldset"
      >
        <legend>
          <TypeOcr>Out {props.index}</TypeOcr>
        </legend>

        <Stack direction="row" justifyContent="left" alignItems="center">
          <UiCategory />
          <UiBehavior />
          <UiDelay />
        </Stack>

        <Stack direction="row" justifyContent="left" alignItems="center">
          <UiStartNote />
          <UiChannel />

          <UiScale />
          <UiFilter />
        </Stack>
        <Stack direction="row" justifyContent="left" alignItems="center">
          <MinMaxFilter />
          <MinMaxChannel />
        </Stack>
        <Stack direction="row" justifyContent="left" alignItems="center">
          <ControlledTooltip
            title={COPYTOOL}
            placement="bottom"
            arrow={true}
            tooltipEnabled={props.tooltipEnabled}
          >
            <SqButton sx={sxw0} onClick={copyButton}>
              Copy
            </SqButton>
          </ControlledTooltip>

          <ControlledTooltip
            title={PASTETOOL}
            placement="bottom"
            arrow={true}
            tooltipEnabled={props.tooltipEnabled}
          >
            <SqButton sx={sxw0} onClick={pasteButton}>
              Paste
            </SqButton>
          </ControlledTooltip>
          <ControlledTooltip
            title={LEARNTOOL}
            placement="bottom"
            arrow={true}
            tooltipEnabled={props.tooltipEnabled}
          >
            <SqButton sx={sxw3} onClick={learnClick} disabled={props.disabled}>
              {learning ? 'Learning' : 'Learn'}
            </SqButton>
          </ControlledTooltip>
        </Stack>
      </SqPaper>
    </>
  );
}

const UiOutputEdit = React.memo(UiOutputAllEdit, (pp: any, np: any) => {
  return (
    pp.behavior === np.behavior &&
    pp.channel === np.channel &&
    pp.delay === np.delay &&
    pp.filter === np.filter &&
    pp.scale === np.scale &&
    pp.offset === np.offset &&
    pp.tooltipEnabled === np.tooltipEnabled &&
    pp.smooth === np.smooth &&
    pp.volume === np.volume &&
    pp.disabled === np.disabled &&
    pp.voiceNumber === np.voiceNumber
  );
});

export default UiOutputEdit;
