import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import UiPreset from './UiPreset';

import {TypeOcrH5} from './Types';

export default function UiPresets(props: any) {
  return (
    <Paper variant="outlined" sx={{m: 2, mt: 2}} component="fieldset">
      <legend>
        <TypeOcrH5>Quick Presets</TypeOcrH5>
      </legend>
      <Grid
        container
        spacing={0}
        columns={{xs: 3, sm: 6, md: 12}}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={2}>
          <UiPreset name="Drums" url="ui/drums.syx" load={props.loadPreset} />
        </Grid>
        <Grid item xs={2}>
          <UiPreset name="Clocks" url="ui/clocks.syx" load={props.loadPreset} />
        </Grid>
        <Grid item xs={2}>
          <UiPreset
            name="Multi Voice"
            url="ui/multi_voice.syx"
            load={props.loadPreset}
          />
        </Grid>
        <Grid item xs={2}>
          <UiPreset name="CC" url="ui/cc.syx" load={props.loadPreset} />
        </Grid>
        <Grid item xs={2}>
          <UiPreset
            name="Poly Voice"
            url="ui/poly.syx"
            load={props.loadPreset}
          />
        </Grid>
        <Grid item xs={2}>
          <UiPreset
            name="Clear All"
            url="ui/All Off.syx"
            load={props.loadPreset}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
