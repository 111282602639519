import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import SqTextField from './SqTextField';

import ControlledTooltip from './ControlledTooltip';
import {POLYBENDTOOL} from '../const';

interface UiPolyBendAmountProps {
  bend: number;
  setBend: (s: number) => void;
  tooltipEnabled: boolean;
  label: string;
  disabled?: any;
}

function UiBendAmountItem(ms: number) {
  return (
    <MenuItem key={ms + 'bend'} value={ms}>
      {ms}
    </MenuItem>
  );
}

function UiPolyBendAmount(props: UiPolyBendAmountProps) {
  return (
    <ControlledTooltip
      title={POLYBENDTOOL}
      placement="top"
      arrow={true}
      tooltipEnabled={props.tooltipEnabled}
    >
      <SqTextField
        select
        label={props.label}
        size="small"
        onChange={(e: any) => {
          props.setBend(e.target.value);
        }}
        value={props.bend}
        sx={{mx: 1, mt: 2, width: '8.5rem'}}
        disabled={props.disabled}
      >
        {UiBendAmountItem(0)}
        {UiBendAmountItem(1)}
        {UiBendAmountItem(2)}
        {UiBendAmountItem(3)}
        {UiBendAmountItem(4)}
        {UiBendAmountItem(5)}
        {UiBendAmountItem(6)}
        {UiBendAmountItem(7)}
        {UiBendAmountItem(8)}
        {UiBendAmountItem(9)}
        {UiBendAmountItem(10)}
        {UiBendAmountItem(11)}
        {UiBendAmountItem(12)}
        {UiBendAmountItem(24)}
        {UiBendAmountItem(48)}
      </SqTextField>
    </ControlledTooltip>
  );
}

export default UiPolyBendAmount;
