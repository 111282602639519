import { PropsWithChildren } from 'react'
interface IfProps {
    value: boolean;
}
export default function If(props: PropsWithChildren<IfProps>) {

    if (props.value) {
        return (<>{props.children}</>);
    }
    else {
        return <></>
    }
}
