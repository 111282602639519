var index = 0;

export class Port {
  index: number;
  id: string;
  port: WebMidi.MIDIPort;

  constructor(p: WebMidi.MIDIPort) {
    this.port = p;
    this.id = p.id;
    this.index = index++;
  }

  isInput() {
    return this.port.type === 'input';
  }

  isOutput() {
    return this.port.type === 'output';
  }

  getInput() {
    if (!this.isInput()) {
      console.log(this);
      throw Error('wrong midi type');
    }
    return this.port as WebMidi.MIDIInput;
  }

  getOutput() {
    if (!this.isOutput()) {
      console.log(this);
      throw Error('wrong midi type');
    }
    return this.port as WebMidi.MIDIOutput;
  }

  close() {
    this.port.close();
  }
}

class WebMidiWrap {
  connectionCallback: (p: Port) => void;
  inputCallback: (p: Port, message: WebMidi.MIDIMessageEvent) => void;

  permission: PermissionStatus | null;
  access: WebMidi.MIDIAccess | null;
  ports: Map<string, Port>;

  isSetup: boolean;

  constructor() {
    this.connectionCallback = (p: Port) => {};
    this.inputCallback = (p: Port, message: WebMidi.MIDIMessageEvent) => {};
    this.permission = null;
    this.access = null;
    this.isSetup = false;
    this.ports = new Map<string, Port>();
  }

  setCallback(
    connection: (p: Port) => void,
    input: (p: Port, message: WebMidi.MIDIMessageEvent) => void
  ) {
    this.connectionCallback = connection;
    this.inputCallback = input;
  }

  static async getPermissions() {
    var opts: any = {name: 'midi', sysex: true};
    return await navigator.permissions.query(opts);
  }

  async enable() {
    if (this.isSetup) {
      return;
    }

    this.isSetup = true;

    const q: any = {name: 'midi', sysex: true};
    this.permission = await navigator.permissions.query(q);

    if (this.permission.state === 'granted') {
      //nothing to do fall thru
    } else if (this.permission.state === 'prompt') {
      //nothing to do fall thru
    } else if (this.permission.state === 'denied') {
      console.log('unknown midi permission:', this.permission.state);
      throw new Error('MIDI access denied: click padlock to enable');
    } else {
      console.log('unknown midi permission:', this.permission.state);
      throw new Error('unknown midi permission state ' + this.permission.state);
    }

    this.access = await window.navigator.requestMIDIAccess({sysex: true});

    this.access.onstatechange = (event: WebMidi.MIDIConnectionEvent) => {
      console.log(
        event.port.id,
        event.port.name,
        event.port.state,
        event.port.connection
      );
      if (event.port.state === 'disconnected') {
        this.remPort(event.port);
      } else if (event.port.state === 'connected') {
        this.addPort(event.port);
      } else {
        console.log('unknown midi state:', event.port.state);
      }
    };

    this.access.inputs.forEach((p) => {
      this.addPort(p);
    });
    this.access.outputs.forEach((p) => {
      this.addPort(p);
    });
  }

  disable() {
    this.ports.forEach((p) => {
      p.close();
    });
  }

  addPort(port: WebMidi.MIDIPort) {
    if (!this.ports.has(port.id)) {
      const p = new Port(port);
      this.ports.set(port.id, p);

      if (p.isInput()) {
        p.getInput().onmidimessage = (message) => {
          this.inputCallback(p, message);
        };
      }

      this.connectionCallback(p);
    }
  }

  remPort(port: WebMidi.MIDIPort) {
    if (this.ports.has(port.id)) {
      this.ports.delete(port.id);
    }
  }

  forEach(type: string, cb: (p: Port) => void) {
    this.ports.forEach((p) => {
      if (p.port.type === type) {
        cb(p);
      }
    });
  }

  hasPort(key: string) {
    return this.ports.get(key) ? true : false;
  }

  getPort(key: string) {
    const p = this.ports.get(key);

    if (!p) {
      throw Error('unknown port ' + key);
    }

    return p;
  }
}

const webmidi = new WebMidiWrap();

export default webmidi;
