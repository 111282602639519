import './firebase';
import {getFunctions, httpsCallableFromURL} from 'firebase/functions';
import getUserInfo from './UserInfo';

const funcs = getFunctions();

//const opts = { enforceAppCheck: true };
const opts = {};

function getName(code: string) {
  for (const p of getUserInfo().plugins) {
    if (p.code === code) return p.name;
  }
  return code;
}

function getUrl(fn: string) {
  return 'https://' + window.location.host + '/' + fn;
}

const gcf = {
  ping: httpsCallableFromURL(funcs, getUrl('ping'), opts),
  email: httpsCallableFromURL(funcs, getUrl('support'), opts),
  product: httpsCallableFromURL(funcs, getUrl('product'), opts),
  key: httpsCallableFromURL(funcs, getUrl('key'), opts),
  redeem: async (guid: string) => {
    console.log('redeeming ' + guid);
    const res: any = await gcf.key({action: 'redeem', guid: guid});
    console.log('redeemed ', res);
    const prefix = 'Key redeemed for ';
    var message = res.data.message;

    if (message.includes(prefix)) {
      const codetext = message.replace(prefix, '').trim();
      const codes = codetext.split(',');
      console.log(codetext, codes);
      const names = codes.map((s: string) => {
        return getName(s.trim());
      });
      res.data.message = prefix + names.join(',');
      console.log(res.data);
    }
    console.log(res.data);
    getUserInfo().redeemRes = res.data;
    return res.data;
  },
  user: httpsCallableFromURL(funcs, getUrl('user'), opts),
  signup: async (email: string) => {
    const res = await gcf.user({action: 'signup', email: email});
    console.log(res);
    return res.data;
  },
  validated: async () => {
    const res = await gcf.user({action: 'validated'});
    console.log(res);
    return res.data;
  },
};

export default gcf;
