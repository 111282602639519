import Button from '@mui/material/Button';

import {TypeOcrB1} from './Types';

interface BarButtonProps {
  name: string;
  enabled: boolean;
  onClick: () => void;
}

function BarButton(props: BarButtonProps) {
  return (
    <Button
      sx={{mx: 1, mb: 3}}
      variant="text"
      size="large"
      color={props.enabled ? 'primary' : 'warning'}
      onClick={() => {
        props.onClick();
      }}
    >
      <TypeOcrB1>{props.name}</TypeOcrB1>
    </Button>
  );
}

export default BarButton;
