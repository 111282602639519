import React from 'react';
import {PropsWithChildren} from 'react';
import Button from '@mui/material/Button';
import {ButtonProps} from '@mui/material/Button';

interface SqButtonProps extends ButtonProps {}

//export default function SqButtonX(pold: PropsWithChildren<SqButtonProps>) {
const SqButton = React.forwardRef(
  (pold: PropsWithChildren<SqButtonProps>, ref) => {
    var propsOld: any = pold;
    var props: any = {};

    Object.keys(propsOld).forEach((k) => {
      props[k] = propsOld[k];
    });

    if (props.style) {
      props.style = {};
      Object.keys(propsOld.style).forEach((k) => {
        props.style[k] = propsOld.style[k];
      });
    } else {
      props.style = {};
    }

    if (props.sx) {
      props.sx = {};
      Object.keys(propsOld.sx).forEach((k) => {
        props.sx[k] = propsOld.sx[k];
      });
    } else {
      props.sx = {};
    }
    //overrides
    props.style.borderRadius = 0;
    props.style.borderWidth = 3;
    props.sx.border = 3;
    props.variant = 'outlined';

    return (
      <Button {...props} ref={ref}>
        {props.children}
      </Button>
    );
  }
);

export default SqButton;
