import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

export default function FaqAccordion(props: any) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{justifyContent: 'left'}}
      >
        <Typography
          sx={{fontWeight: 'bold', justifyContent: 'left', alignItems: 'left'}}
        >
          {props.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="div" align="left">
          {props.children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
export function AccordionFaqPage(props: any) {
  return (
    <Accordion sx={{width: '80%'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{justifyContent: 'left'}}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            justifyContent: 'left',
            alignItems: 'left',
            alignText: 'start',
          }}
        >
          {props.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="div" align="left">
          {props.children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
