import {saveAs} from 'file-saver';
import UiOutput from './UiOutput';
import {
  toSysexUi,
  fromSysexUi,
  UI_SET_CONFIG,
  ROUTE_USB_MIDI,
  ROUTE_MIDI_THRU,
  CURRENT_VERSION,
  FLAGS_CHAINED,
} from './UiDevice';
import {behGetFromCatIncludes} from './UiBehavior';
export default class UiConfig {
  outputs: UiOutput[];
  name: string;
  version: number;
  flags: number;
  route: number;
  clock_ms: number;
  trig_ms: number;
  chChannel: number;
  mpeCount: number;
  bendScale: number;

  constructor() {
    this.name = 'UI is cool!';
    this.version = CURRENT_VERSION;
    this.flags = 0;
    this.route = ROUTE_USB_MIDI;
    this.clock_ms = 3;
    this.trig_ms = 4;
    this.chChannel = 15;
    this.mpeCount = 4;
    this.bendScale = 3;

    this.outputs = [
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
      new UiOutput(),
    ];
  }

  static size: number = 8 * 16 + 16 + 16;

  static fromObj(obj: any) {
    const c = new UiConfig();
    c.name = obj.name;
    c.flags = UiConfig.toFlags(obj.chained);
    c.route = UiConfig.toRoutes(obj.usb2midi, obj.midiThru);
    c.clock_ms = obj.clock_ms;
    c.trig_ms = obj.trig_ms;
    c.chChannel = obj.chChannel;

    c.mpeCount = obj.mpeCount;
    c.bendScale = obj.bendScale;

    for (let i = 0; i < 16; i++) {
      c.outputs[i] = UiOutput.fromObj(obj.outputs[i]);
    }

    return c;
  }

  static toObj(c: UiConfig): any {
    var obj: any = {};

    obj.name = c.name;
    obj.chained = c.isChained();
    obj.usb2midi = c.isUsbMidi();
    obj.midiThru = c.isMidiThru();
    obj.clock_ms = c.clock_ms;
    obj.trig_ms = c.trig_ms;
    obj.chChannel = c.chChannel;
    obj.mpeCount = c.mpeCount;
    obj.bendScale = c.bendScale;

    obj.outputs = [];

    for (let i = 0; i < 16; i++) {
      obj.outputs[i] = c.outputs[i];
    }

    return obj;
  }

  static toFlags(chained: boolean) {
    var flags = 0;

    if (chained) {
      flags |= FLAGS_CHAINED;
    }

    return flags;
  }

  static toRoutes(usb2midi: boolean, midithru: boolean) {
    var flags = 0;

    if (usb2midi) {
      flags |= ROUTE_USB_MIDI;
    }

    if (midithru) {
      flags |= ROUTE_MIDI_THRU;
    }

    return flags;
  }

  isUsbMidi() {
    return (this.route & ROUTE_USB_MIDI) !== 0;
  }

  isMidiThru() {
    return (this.route & ROUTE_MIDI_THRU) !== 0;
  }

  isChained() {
    return (this.flags & FLAGS_CHAINED) !== 0;
  }

  static fromSysex(array: Uint8Array): UiConfig {
    const cmd = array[6];

    if (cmd !== UI_SET_CONFIG && cmd !== 8) {
      throw new Error('Invalid command for config: ' + cmd);
    }

    return UiConfig.fromArray(fromSysexUi(cmd, array));
  }

  static fromArray(array: Uint8Array): UiConfig {
    if (array.length !== UiConfig.size) {
      throw new Error(
        'File is not a valid UI config file. Wrong Size: ' + array.length
      );
    }

    const state = new UiConfig();

    var i: number = 0;

    const checksum = array[i++];
    const magic0 = array[i++];
    const version = array[i++];

    if (magic0 !== 0x23) {
      throw new Error(
        'File is not a valid UI config file. bad magic 0: ' + magic0
      );
    }

    if (version <= 8) {
      return UiConfig.fromArrayVer(state, i, array, checksum, version);
    } else {
      throw new Error(
        'File is not a valid UI config file. bad version: ' + version
      );
    }
  }

  static fromArrayVer(
    state: UiConfig,
    i: number,
    array: Uint8Array,
    checksum: number,
    version: number
  ): UiConfig {
    state.flags = array[i++];
    state.route = array[i++];
    state.trig_ms = array[i++];
    state.clock_ms = array[i++];
    state.chChannel = array[i++];

    state.mpeCount = array[i++];
    state.bendScale = array[i++];
    i += 5;

    if (version <= 7) {
      state.chChannel = 16;
    }

    var name = '';

    for (let c = 0; c < 16; c++) {
      const ch = String.fromCharCode(array[i++]);
      if (ch !== '\0') {
        name += ch;
      }
    }

    state.name = name;

    for (let c = 0; c < 16; c++) {
      i += UiOutput.fromArray(state.outputs[c], i, array);
    }

    const magic1 = array[i++];

    if (magic1 !== 0x32) {
      throw new Error(
        'File is not a valid UI config file. bad magic 1: ' + magic1
      );
    }

    if (i !== this.size) {
      throw Error('wrong sized config ' + i + ' ' + this.size);
    }

    if (checksum) {
    }

    return state;
  }

  static toSysex(state: UiConfig): Uint8Array {
    return toSysexUi(UI_SET_CONFIG, 0x7f, UiConfig.toArray(state));
  }

  static toArray(state: UiConfig): Uint8Array {
    const array = new Uint8Array(UiConfig.size);

    var i: number = 0;

    array[i++] = 0; //checksum;
    array[i++] = 0x23;
    array[i++] = state.version;
    array[i++] = state.flags;
    array[i++] = state.route;
    array[i++] = state.trig_ms;
    array[i++] = state.clock_ms;
    array[i++] = state.chChannel;
    array[i++] = state.mpeCount;
    array[i++] = state.bendScale;
    array[i++] = 0; //pad
    array[i++] = 0; //pad
    array[i++] = 0; //pad
    array[i++] = 0; //pad
    array[i++] = 0; //pad

    for (let c = 0; c < 16; c++) {
      array[i++] = state.name.charCodeAt(c);
    }

    for (let c = 0; c < 16; c++) {
      i += UiOutput.toArray(state.outputs[c], i, array);
    }

    array[i++] = 0x32; //magic1

    if (i !== UiConfig.size) {
      throw Error('wrong sized config ' + i + ' ' + UiConfig.size);
    }

    return array;
  }

  saveAsSyx() {
    saveAs(new Blob([UiConfig.toSysex(this)]), this.name + '.syx');
  }
  saveAsJson() {
    saveAs(
      new Blob([JSON.stringify(UiConfig.toObj(this), null, 2)]),
      this.name + '.json'
    );
  }
  getMpeCount(): number {
    var behaviors = behGetFromCatIncludes('MPE');
    var count = 0;
    for (let c = 0; c < behaviors.length; c++) {
      var bc = this.getBehCount(behaviors[c].code);
      if (bc > count) {
        count = bc;
      }
    }
    return count;
  }

  getBehCount(behavior: number): number {
    var count = 0;
    for (let c = 0; c < 16; c++) {
      if (this.outputs[c].behavior === behavior) {
        count += 1;
      }
    }
    return count;
  }
}
