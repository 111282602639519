import Container from '@mui/material/Container';
import { PropsWithChildren } from 'react'

interface ResponsiveContainerProps { maxWidth?: string };


export default function ResponsiveContainer(props: PropsWithChildren<ResponsiveContainerProps>) {
    var style: any = {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 2,
        maxWidth: props.maxWidth,
    };

    return (<Container style={style}>{props.children}</Container>);
}