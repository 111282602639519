import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export interface ProgressProps {
  value: number;
  visibility: string;
}

export function Progress(props: ProgressProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', visibility: props.visibility }}>
      <Box sx={{ width: "100%", mx: 4 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35, mr: 4 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value,)}%`}</Typography>
      </Box>
    </Box>
  );
}