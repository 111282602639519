import React from 'react';

import gcf from '../ostium/functions';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import If from './If';

import PagePaper from './PagePaper';
import SqTextField from './SqTextField';
import Title from './Title';

import {LoginButton} from './Breadcrumbs';
import ResponsiveContainer from './ResponsiveContainer';
import {TypeOcr, TypeOcrH6} from './Types';

interface FormProps {
  name: string;
  setName: (name: string) => void;
  email: string;
  setEmail: (email: string) => void;
  message: string;
  setMessage: (message: string) => void;
  module: string;
  setModule: (module: string) => void;
  day: string;
  setDay: (day: string) => void;
  purchase: string;
  setPurchase: (purchase: string) => void;
  serial: string;
  setSerial: (serial: string) => void;
  plugin: string;
  setPlugin: (plugin: string) => void;
  buttonMessage: string;
  setButtonMessage: (buttonMessage: string) => void;
  formType: string;
  pedal: string;
  setPedal: (pedal: string) => void;
}

const canSendMessage = (props: FormProps) => {
  if (
    props.email.length <= 5 ||
    !props.email.includes('@') ||
    !props.email.includes('.')
  ) {
    return false;
  }
  if (props.message.length === 0) {
    return false;
  }
  if (props.formType === 'EuroEmail') {
    if (props.module.length === 0) {
      return false;
    }
    if (props.purchase.length === 0) {
      return false;
    }
    if (props.day.length === 0) {
      return false;
    }
    if (props.serial.length === 0) {
      return false;
    }
  }
  if (props.formType === 'PluginEmail') {
    if (props.plugin.length === 0) {
      return false;
    }
  }
  if (props.formType === 'PedalEmail') {
    if (props.pedal.length === 0) {
      return false;
    }
  }
  return true;
};

const formatMessage = (props: FormProps) => {
  if (props.formType === 'PluginEmail') {
    return formatPluginEmail(props);
  }
  if (props.formType === 'EuroEmail') {
    return formatEuroEmail(props);
  }
  if (props.formType === 'PedalEmail') {
    return formatPedalEmail(props);
  }
  if (props.formType === 'GeneralEmail') {
    return formatGeneralEmail(props);
  }
};

const formatGeneralEmail = (props: FormProps) => {
  return `<h5>Message From ${props.name}</h5>
  <p>${props.email}</p> ${props.message}`;
};
const formatEuroEmail = (props: FormProps) => {
  return `<h4>Message From ${props.name}</h4>
<p>${props.email}</p><p>Module Name: ${props.module}</p><p>Purchase date: ${props.day}</p><p>Purchase location: ${props.purchase}</p><p>Serial Number: ${props.serial} </p>${props.message}`;
};
const formatPluginEmail = (props: FormProps) => {
  return `<h5>Message From ${props.name}</h5>
<p>${props.email}</p> <p>Plugin Name: ${props.plugin}</p>${props.message}`;
};
const formatPedalEmail = (props: FormProps) => {
  return `<h5>Message From ${props.name}</h5>
<p>${props.email}</p> <p>Pedal Name: ${props.pedal}</p>${props.message}`;
};

const sendMessage = async (props: FormProps) => {
  const msg = {
    name: props.name,
    email: props.email,
    message: formatMessage(props),
    magic: 1234567,
  };

  const res: any = await gcf.email(msg);

  console.log('done');
  console.log(res);

  if (res.data.status === 'success') {
    props.setButtonMessage(
      'Thanks for your email! We try to reply to inquiries within two business days.'
    );
    props.setEmail('');
    props.setName('');
    props.setMessage('');
    props.setModule('');
    props.setDay('');
    props.setPurchase('');
    props.setSerial('');
    props.setPlugin('');
    props.setPedal('');
  } else {
    props.setButtonMessage('Check required fields');
  }
};
function GeneralEmail(props: FormProps) {
  return (
    <>
      <Stack direction="row">
        {' '}
        <Grid
          container
          spacing={3}
          columns={{sm: 3, md: 6, xl: 12}}
          justifyContent="center"
          alignItems="center"
        >
          <Grid>
            <Stack>
              <TypeOcr align="left" sx={{width: 350}}>
                Your Name
              </TypeOcr>{' '}
              <SqTextField
                sx={{width: 350}}
                onChange={(e: any) => props.setName(e.target.value)}
                value={props.name}
              ></SqTextField>
            </Stack>
          </Grid>
          <Grid>
            <Stack>
              <TypeOcr align="left" sx={{width: 350}}>
                Email Address*
              </TypeOcr>{' '}
              <SqTextField
                sx={{width: 350}}
                required
                onChange={(e: any) => props.setEmail(e.target.value)}
                value={props.email}
              ></SqTextField>
            </Stack>
          </Grid>

          <Grid>
            <Stack>
              <TypeOcr align="left" sx={{width: 350}}>
                Tell us what's on your mind.*
              </TypeOcr>{' '}
              <SqTextField
                sx={{width: 350}}
                required
                multiline
                minRows={10}
                onChange={(e: any) => props.setMessage(e.target.value)}
                value={props.message}
              ></SqTextField>
            </Stack>
          </Grid>
        </Grid>{' '}
      </Stack>
      <Button
        disabled={!canSendMessage(props)}
        color="info"
        variant="contained"
        style={{borderRadius: 0}}
        sx={{mt: 3, mb: 3}}
        onClick={() => {
          sendMessage(props);
        }}
      >
        {props.buttonMessage}
      </Button>
    </>
  );
}
function EuroEmail(props: FormProps) {
  return (
    <>
      <Grid
        container
        spacing={3}
        columns={{xs: 1, sm: 5, md: 5, xl: 12}}
        justifyContent="center"
        alignItems="center"
      >
        {' '}
        <Grid>
          <Stack>
            <TypeOcr align="left" sx={{width: 350}}>
              Your Name
            </TypeOcr>{' '}
            <SqTextField
              sx={{width: 350}}
              onChange={(e: any) => props.setName(e.target.value)}
              value={props.name}
            ></SqTextField>
          </Stack>
        </Grid>
        <Grid>
          <Stack>
            <TypeOcr align="left" sx={{width: 350}}>
              Email Address*
            </TypeOcr>{' '}
            <SqTextField
              sx={{width: 350}}
              required
              onChange={(e: any) => props.setEmail(e.target.value)}
              value={props.email}
            ></SqTextField>
          </Stack>
        </Grid>
        <Grid>
          {' '}
          <Stack>
            <TypeOcr align="left" sx={{width: 350}}>
              Module Name*
            </TypeOcr>{' '}
            <SqTextField
              sx={{width: 350}}
              required
              onChange={(e: any) => props.setModule(e.target.value)}
              value={props.module}
            ></SqTextField>{' '}
          </Stack>
        </Grid>
        <Grid>
          <Stack>
            <TypeOcr align="left" sx={{width: 350}}>
              Purchase Date*
            </TypeOcr>{' '}
            <SqTextField
              sx={{width: 350}}
              required
              onChange={(e: any) => props.setDay(e.target.value)}
              value={props.day}
            ></SqTextField>
          </Stack>
        </Grid>
        <Grid>
          <Stack>
            <TypeOcr align="left" sx={{width: 350}}>
              Place of Purchase*
            </TypeOcr>{' '}
            <SqTextField
              sx={{width: 350}}
              required
              onChange={(e: any) => props.setPurchase(e.target.value)}
              value={props.purchase}
            ></SqTextField>
          </Stack>
        </Grid>
        <Grid>
          <Stack>
            <TypeOcr align="left" sx={{width: 350}}>
              Serial Number*
            </TypeOcr>{' '}
            <SqTextField
              sx={{width: 350}}
              required
              onChange={(e: any) => props.setSerial(e.target.value)}
              value={props.serial}
            ></SqTextField>{' '}
          </Stack>
        </Grid>{' '}
      </Grid>

      <Grid>
        <Typography sx={{mt: 3, mb: 3}}>
          <b>
            Please send a clear photo of the back of the module to
            supportimages@noiseengineering.us{' '}
          </b>
          <br></br>
          <br></br>We use this to rule out / look for obvious problems and it
          can be much faster and easier than sending a module in for repair!
          Sometimes a short video of the problem is helpful, too. If you want to
          send that, please do.
        </Typography>{' '}
      </Grid>

      <Grid
        container
        spacing={3}
        columns={{xs: 1, sm: 12, md: 12, xl: 12}}
        justifyContent="center"
        alignItems="center"
      >
        <Grid>
          <Stack>
            <TypeOcr align="left" sx={{width: 350}}>
              What's happening with the module? We can help!*
            </TypeOcr>{' '}
            <SqTextField
              sx={{width: 350}}
              required
              multiline
              minRows={7}
              onChange={(e: any) => props.setMessage(e.target.value)}
              value={props.message}
            ></SqTextField>
          </Stack>
        </Grid>{' '}
      </Grid>

      <Button
        disabled={!canSendMessage(props)}
        color="info"
        variant="contained"
        style={{borderRadius: 0}}
        sx={{mt: 3, mb: 3}}
        onClick={() => {
          sendMessage(props);
        }}
      >
        {props.buttonMessage}
      </Button>

      <Typography sx={{mb: 3}}>
        Looking for the Serial Number? Look for a number like B10002 or C020 on
        the back of the module.
      </Typography>
    </>
  );
}
function PluginEmail(props: FormProps) {
  return (
    <>
      <ResponsiveContainer>
        {' '}
        <Grid
          container
          spacing={3}
          columns={{sm: 3, md: 6, xl: 12}}
          justifyContent="center"
          alignItems="center"
        >
          <Grid>
            <Stack>
              <TypeOcr align="left" sx={{width: 350}}>
                Name
              </TypeOcr>{' '}
              <SqTextField
                sx={{width: 350, mb: 2}}
                onChange={(e: any) => props.setName(e.target.value)}
                value={props.name}
              ></SqTextField>{' '}
            </Stack>
          </Grid>
          <Grid>
            {' '}
            <Stack>
              <Container>
                {' '}
                <TypeOcr align="left" sx={{width: 350}}>
                  Email Address*
                </TypeOcr>{' '}
              </Container>
              <SqTextField
                sx={{width: 350, mb: 2}}
                required
                onChange={(e: any) => props.setEmail(e.target.value)}
                value={props.email}
              ></SqTextField>{' '}
            </Stack>
          </Grid>
          <Grid>
            <Stack>
              <Container>
                {' '}
                <TypeOcr align="left" sx={{width: 350}}>
                  Name of Plugin*
                </TypeOcr>{' '}
              </Container>
              <SqTextField
                sx={{width: 350, mb: 2}}
                required
                onChange={(e: any) => props.setPlugin(e.target.value)}
                value={props.plugin}
              ></SqTextField>{' '}
            </Stack>
          </Grid>
          <Grid>
            <Stack>
              {' '}
              <Container>
                {' '}
                <TypeOcr align="left" sx={{width: 350}}>
                  What's happening with the plugin? We can help!*
                </TypeOcr>
              </Container>
              <SqTextField
                sx={{width: 350}}
                required
                multiline
                minRows={7}
                onChange={(e: any) => props.setMessage(e.target.value)}
                value={props.message}
              ></SqTextField>{' '}
            </Stack>
          </Grid>
        </Grid>
        <Button
          disabled={!canSendMessage(props)}
          color="info"
          variant="contained"
          style={{borderRadius: 0}}
          sx={{mt: 3, mb: 3}}
          onClick={() => {
            sendMessage(props);
          }}
        >
          {props.buttonMessage}
        </Button>
        <Stack>
          <Container>
            {' '}
            <TypeOcr sx={{mt: 3}} align="center">
              Have other bits and baubles that might help?
            </TypeOcr>
            <Typography sx={{mt: 0, mb: 3}}>
              If you have a screenshot of the problem occurring, please send it
              to supportimages@noiseengineering.us. If the problem is within a
              browser, a screenshot of the Developer Console is also handy. You
              can get to it by hitting Ctrl+Shift+I on Windows or Cmd+Shift+I on
              Mac, and then selecting the Console tab.
            </Typography>{' '}
          </Container>{' '}
        </Stack>
      </ResponsiveContainer>
    </>
  );
}

function PedalEmail(props: FormProps) {
  return (
    <>
      <ResponsiveContainer>
        {' '}
        <Grid
          container
          spacing={3}
          columns={{sm: 3, md: 6, xl: 12}}
          justifyContent="center"
          alignItems="center"
        >
          <Grid>
            <Stack>
              <TypeOcr align="left" sx={{width: 350}}>
                Name
              </TypeOcr>{' '}
              <SqTextField
                sx={{width: 350, mb: 2}}
                onChange={(e: any) => props.setName(e.target.value)}
                value={props.name}
              ></SqTextField>{' '}
            </Stack>
          </Grid>
          <Grid>
            {' '}
            <Stack>
              <Container>
                {' '}
                <TypeOcr align="left" sx={{width: 350}}>
                  Email Address*
                </TypeOcr>{' '}
              </Container>
              <SqTextField
                sx={{width: 350, mb: 2}}
                required
                onChange={(e: any) => props.setEmail(e.target.value)}
                value={props.email}
              ></SqTextField>{' '}
            </Stack>
          </Grid>
          <Grid>
            <Stack>
              <Container>
                {' '}
                <TypeOcr align="left" sx={{width: 350}}>
                  Name of Pedal*
                </TypeOcr>{' '}
              </Container>
              <SqTextField
                sx={{width: 350, mb: 2}}
                required
                onChange={(e: any) => props.setPedal(e.target.value)}
                value={props.pedal}
              ></SqTextField>{' '}
            </Stack>
          </Grid>
          <Grid>
            <Stack>
              {' '}
              <Container>
                {' '}
                <TypeOcr align="left" sx={{width: 350}}>
                  What can we do for you?
                </TypeOcr>
              </Container>
              <SqTextField
                sx={{width: 350}}
                required
                multiline
                minRows={7}
                onChange={(e: any) => props.setMessage(e.target.value)}
                value={props.message}
              ></SqTextField>{' '}
            </Stack>
          </Grid>
        </Grid>
        <Button
          disabled={!canSendMessage(props)}
          color="info"
          variant="contained"
          style={{borderRadius: 0}}
          sx={{mt: 3, mb: 3}}
          onClick={() => {
            sendMessage(props);
          }}
        >
          {props.buttonMessage}
        </Button>
      </ResponsiveContainer>
    </>
  );
}
interface ContactProps {
  setCurrentTab: (s: string) => void;
}

export default function Contact(props: ContactProps) {
  const [formType, setFormType] = React.useState('');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [day, setDay] = React.useState('');
  const [purchase, setPurchase] = React.useState('');
  const [serial, setSerial] = React.useState('');
  const [module, setModule] = React.useState('');
  const [plugin, setPlugin] = React.useState('');
  const [pedal, setPedal] = React.useState('');
  const [buttonMessage, setButtonMessage] = React.useState('Submit Message');

  return (
    <>
      <Title>Talk to us</Title>
      <PagePaper>
        <ResponsiveContainer>
          <Grid sx={{mx: 0, my: 0, mt: 4, mr: 0}} width="100%">
            <TypeOcrH6 sx={{mx: 0, my: 0, mt: 4, mb: 4}}>
              How Can We Help?
            </TypeOcrH6>
            <Typography sx={{mx: 0, my: 0, mb: 2, mr: 0}}>
              <br></br>Do you have a question/comment/concern about one of our
              products?
              <br></br>
              We want to hear from you.
            </Typography>
          </Grid>
          <ResponsiveContainer>
            <Grid sx={{mx: 0, my: 0, mt: 4, mr: 0}} width="70%">
              <TypeOcr sx={{mx: 0, my: 0, mb: 2, mr: 0}}>
                What would you like to talk to us about?
              </TypeOcr>{' '}
              <Tooltip
                title="Choose your contact form option from the dropdown. For general inquiries about a product, please choose the I have a question option."
                placement="top"
                arrow={true}
              >
                <SqTextField
                  select
                  name="Choose your path."
                  onChange={(e: any) => {
                    setFormType(e.target.value);
                  }}
                  sx={{width: '100%', mx: 0, my: 0, mb: 3, mr: 0}}
                  value={formType}
                >
                  <MenuItem value="GeneralEmail">
                    I have a question / just want to talk.
                  </MenuItem>
                  <MenuItem value="EuroEmail">
                    I have a problem with a module.
                  </MenuItem>
                  <MenuItem value="PluginEmail">
                    I have a problem with a plugin.
                  </MenuItem>
                  <MenuItem value="PedalEmail">
                    I have a problem with a pedal.
                  </MenuItem>
                </SqTextField>
              </Tooltip>
              <If value={formType === 'GeneralEmail'}>
                <GeneralEmail
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  message={message}
                  setMessage={setMessage}
                  module={module}
                  setModule={setModule}
                  setDay={setDay}
                  setPurchase={setPurchase}
                  setSerial={setSerial}
                  setPlugin={setPlugin}
                  pedal={pedal}
                  setPedal={setPedal}
                  day={day}
                  purchase={purchase}
                  serial={serial}
                  plugin={plugin}
                  buttonMessage={buttonMessage}
                  setButtonMessage={setButtonMessage}
                  formType={formType}
                ></GeneralEmail>
              </If>
              <If value={formType === 'EuroEmail'}>
                <EuroEmail
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  message={message}
                  setMessage={setMessage}
                  module={module}
                  setModule={setModule}
                  setDay={setDay}
                  setPurchase={setPurchase}
                  setSerial={setSerial}
                  setPlugin={setPlugin}
                  pedal={pedal}
                  setPedal={setPedal}
                  day={day}
                  purchase={purchase}
                  serial={serial}
                  plugin={plugin}
                  buttonMessage={buttonMessage}
                  setButtonMessage={setButtonMessage}
                  formType={formType}
                ></EuroEmail>
              </If>
              <If value={formType === 'PluginEmail'}>
                <PluginEmail
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  message={message}
                  setMessage={setMessage}
                  module={module}
                  setModule={setModule}
                  setDay={setDay}
                  setPurchase={setPurchase}
                  setSerial={setSerial}
                  setPlugin={setPlugin}
                  pedal={pedal}
                  setPedal={setPedal}
                  day={day}
                  purchase={purchase}
                  serial={serial}
                  plugin={plugin}
                  buttonMessage={buttonMessage}
                  setButtonMessage={setButtonMessage}
                  formType={formType}
                ></PluginEmail>
              </If>
              <If value={formType === 'PedalEmail'}>
                <PedalEmail
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  message={message}
                  setMessage={setMessage}
                  module={module}
                  setModule={setModule}
                  setDay={setDay}
                  setPurchase={setPurchase}
                  setSerial={setSerial}
                  setPlugin={setPlugin}
                  pedal={pedal}
                  setPedal={setPedal}
                  day={day}
                  purchase={purchase}
                  serial={serial}
                  plugin={plugin}
                  buttonMessage={buttonMessage}
                  setButtonMessage={setButtonMessage}
                  formType={formType}
                ></PedalEmail>
              </If>
            </Grid>
          </ResponsiveContainer>
          <LoginButton setCurrentTab={props.setCurrentTab}></LoginButton>
        </ResponsiveContainer>{' '}
      </PagePaper>
    </>
  );
}
