import {isWindows} from 'react-device-detect';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Image from 'mui-image';

import getUserInfo from '../ostium/UserInfo';
import SqButton from './SqButton';
import SqTextField from './SqTextField';
import {TypeOcrH6, TypeSubTitle} from './Types';

import {STATE_CONNECT} from './TabFirmware';
import {Progress} from './Progress';

interface FirmwareWizardProps {
  isOpen: () => boolean;
  close: () => void;
  getPlatform: () => string;
  getFirmware: () => string;
  setPlatform: (p: string) => void;
  setFirmware: (p: string) => void;
  getButtonOn: () => boolean;
  getButtonText: () => string;
  goButton: () => void;
  getConnectState: () => string;
  getProgress: () => number;
  getProgressVis: () => string;
  getErrorText: () => string;
}
interface FirmwareStepProps extends FirmwareWizardProps {
  hideButton: boolean;
}
const IMG_HEIGHT = 300;
const IMG_WID = 300;
const WIN_STEPS = isWindows ? ['Configure driver'] : [];
const STEP_NAMES = [
  'Select Firmware',
  'Configure WebUSB',
  'Connect USB',
  ...WIN_STEPS,
  'Connect Module',
  'Reprogram',
];

const STEP_COUNT = STEP_NAMES.length;

function DButton(
  justify: string,
  text: string,
  enabled: boolean,
  onClick: () => void
) {
  if (enabled) {
    return (
      <Container style={{alignItems: justify, justifyContent: justify}}>
        <SqButton sx={{m: 2}} onClick={() => onClick()}>
          {text}
        </SqButton>
      </Container>
    );
  } else {
    return <></>;
  }
}

function FButton(
  justify: string,
  text: string,
  enabled: boolean,
  onClick: () => void
) {
  return (
    <Container
      style={{
        flex: 1,
        flexDirection: 'row',
        alignItems: justify,
        justifyContent: justify,
      }}
    >
      <SqButton disabled={!enabled} sx={{m: 2}} onClick={() => onClick()}>
        {text}
      </SqButton>
    </Container>
  );
}

export default function FirmwareWizard(props: FirmwareWizardProps) {
  const [currentStep, setCurrentStep] = React.useState(0);

  const isEnd = STEP_COUNT - 1 === currentStep;
  const isStart = 0 === currentStep;
  const nextText = isEnd ? 'Close' : 'Next';
  const isConnected = props.getConnectState() !== STATE_CONNECT;
  const connectBlock = currentStep === (isWindows ? 4 : 3);

  const hasFirmware = props.getFirmware() && props.getPlatform();
  const flashBlock = currentStep === (isWindows ? 4 : 3);

  var showNext = true;
  if (connectBlock && !isConnected) showNext = false;
  if (flashBlock && !hasFirmware) showNext = false;
  if (!hasFirmware) showNext = false;

  const finishBlock = currentStep === (isWindows ? 6 : 5);
  const hideConnect = finishBlock && !isConnected;

  const handleBack = () => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
  };

  function onClick() {
    if (isEnd) {
      props.close();
      setCurrentStep(0);
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  const StepN = compStep[currentStep];

  return (
    <Dialog
      PaperProps={{sx: {width: '100%', height: 980}}}
      open={props.isOpen()}
      onClose={() => {
        props.close();
        setCurrentStep(0);
        return true;
      }}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle align="center">
        <TypeOcrH6>Firmware Wizard</TypeOcrH6>
      </DialogTitle>
      <Box sx={{mx: 6, my: 0}}>
        <Typography></Typography>
      </Box>
      <Box>
        <Box sx={{mx: 6, my: 0, height: 700}}>
          <StepN {...props} hideButton={hideConnect} />
        </Box>

        <Stepper activeStep={currentStep} alternativeLabel>
          {STEP_NAMES.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <DialogActions>
        {DButton('left', 'Back', !isStart, handleBack)}
        {DButton('center', 'Close', !isEnd, () => {
          props.close();
          setCurrentStep(0);
        })}
        {FButton('right', nextText, showNext, onClick)}
      </DialogActions>
    </Dialog>
  );
}
function StepImage(props: any) {
  return (
    <Image
      alt=" "
      src={props.src}
      width={IMG_WID}
      height={IMG_HEIGHT}
      wrapperStyle={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 15,
      }}
    />
  );
}

function Step1(props: FirmwareStepProps) {
  return (
    <>
      <StepImage src="panels/antri_combo400x400.png"></StepImage>
      <TypeSubTitle>Select firmware</TypeSubTitle>
      <DialogContentText component="div" align="center">
        <p>
          Select the platform name of your module and the firmware you'd like to
          install from the dropdowns.
        </p>
        <Stack direction="row" justifyContent="center">
          <SqTextField
            select
            size="small"
            onChange={(e: any) => {
              props.setPlatform(e.target.value);
            }}
            value={props.getPlatform()}
            style={{width: 125, height: 40}}
            sx={{mx: 2, my: 1, mt: 0}}
            label="Platform"
          >
            {getUserInfo().platforms.map((value) => {
              return (
                <MenuItem key={value.code} value={value.code}>
                  {value.name}
                </MenuItem>
              );
            })}
          </SqTextField>

          <SqTextField
            select
            size="small"
            onChange={(e: any) => {
              props.setFirmware(e.target.value);
            }}
            value={props.getFirmware()}
            style={{width: 270, height: 40}}
            sx={{mx: 2, my: 1, mt: 0}}
            label="Firmware"
          >
            {getUserInfo()
              .findFirmware(props.getPlatform())
              .map((value: any) => {
                return (
                  <MenuItem key={value.fullname} value={value.fullname}>
                    {value.name}
                  </MenuItem>
                );
              })}
          </SqTextField>
        </Stack>
      </DialogContentText>
    </>
  );
}

function Step2(props: FirmwareStepProps) {
  return (
    <>
      <StepImage src="panels/chrome-400.png"></StepImage>
      <TypeSubTitle>Configure WebUSB</TypeSubTitle>
      <DialogContentText component="div" align="center">
        <p>
          This app uses webUSB. It requires Chrome or a Chromium-based browser
          to function. Learn more at{' '}
          <Link
            href="https://caniuse.com/webusb"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>https://caniuse.com/webusb</b>
          </Link>
        </p>
        <p>
          If you have never swapped firmware before, you may need to configure
          your browser to recognize USB devices. This is a one-time procedure.
        </p>
        <p>
          To do that, visit <b>chrome://settings/content/usbDevices</b> and
          check the box that says "Sites can ask to connect to USB devices."
        </p>
      </DialogContentText>
    </>
  );
}

function Step3(props: FirmwareStepProps) {
  const f0 =
    props.getPlatform() === 'ee' ||
    props.getPlatform() === 'ui' ||
    props.getPlatform() === 'on' ||
    props.getPlatform() === 'pd';

  if (f0) {
    return (
      <>
        <StepImage src="panels/ee-back.jpg"></StepImage>
        <TypeSubTitle>Connect your module</TypeSubTitle>
        <DialogContentText component="div" align="center">
          <br></br>
          <br></br> Power off your case & unplug your module. While holding the
          small white button down, connect it to your computer via USB.
          <br></br>
          <p>Click Next.</p>
        </DialogContentText>
      </>
    );
  } else {
    return (
      <>
        <StepImage src="panels/versio-400.png"></StepImage>
        <TypeSubTitle>Connect your module</TypeSubTitle>
        <DialogContentText component="div" align="center">
          <br></br>
          <br></br> Power off your case & unplug your module. Connect it to your
          computer via the USB port on the back. Press and hold Button 1, tap
          and release Button 2. Release Button 1.<br></br>
          <br></br>
          <p>Click Next</p>
        </DialogContentText>
      </>
    );
  }
}

function Step4(props: FirmwareStepProps) {
  const f0 = props.getPlatform() === 'ee' || props.getPlatform() === 'ui';

  if (f0) {
    return (
      <>
        <StepImage src="panels/zadig-400.png"></StepImage>
        <TypeSubTitle>Configure Windows USB driver</TypeSubTitle>
        <DialogContentText component="div" align="center">
          <br></br>
          The first time you use this app, Windows users will need to download
          and run the free{' '}
          <Link
            href="https://zadig.akeo.ie/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zadig USB driver
          </Link>
          .<br></br>
          <br></br>
          With your module still connected, select "List All Devices" in the
          Options menu. In the dropdown, select "STM32Bootloader." To the right
          of the arrow, select "WinUSB" then click "Install Driver/Replace
          Driver"<br></br>
          <br></br>
          <br></br>
          Close Zadig and continue to the next step.
        </DialogContentText>
      </>
    );
  } else {
    return (
      <>
        <StepImage src="panels/zadig-400.png"></StepImage>
        <TypeSubTitle>Configure Windows USB driver</TypeSubTitle>
        <DialogContentText component="div" align="center">
          The first time you use this app, Windows users will need to download
          and run the free{' '}
          <Link
            href="https://zadig.akeo.ie/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zadig USB driver
          </Link>
          .<br></br>
          <br></br>
          With your module still connected, select "List All Devices" in the
          Options menu. In the dropdown, select "DFU in FS Mode." To the right
          of the arrow, select "WinUSB" then click "Install Driver/Replace
          Driver"<br></br>
          <br></br>
          <br></br>
          Close Zadig and continue to the next step.
        </DialogContentText>
      </>
    );
  }
}

function Step5(props: FirmwareStepProps) {
  const f0 = props.getPlatform() === 'ee' || props.getPlatform() === 'ui';

  if (f0) {
    return (
      <>
        <StepImage src="panels/ee-back.jpg"></StepImage>
        <TypeSubTitle>Connect your module</TypeSubTitle>
        <DialogContentText component="div" align="center">
          <br></br>

          <p>
            Click the connect button to connect your module to your computer. If
            a Chrome popup appears listing the available USB devices, select
            "STM32Bootloader" and click "Connect."{' '}
          </p>
          <p>If no popup appears, you are connected. Click Next.</p>
        </DialogContentText>

        <Stack direction="row" justifyContent="center">
          <SqButton
            onClick={() => props.goButton()}
            disabled={props.getConnectState() !== STATE_CONNECT}
            style={{width: 200, height: 30}}
            sx={{mx: 1, mt: 1}}
          >
            {props.getButtonText()}
          </SqButton>
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <StepImage src="panels/versio-400.png"></StepImage>
        <TypeSubTitle>Connect your module</TypeSubTitle>
        <DialogContentText component="div" align="center">
          <br></br>

          <p>
            Click the connect button to connect your module to your computer. If
            a Chrome popup appears listing the available USB devices, select
            "DFU in FS Mode" and click "Connect."{' '}
          </p>
          <p>If no popup appears, you are connected.</p>
        </DialogContentText>

        <Stack direction="row" justifyContent="center">
          <SqButton
            onClick={() => props.goButton()}
            disabled={props.getConnectState() !== STATE_CONNECT}
            style={{width: 200, height: 30}}
            sx={{mx: 1, mt: 1}}
          >
            {props.getButtonText()}
          </SqButton>
        </Stack>
      </>
    );
  }
}

function Step6(props: FirmwareStepProps) {
  return (
    <>
      <StepImage src="panels/desmodus-400.png"></StepImage>
      <TypeSubTitle>Change your firmware!</TypeSubTitle>
      <DialogContentText component="div" align="center">
        <p>
          Click the Change Firmware button. When the "Done!" message appears,
          unplug your module from the USB cable, reinstall it in your case, and
          go make sweet modular sounds!
        </p>
      </DialogContentText>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Box sx={{mx: 0, my: 0, width: '100%'}}>
          <Progress
            value={props.getProgress()}
            visibility={props.getProgressVis()}
          />
        </Box>
        {props.getErrorText()}
        {!props.hideButton && (
          <SqButton
            onClick={() => props.goButton()}
            disabled={!props.getButtonOn()}
            style={{width: 200, height: 40}}
            sx={{mx: 20, mt: 10}}
          >
            {props.getButtonText()}
          </SqButton>
        )}
      </Stack>
    </>
  );
}

const WIN_STEP = isWindows ? [Step4] : [];

const compStep = [Step1, Step2, Step3, ...WIN_STEP, Step5, Step6];
