import LegalText from './LegalText';
import Typography from '@mui/material/Typography';

interface TabCookiesProps {
  setPage: (s: string) => void;
}

export default function TabCookies(props: TabCookiesProps) {
  return (
    <>
      <LegalText title="Cookies Policy" setPage={props.setPage}>
        <Typography
          component="div"
          justifyContent="left"
          align="left"
          sx={{ml: 5, mr: 5}}
        >
          {' '}
          Last updated: September 1, 2021
          <br />
          <br />
          This Cookies Policy explains what Cookies are and how We use them. You
          should read this policy so You can understand what type of cookies We
          use, or the information We collect using Cookies and how that
          information is used. Cookies do not typically contain any information
          that personally identifies a user, but personal information that we
          store about You may be linked to the information stored in and
          obtained from Cookies. For further information on how We use, store
          and keep your personal data secure, see our Privacy Policy. We do not
          store sensitive personal information, such as mailing addresses,
          account passwords, etc. in the Cookies We use.
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
          <h3>Definitions</h3>
          For the purposes of this Cookies Policy:
          <ul>
            <li>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Cookies Policy) refers to Noise Engineering, 4875
              Huntington Dr N #32226 Los Angeles CA 90032.
            </li>
            <li>
              <strong>Cookies</strong> means small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing details of your browsing history on that website among
              its many uses.
            </li>
            <li>
              <strong>Website</strong> refers to Noise Engineering Customer
              Portal, accessible from{' '}
              <a
                href="https://portal.noiseengineering.us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://portal.noiseengineering.us
              </a>
            </li>

            <li>
              <strong>You</strong> means the individual accessing or using the
              Website, or a company, or any legal entity on behalf of which such
              individual is accessing or using the Website, as applicable.
            </li>
          </ul>
          <h2>The use of the Cookies</h2>
          <h3>Type of Cookies We Use</h3>
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close your web browser. We use both session and persistent Cookies for
          the purposes set out below:
          <ul>
            <li>
              <strong>Necessary / Essential Cookies</strong>
              Type: Session Cookies Administered by: Us Purpose: These Cookies
              are essential to provide You with services available through the
              Website and to enable You to use some of its features. They help
              to authenticate users and prevent fraudulent use of user accounts.
              Without these Cookies, the services that You have asked for cannot
              be provided, and We only use these Cookies to provide You with
              those services.
            </li>
            <li>
              <strong>Functionality Cookies</strong>
              Type: Persistent Cookies Administered by: Us Purpose: These
              Cookies allow us to remember choices You make when You use the
              Website, such as remembering your login details or language
              preference. The purpose of these Cookies is to provide You with a
              more personal experience and to avoid You having to re-enter your
              preferences every time You use the Website.
            </li>
            <li>
              <strong>Tracking and Performance Cookies</strong>
              Type: Persistent Cookies Administered by: Third-Parties Purpose:
              These Cookies are used to track information about traffic to the
              Website and how users use the Website. The information gathered
              via these Cookies may directly or indirectly identify you as an
              individual visitor. This is because the information collected is
              typically linked to a pseudonymous identifier associated with the
              device you use to access the Website. We may also use these
              Cookies to test new pages, features or new functionality of the
              Website to see how our users react to them.
            </li>
            <li>
              <strong>Targeting and Advertising Cookies</strong>
              Type: Persistent Cookies Administered by: Third-Parties Purpose:
              These Cookies track your browsing habits to enable Us to show
              advertising which is more likely to be of interest to You. These
              Cookies use information about your browsing history to group You
              with other users who have similar interests. Based on that
              information, and with Our permission, third party advertisers can
              place Cookies to enable them to show adverts which We think will
              be relevant to your interests while You are on third party
              websites.
            </li>
          </ul>
          <h3>Your Choices Regarding Cookies</h3>
          If You prefer to avoid the use of Cookies on the Website, first You
          must disable the use of Cookies in your browser and then delete the
          Cookies saved in your browser associated with this website. You may
          use this option for preventing the use of Cookies at any time. If You
          do not accept Our Cookies, You may experience some inconvenience in
          your use of the Website and some features may not function properly.
          If You'd like to delete Cookies or instruct your web browser to delete
          or refuse Cookies, please visit the help pages of your web browser.
          <ul>
            <li>
              For the Chrome web browser, please visit this page from Google:{' '}
              <a
                href="https://support.google.com/accounts/answer/32050"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/accounts/answer/32050
              </a>
            </li>
            <li>
              For the Internet Explorer web browser, please visit this page from
              Microsoft:{' '}
              <a
                href="http://support.microsoft.com/kb/278835"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://support.microsoft.com/kb/278835
              </a>
            </li>
            <li>
              For the Firefox web browser, please visit this page from Mozilla:{' '}
              <a
                href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
              </a>
            </li>
            <li>
              For the Safari web browser, please visit this page from Apple:
              <a
                href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
              </a>
            </li>
          </ul>
          For any other web browser, please visit your web browser's official
          web pages.
          <h3>More Information about Cookies</h3>
          You can learn more about cookies here:{' '}
          <a
            href="https://www.termsfeed.com/blog/cookies/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.termsfeed.com/blog/cookies/
          </a>
          .<h3>Contact Us</h3>
          If you have any questions about this Cookies Policy, You can contact
          us:
          <ul>
            <li>
              By email:{' '}
              <a href="mailto:pleasereply@noiseengineering.us">
                pleasereply@noiseengineering.us
              </a>
            </li>
            <li>
              By visiting this page on our website:{' '}
              <a
                href="https://portal.noiseengineering.us/?land=contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://portal.noiseengineering.us/?land=contact
              </a>
            </li>
            <li>By mail: 4875 Huntington Dr N #32226 Los Angeles, CA 90032</li>
          </ul>
        </Typography>
      </LegalText>
    </>
  );
}
