import UiOctaveSelect from './UiOctaveSelect';
import UiNoteSelect from './UiNoteSelect';
import Stack from '@mui/material/Stack';

interface UiOctaveNoteProps {
  allowAny: boolean;
  suffix: string;
  offset: number;
  setOffset: (s: number) => void;
  tooltipEnabled: boolean;
}

function UiOctaveNote(props: UiOctaveNoteProps) {
  const octave =
    props.offset === 255 ? 255 : Math.min(9, Math.floor(props.offset / 12));
  const note = props.offset === 255 ? 255 : props.offset % 12;

  return (
    <>
      <Stack direction="row" justifyContent="left" alignItems="center">
        <UiOctaveSelect
          allowAny={props.allowAny}
          suffix={props.suffix}
          octave={octave}
          setOctave={(s: number) => {
            if (s === 255) props.setOffset(s);
            else props.setOffset(12 * s + (props.offset % 12));
          }}
          tooltipEnabled={props.tooltipEnabled}
        />

        <UiNoteSelect
          allowAny={props.allowAny}
          suffix={props.suffix}
          semitone={note}
          setSemitone={(s: number) => {
            if (s === 255) {
              props.setOffset(s);
            } else {
              if (octave === 255) {
                props.setOffset(12 * 3 + s);
              } else {
                props.setOffset(12 * Math.floor(props.offset / 12) + s);
              }
            }
          }}
          tooltipEnabled={props.tooltipEnabled}
        />
      </Stack>
    </>
  );
}

export default UiOctaveNote;
