import Tooltip from '@mui/material/Tooltip';

import {ReactElement} from 'react';

interface ControlledTooltipProps {
  title: string;
  placement: any;
  arrow: boolean;
  tooltipEnabled: boolean;
  children: ReactElement<any, any>;
}

export default function ControlledTooltip(props: ControlledTooltipProps) {
  if (!props.children) {
    return <></>;
  } else {
    return (
      <Tooltip
        title={props.tooltipEnabled ? props.title : ''}
        placement={props.placement}
        arrow={props.arrow}
      >
        {props.children}
      </Tooltip>
    );
  }
}
