import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { TypeOcrH5, TypeOcrH6 } from './Types';
import PagePaper from './PagePaper';
import getUserInfo from '../ostium/UserInfo'


export const MODE_LOGIN = "login";
export const MODE_CREATE = "create";
export const MODE_DONE = "";


interface TabResetProps {
    setPage: (s: string) => void;
}

export default function TabReset(props: TabResetProps) {
    const [pw, setPw] = React.useState('');
    const [pwc, setPwc] = React.useState('');
    const [subtext, setSubtext] = React.useState(' ');
    const [disabled, setDisabled] = React.useState(false);

    async function create() {
        if (pw !== pwc) {
            setSubtext("Passwords do not match.");
            return;

        }
        setDisabled(true);
        try {
            await getUserInfo().confirmResetPassword(pw);
            props.setPage('firmware');
        } catch (e: any) {
            setSubtext(e.message);
            setDisabled(false);
            console.log(e);
        }
    }

    function check(): boolean {
        return pw !== "" && pwc !== "";
    }

    return (
        <>
            <Container sx={ { pt: "10%" } }

                style={ {

                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1
                } }>
                <PagePaper>

                    <TypeOcrH5 sx={ { pt: 2, m:2} } align="center">
                        Welcome to the Noise Engineering Customer Portal
                    </TypeOcrH5>
                    <Box sx={ { p: 5 } }></Box>

                    <TypeOcrH6>Reset your password</TypeOcrH6>

                    <Box sx={ { mx: 5, my: 5 } } >
                        <Typography align="center">{ subtext }</Typography></Box>
                    <Container style={ {
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: '200'
                    } }>
                        <Stack justifyContent="center" alignItems="center" >


                            <TextField
                                autoFocus
                                margin="dense"
                                id="pw"
                                label="Password"
                                type="password"
                                variant="outlined"
                                onChange={ e => setPw(e.target.value) }
                                value={ pw }
                                onKeyUp={ (e: any) => { if (e.keyCode === 13) { create(); } } }
                                disabled={ disabled }
                            />
                            <TextField
                                type="password"
                                autoFocus
                                margin="dense"
                                id="pwc"
                                label="Confirm Password"
                                variant="outlined"
                                onChange={ e => setPwc(e.target.value) }
                                value={ pwc }
                                onKeyUp={ (e: any) => { if (e.keyCode === 13) { create(); } } }
                                disabled={ disabled }
                            />
                            <br />
                        </Stack>

                    </Container >

                    < Stack direction="row" justifyContent="space-evenly" alignItems="center" >
                        <Button size="large" onClick={ () => props.setPage('login') } >Back to Login</Button>
                        <Button size="large" onClick={ () => create() } disabled={ !check() || disabled }  >Reset Account</Button>
                    </Stack >
                </PagePaper >
            </Container>

        </>
    );
}
