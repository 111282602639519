import SqTextField from './SqTextField';
import UiLayoutConst from './UiLayoutConst';
import ControlledTooltip from './ControlledTooltip';
import {CCTOOL} from '../const';

interface UiEnvEditProps {
  smooth: number;
  setSmooth: (n: number) => void;
  tooltipEnabled: boolean;
}

function UiEnvEdit(props: UiEnvEditProps) {
  const lay = new UiLayoutConst();

  return (
    // <Tooltip title={CCTOOL} placement="top" arrow={true}>
    <>
      <ControlledTooltip
        title={CCTOOL}
        placement="top"
        arrow={true}
        tooltipEnabled={props.tooltipEnabled}
      >
        <SqTextField
          autoFocus
          type="number"
          label="CC#"
          size="small"
          onChange={(e: any) => {
            var value = e.target.value === '' ? -1 : parseInt(e.target.value);

            if (value >= 120) value = 119;
            if (value <= -1) value = 0;
            if (value !== props.smooth) {
              props.setSmooth(value);
              console.log(value, props.smooth);
            }
          }}
          sx={{mx: lay.mx, my: lay.my, width: 6 * 17}}
          value={props.smooth === -1 ? '' : props.smooth}
        ></SqTextField>
      </ControlledTooltip>
    </>
  );
}

export default UiEnvEdit;
