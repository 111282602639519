import MenuItem from '@mui/material/MenuItem';
import SqTextField from './SqTextField';
import UiLayoutConst from './UiLayoutConst';

interface UiMinChannelProps {
  smooth: number;
  setSmooth: (n: number) => void;
  volume: number;
  setVolume: (n: number) => void;
  tooltipEnabled: boolean;
}

function UiMinChannel(props: UiMinChannelProps) {
  const lay = new UiLayoutConst();

  //const valid = props.filter < 17;
  //const invalidKey = valid ? 255 : props.filter;

  const ccs = Array.from({length: 16}, (x, i) => i + 1);

  return (
    // <Tooltip title={CCTOOL} placement="top" arrow={true}>
    <>
      <SqTextField
        select
        label="Min Channel"
        size="small"
        onChange={(e: any) => {
          props.setSmooth(parseInt(e.target.value));
        }}
        sx={{mx: lay.mx, my: lay.my, width: 6 * 17}}
        value={props.smooth}
      >
        {ccs.map((key: any) => {
          return (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          );
        })}
      </SqTextField>
      <SqTextField
        select
        label="Max Channel"
        size="small"
        onChange={(e: any) => {
          props.setVolume(parseInt(e.target.value));
        }}
        sx={{mx: lay.mx, my: lay.my, width: 6 * 17}}
        value={props.volume}
      >
        {ccs.map((key: any) => {
          return (
            <MenuItem key={key + 'cc'} value={key}>
              {key}
            </MenuItem>
          );
        })}
      </SqTextField>
    </>
    // </Tooltip>
  );
}

export default UiMinChannel;
