import {WebDFU} from './dfu/index';

//import { WebDFU } from "./webdfu/index";

export class NeDfu {
  progress: (step: string, prog: number, tot: number) => void;
  status: (msg: string) => void;
  connected: () => void;
  done: () => void;
  webdfu: WebDFU | null;
  writeDone: boolean;

  constructor() {
    this.connected = () => {};
    this.status = (msg: string) => {};
    this.done = () => {};
    this.progress = (step: string, done: number, total: number) => {};
    this.webdfu = null;
    this.writeDone = false;
  }

  init(
    progress: (step: string, done: number, total: number) => void,
    status: (msg: string) => void,
    connected: () => void,
    done: () => void
  ) {
    this.connected = connected;
    this.status = status;
    this.done = done;
    this.progress = progress;
  }

  async getDevice(): Promise<USBDevice | null> {
    const filters = [{vendorId: 0x0483, productId: 0xdf11}];

    const devs = await navigator.usb.getDevices();

    for (const dev of devs) {
      if (
        dev.vendorId === filters[0].vendorId &&
        dev.productId === filters[0].productId
      ) {
        return dev;
      }
    }

    return await navigator.usb.requestDevice({filters: filters});
  }

  async connect(): Promise<void> {
    const selectedDevice = await this.getDevice();

    if (!selectedDevice) {
      return;
    }

    this.webdfu = new WebDFU(selectedDevice, {forceInterfacesName: true});

    await this.webdfu.init();

    //console.log(this.webdfu);

    this.webdfu.progress = (step: string, prog: number, tot: number) => {
      this.progress(step, prog, tot);
    };

    this.webdfu.status = (status: string) => {
      this.status(status);
    };

    //console.log(this.webdfu.interfaces);

    if (this.webdfu.interfaces.length === 0) {
      this.status('The selected device does not have any USB DFU interfaces.');
    }

    await this.webdfu.connect(0);

    //console.log('connected', this.webdfu.properties);

    this.connected();
  }

  async fetchAndFlash(getBuf: () => Promise<ArrayBuffer>) {
    try {
      await this.flash(await getBuf());
    } catch (e: any) {
      console.log(e);
      this.status(e.message);
    }

    this.done();
  }

  async flash(bin: ArrayBuffer): Promise<void> {
    this.writeDone = false;

    if (this.webdfu === null) {
      throw new Error('webdfu not initialized');
    }

    //console.log(bin);

    const size = this.webdfu.properties?.TransferSize || 1024;
    const manifest = this.webdfu.properties?.ManifestationTolerant || false;

    let status = await this.webdfu.getStatus();
    if (status.state === 10) {
      await this.webdfu.clearStatus();
    }

    await this.webdfu.write(size, bin, manifest);
  }
}

const nedfu = new NeDfu();

export default nedfu;
