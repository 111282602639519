export default class Plugin {
    release: string;
    os: string;
    version: string;
    code: string;
    name: string;

    static uniq(plugs: Plugin[]) {
        return plugs;
    }

    static getPublic() { return []; }

    constructor(
        release: string,
        os: string,
        version: string,
        code: string,
        name: string
    ) {
        this.release = release;
        this.os = os;
        this.version = version;
        this.code = code;
        this.name = name;
    }

    static fromMeta(meta: any) {
        return new Plugin(meta.category, meta.os, meta.version, meta.code, meta.name);
    }

    toString() { return `plugin ${this.code} ${this.os} ${this.version} ${this.name}`; }

};