import Paper from '@mui/material/Paper';

export default function SqPaper(propsOld: any) {

    var props: any = {};

    Object.keys(propsOld).forEach((k) => {
        props[k] = propsOld[k];
    });

    if (props.style) {
        props.style = {}
        Object.keys(propsOld.style).forEach((k) => {
            props.style[k] = propsOld.style[k];
        });
    }
    else {
        props.style = {};
    }
    //overrides
    props.style.borderRadius = 0;


    return (<Paper {...props}>{props.children}</Paper >);
}
