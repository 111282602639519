import { PropsWithChildren } from 'react'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import Title from './Title';
import PagePaper from './PagePaper';

import getUserInfo from '../ostium/UserInfo';
import If from './If';

interface LegalTextProps {
    title: string;
    setPage: (s: string) => void;
}


function LegalText(props: PropsWithChildren<LegalTextProps>) {
    return (<>
        <Title>{props.title}</Title >
        <PagePaper>
            <If value={!getUserInfo().loggedIn}>
                <Container>
                    <Button size="large" onClick={() => props.setPage('login')} >Back</Button>
                </Container></If>
            <Stack justifyContent="center" alignItems="center">
                <Box overflow="auto" sx={{ height: 1000, my: 1 }}>
                    {props.children}
                </Box>
            </Stack>

        </PagePaper>
    </>
    );
}

export default LegalText;