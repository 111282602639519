import fb from './firebase';
import {ref, getBytes} from 'firebase/storage';
import {FIRMWARE, PUBLIC_RELEASE} from '../const';

export default class Firmware {
  release: string;
  os: string;
  format: string;
  use_bucket: boolean;
  bucket: string;
  filename: string;
  version: string;
  code: string;
  name: string;
  shortDesc: string;
  medDesc: string;
  longDesc: string;
  shopUrl: string;
  manualUrl: string;
  youTubeUrl: string;
  image: string;
  fullname: string;

  static getDescription(name: string, custom: string) {
    if (custom) {
      return custom;
    } else if (FIRMWARE[name]) {
      return FIRMWARE[name];
    } else {
      return 'Firmware Description: ' + name;
    }
  }

  static uniq(fws: Firmware[]) {
    let obj: any = {};

    for (const x of fws) {
      const key = x.name + x.release;
      const o = obj[key];
      if (!o) {
        obj[key] = x;
      } else if (x.release === o.release && x.version > o.version) {
        obj[key] = x;
      }
    }

    let nfws: Firmware[] = [];

    for (const x of Object.keys(obj)) {
      nfws.push(obj[x]);
    }

    nfws.sort((a: Firmware, b: Firmware) => {
      if (a.fullname < b.fullname) return -1;
      if (a.fullname > b.fullname) return 1;
      if (a.fullname === b.fullname) return 0;
      return 0;
    });

    return nfws;
  }

  constructor(
    release: string,
    os: string,
    format: string,
    use_bucket: boolean,
    bucket: string,
    filename: string,
    version: string,
    code: string,
    name: string,
    shortDesc: string = '',
    medDesc: string = '',
    longDesc: string = ''
  ) {
    this.release = release;
    this.os = os;
    this.format = format;
    this.use_bucket = use_bucket;
    this.bucket = bucket;
    this.filename = filename;
    this.version = version;
    this.code = code;
    this.name = FIRMWARE[name]?.name || name;

    this.image = 'panels/' + this.name.split(' ')[0].toLowerCase() + '.png';
    this.fullname =
      release === 'public'
        ? this.name
        : this.name + ' (' + release + ' ' + version + ')';

    this.shortDesc = FIRMWARE[name]?.shortDesc || name;
    this.medDesc = FIRMWARE[name]?.medDesc || name;
    this.longDesc = FIRMWARE[name]?.longDesc || name;
    this.shopUrl = FIRMWARE[name]?.shopUrl || 'https://www.noiseengineering.us';
    this.manualUrl =
      FIRMWARE[name]?.manualUrl || 'https://manuals.noiseengineering.us';
    this.youTubeUrl =
      FIRMWARE[name]?.youTubeUrl || 'https://www.youtube.com/noiseengineering';
  }

  toString() {
    return `firmware ${this.code} ${this.os} ${this.format} ${this.version} ${this.name}`;
  }

  async getBin(): Promise<ArrayBuffer | null> {
    if (this.use_bucket) {
      console.log('getting firmware from bucket\n');
      const r = ref(fb.storage, this.bucket);
      return await getBytes(r);
    } else {
      const r = await fetch('release/' + this.filename);
      return await r.arrayBuffer();
    }
  }

  static getFromFirmwares(firmwares: any): Firmware[] {
    let fws: Firmware[] = [];
    for (let x of firmwares) {
      if (x.os === 'lacerti') {
        fws.push(
          new Firmware(
            'public',
            x.os,
            x.format,
            false,
            x.bucket,
            x.file,
            x.version,
            x.code,
            x.name
          )
        );
      }
    }
    return fws;
  }
  static getPublic(): Firmware[] {
    return Firmware.getFromFirmwares(PUBLIC_RELEASE);
  }
}
