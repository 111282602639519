import Title from './Title';
import PagePaper from './PagePaper';
import ResponsiveContainer from './ResponsiveContainer';
import {TypeOcrH5} from './Types';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import getUserInfo from '../ostium/UserInfo';

interface TabValidateProps {
  setPage: (s: string) => void;
}

export default function TabValidate(props: TabValidateProps) {
  return (
    <>
      <Title>Email Validated</Title>
      <ResponsiveContainer>
        <PagePaper>
          <TypeOcrH5 sx={{pt: 1, m: 3, mb: 2}} align="center">
            Noise Engineering Pangolin says Congratulations!
            <br /> Your account has been verified. <br />
            Login to get this party started.
          </TypeOcrH5>
          <img src="/panels/pangolin.png" alt="I am a pangolin"></img>
          <Container>
            <Button
              sx={{m: 2}}
              size="large"
              onClick={() => {
                console.log(getUserInfo().continueUrl);
                window.location.href = getUserInfo().continueUrl;
              }}
            >
              Go to Login
            </Button>
          </Container>
        </PagePaper>
      </ResponsiveContainer>
    </>
  );
}
