import { isChromium, isChrome } from 'react-device-detect';

export default function IfNotChrome(props: any) {
    if (isChromium || isChrome) {
        return (<></>);
    }
    else {
        return (props.children)
    }

}
