import fb from './firebase';
import {
  User,
  sendEmailVerification,
  getIdTokenResult,
  applyActionCode,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';
import {getDoc, doc, collection, getDocs} from 'firebase/firestore';

import gcf from './functions';
import Installer from './Installer';
import Plugin from './Plugin';
import Firmware from './Firmware';
import Platform from './Platform';
import {filter} from '../ostium/database';

export class UserInfo {
  unsubscribe: any;
  resetLogin: () => void;
  loading: boolean;
  user: User | null;
  uid: string;
  token: any;
  setSubtext: (s: string) => void;
  setPage: (s: string) => void;
  setDisabled: (b: boolean) => void;
  setIsLoggedIn: (b: boolean) => void;
  sendVerifyOnce: boolean;
  installers: Installer[];
  plugins: Plugin[];
  firmwares: Firmware[];
  platforms: Platform[];
  lics: string[];
  perms: string[];
  loggedIn: boolean;
  keepMessage: boolean;
  lastMessage: string;
  landingPage: string;
  oobCode: string;
  continueUrl: string;
  redeemRes: any;
  justValidated: boolean;

  constructor(
    resetLogin: () => void,
    setSubtext: (s: string) => void,
    setPage: (obj: string) => void
  ) {
    this.setSubtext = setSubtext;
    this.setPage = setPage;
    this.sendVerifyOnce = false;
    this.resetLogin = resetLogin;
    this.setDisabled = (b: boolean) => {};
    this.setIsLoggedIn = (b: boolean) => {};

    this.loading = false;
    this.user = null;
    this.installers = [];
    this.plugins = [];
    this.firmwares = [];
    this.lics = [];
    this.perms = [];
    this.uid = '';
    this.loggedIn = false;
    this.keepMessage = false;
    this.lastMessage = '';
    this.platforms = Platform.getPublic();
    this.justValidated = false;

    this.landingPage = 'firmware';
    this.oobCode = '';
    this.continueUrl = '';
    this.redeemRes = undefined;

    this.unsubscribe = fb.auth.onAuthStateChanged((fbuser: User | null) => {
      this.authStateChange(fbuser);
    });
  }

  isFactory() {
    return this.perms.includes('ff');
  }

  static async resetPassword(email: string | undefined | null = '') {
    let actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };
    email = email || fb.auth?.currentUser?.email;
    if (email) {
      await sendPasswordResetEmail(fb.auth, email, actionCodeSettings);
      console.log('send reset pw email');
    } else {
      console.log('user missing email');
    }
  }

  async confirmResetPassword(newPw: string) {
    await verifyPasswordResetCode(fb.auth, this.oobCode);
    await confirmPasswordReset(fb.auth, this.oobCode, newPw);
    window.location.href = this.continueUrl;
  }

  findFirmware(platform: string) {
    const fws = filter('format', platform, this.firmwares);
    fws.sort();
    return fws;
  }

  getEmail() {
    return this.user?.email || '';
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  setMessage(msg: string) {
    this.lastMessage = msg;
    this.setSubtext(msg);
  }

  getLastMessage() {
    return this.lastMessage;
  }

  setUpdate(
    resetLogin: () => void,
    setSubtext: (obj: any) => void,
    setPage: (obj: string) => void,
    setDisabled: (b: boolean) => void,
    setIsLoggedIn: (b: boolean) => void
  ) {
    userInfo.resetLogin = resetLogin;
    userInfo.setSubtext = setSubtext;
    userInfo.setPage = setPage;
    userInfo.setDisabled = setDisabled;
    userInfo.setIsLoggedIn = setIsLoggedIn;
  }

  async authStateChange(u: User | null): Promise<void> {
    console.log('authStateChange ', u?.uid, u?.email);

    if (u === null) {
      console.log('logout');
      this.landingPage = 'login';
      await this.possiblyHandleAction();
      this.possiblyLand();
      console.log('landing', this.landingPage);
      this.loading = false;
      this.user = null;
      this.installers = [];
      this.plugins = [];
      this.firmwares = [];
      this.platforms = [];
      this.lics = [];
      this.perms = [];
      this.uid = '';
      this.loggedIn = false;
      if (!this.keepMessage) {
        this.setMessage('');
      }
      this.keepMessage = false;
      this.setPage(this.landingPage);
      this.resetLogin();
    } else if (this.user === null) {
      //login
      console.log('login');

      this.landingPage = 'plugin';

      if (this.loading) {
        console.log('warning loading twice');
      }

      this.loading = true;

      try {
        this.user = u;

        this.setDisabled(true);

        this.possiblyLand();

        await this.possiblyHandleAction();

        this.setMessage('Checking Validation');

        if (!this.user.emailVerified) {
          this.setMessage(
            'We have sent you an email with a magic link to verify your account. Please click the link to login.'
          );
          this.possiblySendVerify();
          this.keepMessage = true;
          fb.auth.signOut();
          return;
        }

        this.possiblyRedirect();
        this.possiblyThanks();
        await this.possiblyRedeem();
        await this.updateUser();
        await this.loadUser();

        window.history.pushState({}, '', '/'); //clear out any extra url information

        this.loggedIn = true;

        this.updateUserState();
        this.setIsLoggedIn(true);
        this.setPage(this.landingPage);

        console.log('login complete ' + this.user?.uid, this.landingPage);
      } catch (e: any) {
        console.log(JSON.stringify(e, null, 2));
        console.log(e.stack);
        this.setMessage(e.message);
        this.keepMessage = true;
        this.setPage('login');
        this.resetLogin();
      } finally {
        this.loading = false;
      }
    }
  }
  possiblyLand() {
    var args = new URLSearchParams(window.location.search);
    if (!args.has('land')) {
      return;
    }
    this.landingPage = args.get('land') as string;
  }

  async possiblyHandleAction() {
    var args = new URLSearchParams(window.location.search);
    if (!args.has('mode')) {
      return;
    }

    this.setMessage('Checking OOB code');
    console.log('pre login action', args);

    const mode = args.get('mode');
    this.oobCode = args.get('oobCode') || '';
    this.continueUrl = args.get('continueUrl') || '';

    switch (mode) {
      case 'resetPassword':
        await verifyPasswordResetCode(fb.auth, this.oobCode);
        this.landingPage = 'reset';
        break;
      case 'verifyEmail':
        try {
          await applyActionCode(fb.auth, this.oobCode);
          this.justValidated = true;
          this.landingPage = 'validate';
          console.log('verify success', this.continueUrl);
        } catch (e: any) {
          this.keepMessage = true;
          this.setMessage('Verify Failure: ' + e.message);
        }
        break;
      default:
        // Error: invalid mode.
        console.log('invalid mode');
    }
  }

  async possiblySendVerify(): Promise<void> {
    if (!this.sendVerifyOnce && this.user) {
      this.sendVerifyOnce = true;

      let actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };

      console.log('resend user email verification\n');

      try {
        await sendEmailVerification(this.user, actionCodeSettings);
      } catch (e: any) {
        console.log(e);
        if (e.code === 'auth/too-many-requests') {
          console.log('waiting on email timeout..');
        } else {
          this.setMessage('Verify Failure: ' + e.message);
        }
      }
    }
  }
  possiblyThanks() {
    if (window.location.href.includes('/thanks')) {
      this.landingPage = 'redirect';
    }
  }
  possiblyRedirect() {
    this.setMessage('Checking redirect');

    var url = new URL(window.location.href);
    var redirect: string | null = url.searchParams.get('redirect');

    if (redirect && this.user) {
      window.location.replace(redirect + '?refresh=' + this.user.refreshToken);
      //window.open(redirect + '?refresh=' + this.user.refreshToken, '_blank');
    }
  }

  async possiblyRedeem(): Promise<void> {
    this.setMessage('Checking redeem');
    if (!this.user) throw Error('cannot load null user\n');

    var keya = 'shop_redeem_guid=';
    var keyb = 'key=';
    var key = '';
    var url = window.location.href;

    if (url.includes(keya)) {
      key = keya;
    }

    if (url.includes(keyb)) {
      key = keyb;
    }

    if (key !== '') {
      this.setMessage('Redeeming key.');
      // this.setUser({ "redeemed": true });

      const guid = url.split(key).slice(-1)[0].split('&')[0];

      const res: any = await gcf.redeem(guid);
      this.setMessage(res.message);

      this.user.reload();

      this.landingPage = 'redeem';
    }
  }

  async refresh() {
    if (this.user) {
      await this.user.reload();
      await this.updateUser();
      await this.loadUser();
      this.updateUserState();
    }
  }

  async updateUser() {
    if (!this.user) {
      throw Error('cannot load null user\n');
    }
    this.token = await getIdTokenResult(this.user, true);
    this.perms = this.token.claims['permissions'] || [];
    this.lics = this.token.claims['licenses'] || [];
    this.uid = this.token.claims['guid'] || this.user.uid;
  }

  async loadUser(): Promise<void> {
    if (this.user === null) {
      throw Error('cannot load null user\n');
    }

    this.setMessage('Loading Products');

    this.installers = [];
    this.plugins = [];
    this.firmwares = [];

    await Promise.all([
      this.doPing(),
      this.addProducts(),
      this.addFirmware('public'),
      this.perms.includes('fb') ? this.addFirmware('beta') : Promise.resolve(),
      this.perms.includes('fa') ? this.addFirmware('test') : Promise.resolve(),
      this.perms.includes('ff')
        ? this.addFirmware('factory')
        : Promise.resolve(),
    ]);

    console.log('done loading products');

    this.loading = false;
  }

  updateUserState() {}

  async addProducts() {
    var args = {
      type: 'plugin',
      os: '',
      installerUrls: true,
      pluginUrls: false,
      addInstaller: true,
    };
    var res: any = await gcf.product(args);

    if (res.data.status === 'success') {
      if (res.data.products) {
        for (const p of res.data.products) {
          if (p.code === 'PLGM') {
            const i = Installer.fromMeta(p);
            this.installers.push(i);
          } else {
            const i = Plugin.fromMeta(p);
            this.plugins.push(i);
          }
        }
      }
    } else {
      console.log('products failure', res);
    }
  }

  async doPing() {
    console.log('ping:', (await gcf.ping({input: 'input'})).data);
  }

  async addInstaller(release: string, os: string): Promise<void> {
    let pd = await getDoc(
      doc(fb.firestore, 'release_plugin_' + release, 'plgm_' + os + '_inst')
    );
    let pr = pd.data();

    if (pr) {
      const inst = new Installer(
        release,
        os,
        true,
        pr['bucket'],
        pr['filename'],
        pr['version'],
        pr['code'],
        ''
      );
      this.installers.push(inst);
    }
  }

  async addFirmware(release: string) {
    let col = collection(fb.firestore, 'release_firmware_' + release);
    let docs = await getDocs(col);
    let fws: Firmware[] = [];

    docs.forEach((doc) => {
      const d: any = doc.data();
      const fw = new Firmware(
        release,
        d.os,
        d.format,
        true,
        d.bucket,
        d.file,
        d.version,
        d.code,
        d.name,
        ''
      );
      this.firmwares.push(fw);
      fws.push(fw);
    });

    const old_plats = this.platforms;
    this.platforms = [];

    for (const x of old_plats) {
      if (!this.hasPlatform(x)) {
        this.platforms.push(x);
      }
    }
    for (const x of Platform.getFromFirmwares(fws)) {
      if (!this.hasPlatform(x)) {
        this.platforms.push(x);
      }
    }
  }

  hasPlatform(fw: Platform) {
    for (const x of this.platforms) {
      if (x.code === fw.code) {
        return true;
      }
    }
    return false;
  }
}

let userInfo: UserInfo = new UserInfo(
  () => {},
  (obj: any) => {},
  (obj: any) => {}
);

export default function getUserInfo() {
  return userInfo;
}
