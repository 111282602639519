

export function match(field: string, value: string, array: any) {
    for (const x of array) {
        if (x[field] && x[field] === value) { return x; }
    }
    return null;
}

export function filter(field: string, value: string, array: any[]) {
    let s: any[] = [];

    for (const x of array) {
        if (x[field] && x[field] === value) { s.push(x); }
    }

    return s;
}


