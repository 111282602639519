import UiConfig from './UiConfig';
import {generate} from 'random-words';
import webmidi from './midi';
import {Port} from './midi';

export const SYSEX_START = 0xf0;
export const SYSEX_ID0 = 0x00;
export const SYSEX_ID1 = 0x02;
export const SYSEX_ID2 = 0x44;
export const DEVICE_ID = 0x55;
export const SYSEX_END = 0xf7;

export const DFU_KEY = 0x23;

export const UI_NOP = 0;
export const UI_SETUP = 1;
export const UI_UID = 2;
export const UI_SETUP_CHAIN = 3;
export const UI_SET_ADDR = 4;
export const UI_BLINK = 5;
export const UI_GET_CONFIG = 6;
export const UI_SET_CONFIG = 7;
export const UI_SET_TUNING = 8;
export const UI_DFU_MODE = 9;
export const UI_SET_NAME = 10;
export const UI_TEST = 11;

export const UI_FILE_CONFIG = 0;
export const UI_FILE_TUNING = 0;

export const TEST_MCP_LOOP = 0;
export const TEST_MIDI_LOOP = 1;
export const TEST_CV_OUT = 2;

export const STATE_START = 0;
export const STATE_TEST = 1;
export const STATE_SUCCESS = 2;
export const STATE_FAILURE = 3;

export const FLAGS_CHAINED = 1;
export const ROUTE_MIDI_THRU = 1;
export const ROUTE_USB_MIDI = 2;

export const CURRENT_VERSION = 8;
export const OLD_FIRMWARE = 0xffffffff;

export function bytes2nibs(src: Uint8Array): Uint8Array {
  var dst = new Uint8Array(2 * src.length);

  for (var i = 0; i < src.length; i++) {
    dst[2 * i + 0] = (src[i] >> 4) & 0xf;
    dst[2 * i + 1] = src[i] & 0xf;
  }

  {
    //test invertability
    const tst = nibs2bytes(dst);

    for (let i = 0; i < tst.length; i++) {
      if (tst[i] !== src[i]) throw new Error();
    }
  }

  return dst;
}

export function nibs2bytes(src: Uint8Array): Uint8Array {
  var dst = new Uint8Array(src.length / 2);

  for (var i = 0; i < dst.length; i++) {
    dst[i] = (src[2 * i + 0] << 4) | src[2 * i + 1];
  }

  return dst;
}

function getU32(ofs: number, msg: Uint8Array): number {
  return (
    (msg[0 + ofs] << 0) |
    (msg[1 + ofs] << 8) |
    (msg[2 + ofs] << 16) |
    (msg[3 + ofs] << 24)
  );
}

function getByte(shift: number, bal: number): number {
  return (bal >> shift) & 0xff;
}

function setU32(ofs: number, val: number, msg: Uint8Array) {
  msg[0 + ofs] = getByte(0, val);
  msg[1 + ofs] = getByte(8, val);
  msg[2 + ofs] = getByte(16, val);
  msg[3 + ofs] = getByte(24, val);
}

export function toSysexUi(
  command: number,
  addr: number,
  src: Uint8Array
): Uint8Array {
  const body = bytes2nibs(src);
  const msg = new Uint8Array(body.length + 8);

  let i = 0;

  msg[i++] = SYSEX_START;
  msg[i++] = SYSEX_ID0;
  msg[i++] = SYSEX_ID1;
  msg[i++] = SYSEX_ID2;
  msg[i++] = DEVICE_ID;
  msg[i++] = addr; //0x7f;
  msg[i++] = command;

  for (let c = 0; c < body.length; c++) {
    msg[i++] = body[c];
  }

  msg[i++] = SYSEX_END;

  return msg;
}

export function isSysex(src: Uint8Array) {
  return src[0] === SYSEX_START;
}

export function isSysexUi(src: Uint8Array) {
  return (
    src[0] === SYSEX_START &&
    src[1] === SYSEX_ID0 &&
    src[2] === SYSEX_ID1 &&
    src[3] === SYSEX_ID2 &&
    src[4] === DEVICE_ID
  );
}

export function isSysexUiCmd(cmd: number, src: Uint8Array) {
  return isSysexUi(src) && src[6] === cmd;
}

export function getSysexAddr(src: Uint8Array) {
  return src[5];
}

export function fromSysexUi(
  cmd: number | undefined,
  src: Uint8Array
): Uint8Array {
  if (src[0] !== SYSEX_START) {
    throw new Error('Bad sysex buffer: no start ' + src[0] + ' ' + SYSEX_START);
  }
  if (src[1] !== SYSEX_ID0) {
    throw new Error('Bad sysex buffer: bad id0 ' + src[1]);
  }
  if (src[2] !== SYSEX_ID1) {
    throw new Error('Bad sysex buffer: bad id1 ' + src[2]);
  }
  if (src[3] !== SYSEX_ID2) {
    throw new Error('Bad sysex buffer: bad id2 ' + src[3]);
  }
  if (src[4] !== DEVICE_ID) {
    throw new Error('Bad sysex buffer: bad device ' + src[4]);
  }

  //5 is unit id - ignored

  if (cmd !== undefined) {
    if (src[6] !== cmd) {
      throw new Error('expected command ' + cmd + ' got ' + src[6]);
    }
  }

  return nibs2bytes(src.slice(7, src.length - 1));
}

export function isUiSysex(cmd: number, src: Uint8Array) {
  try {
    fromSysexUi(cmd, src);
    return true;
  } catch (e: any) {
    return false;
  }
}

export function toSysexPing(v: number): Uint8Array {
  return toSysexUi(UI_SETUP, 0x7f, new Uint8Array([v]));
}

interface UiPong {
  index: number;
  version: number;
  uid: number;
  firmware: number;
}

export function fromSysexPong(array: Uint8Array): UiPong {
  const msg = fromSysexUi(UI_UID, array);
  return {
    index: msg[0],
    version: msg[1],
    uid: getU32(2, msg),
    firmware: msg.length > 8 ? getU32(6, msg) : OLD_FIRMWARE,
  };
}

export function toSysexGetConfig(addr: number): Uint8Array {
  return toSysexUi(UI_GET_CONFIG, addr, new Uint8Array([UI_FILE_CONFIG]));
}

export function toSysexGetTuning(addr: number): Uint8Array {
  return toSysexUi(UI_GET_CONFIG, addr, new Uint8Array([UI_FILE_TUNING]));
}

export function toSysexBlink(addr: number, blink: boolean): Uint8Array {
  return toSysexUi(UI_BLINK, addr, new Uint8Array([blink ? 1 : 0]));
}

export function toSysexSetAddr(addr: number, uid: number): Uint8Array {
  const array = new Uint8Array(5);
  setU32(0, uid, array);
  array[4] = addr;
  return toSysexUi(UI_SET_ADDR, 0x7f, array);
}

export function toSysexDFU(addr: number): Uint8Array {
  return toSysexUi(UI_DFU_MODE, addr, new Uint8Array([DFU_KEY]));
}

interface UiTest {
  type: number;
  state: number;
}

export function toSysexTest(addr: number, test: number): Uint8Array {
  return toSysexUi(UI_TEST, addr, new Uint8Array([test, STATE_START]));
}

export function fromSysexTest(array: Uint8Array): UiTest {
  const msg = fromSysexUi(UI_TEST, array);
  return {type: msg[0], state: msg[1]};
}

let addr_alloc = 15;

function nextAlloc() {
  addr_alloc = (addr_alloc + 1) & 0x7f;
  return addr_alloc;
}

export default class UiDevice {
  inId: string;
  inName: string;
  outId: string;
  outName: string;
  config: UiConfig | null;
  uid: number;
  version: number;
  firmware: number;
  outIndex: number;
  addr: number;
  setMidi: (s: string) => void;
  setMcp: (s: string) => void;
  aliveTime: number;
  saveConfirm: boolean;
  haveSaved: boolean;

  constructor(
    inId: string,
    inName: string,
    outId: string,
    outName: string,
    outIndex: number,
    version: number,
    firmware: number,
    uid: number
  ) {
    this.inId = inId;
    this.inName = inName;
    this.outId = outId;
    this.outName = outName;
    this.config = null;
    this.outIndex = outIndex;
    this.version = version;
    this.firmware = firmware;
    this.uid = uid;
    this.addr = nextAlloc();
    this.setMcp = (s: string) => {};
    this.setMidi = (s: string) => {};
    this.aliveTime = Date.now();
    this.saveConfirm = false;
    this.haveSaved = false;
  }

  getKey() {
    return this.inId;
  }

  getUidString() {
    return generate({exactly: 1, seed: this.uid.toString()});
  }

  toString() {
    if (this.config && this.uid) {
      return this.config.name;
    } else if (this.config) {
      return this.config.name;
    } else {
      return '(loading config)';
    }
  }

  getOut() {
    return webmidi.getPort(this.outId).getOutput();
  }

  getInp() {
    return webmidi.getPort(this.inId).getInput();
  }

  send(a: Uint8Array) {
    this.getOut().send(a);
  }

  sysex(p: Port, msg: Uint8Array) {
    if (isSysexUiCmd(UI_SET_CONFIG, msg)) {
      if (this.config != null) {
        if (this.haveSaved) {
          this.saveConfirm = true;
        }
      }
      this.config = UiConfig.fromSysex(msg);
      return true;
    } else if (isSysexUiCmd(UI_SET_TUNING, msg)) {
      //save tuning
      return true;
    } else if (isSysexUiCmd(UI_BLINK, msg)) {
      this.aliveTime = Date.now();
      //console.log(this.addr, 'keepalive', this.aliveTime, this.uid);
      return false;
    } else if (isSysexUiCmd(UI_TEST, msg)) {
      const res = fromSysexTest(msg);

      if (res.type === TEST_MCP_LOOP) {
        if (res.state === STATE_SUCCESS) {
          this.setMcp('Mcp Loopback Pass');
        } else {
          this.setMcp('Mcp Loopback Fail');
        }
      } else if (res.type === TEST_MIDI_LOOP) {
        if (res.state === STATE_SUCCESS) {
          this.setMidi('MIDI Loopback Pass');
        } else {
          this.setMidi('MIDI Loopback Fail');
        }
      } else {
        console.log('bad test:', res);
      }
      return true;
    }

    return true;
  }

  setAddr() {
    console.log('set addr', this.inId, this.uid, this.addr);
    this.send(toSysexSetAddr(this.addr, this.uid));
  }

  getConfig() {
    console.log('get config', this.inId, this.addr, this.uid.toString(16));
    this.send(toSysexGetConfig(this.addr));
  }

  blink(b: boolean) {
    if (webmidi.hasPort(this.outId)) {
      this.getOut().send(toSysexBlink(this.addr, b));
    }
  }

  dfu() {
    this.getOut().send(toSysexDFU(this.addr));
  }

  start_test(setMidi: (s: string) => void, setMcp: (s: string) => void) {
    this.getOut().send(toSysexTest(this.addr, TEST_MCP_LOOP));
    this.getOut().send(toSysexTest(this.addr, TEST_MIDI_LOOP));
    this.getOut().send(toSysexTest(this.addr, TEST_CV_OUT));
    this.setMidi = setMidi;
    this.setMcp = setMcp;
  }

  static ping(p: Port) {
    console.log('ping', p.id);
    p.getOutput().send(toSysexPing(p.index));
  }

  setConfig(c: UiConfig) {
    console.log('set config', this.uid, this.addr);
    this.haveSaved = true;
    this.config = c;
    this.send(UiConfig.toSysex(c));
  }

  needsUpgrade(currentFirmware: number) {
    if (currentFirmware === 0) {
      return false;
    }

    if (this.firmware === 0) {
      return true;
    }

    if (this.version < CURRENT_VERSION) {
      return true;
    }

    if (this.firmware < currentFirmware) {
      return true;
    }

    return false;
  }
}
