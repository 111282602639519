import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import * as React from 'react';

import {createUserWithEmailAndPassword} from 'firebase/auth';
import fb from '../ostium/firebase';
import gcf from '../ostium/functions';
import SqTextField from './SqTextField';
import {TypeOcrH6} from './Types';

interface CreateAccountProps {
  email: string;
  disabled: boolean;
  pw: string;
  pwc: string;
  enableLogin: () => void;
  setEmail: (s: string) => void;
  setPw: (s: string) => void;
  setPwc: (s: string) => void;
  setSubtext: (s: string) => void;
  subtext: string;
}

export default function CreateAccount(props: CreateAccountProps) {
  const [age, setAge] = React.useState(false);
  const [tac, setTac] = React.useState(false);

  async function create() {
    if (props.pw !== props.pwc) {
      props.setSubtext('Passwords do not match.');
    } else if (!tac) {
      props.setSubtext('You must agree to terms and conditions.');
    } else if (!age) {
      props.setSubtext('You must verify your age.');
    } else {
      props.setSubtext(
        'We have sent you an email to ' +
          props.email +
          ' with a magic link to verify your account. Please click that link to login.'
      );

      try {
        await createUserWithEmailAndPassword(fb.auth, props.email, props.pw);
        gcf.signup(props.email);
      } catch (e: any) {
        console.log(e);
        props.setSubtext(e.message);
      }
    }
  }
  function checkPw(pw: string, pwc: string) {
    const message = 'Passwords do not match.';
    if (pw !== pwc) {
      props.setSubtext(message);
    }
    if (pw === pwc && props.subtext === message) {
      props.setSubtext('');
    }
  }

  function getNoMatchVis(): any {
    if (props.pw === props.pwc) {
      return (
        <TaskAltOutlinedIcon
          sx={{
            m: 1,
          }}
        />
      );
    } else {
      return (
        <UnpublishedOutlinedIcon
          sx={{
            m: 1,
          }}
          color="primary"
        />
      );
    }
  }

  function check(): boolean {
    return (
      tac && age && props.email !== '' && props.pw !== '' && props.pwc !== ''
    );
  }

  return (
    <>
      <TypeOcrH6>Create an Account</TypeOcrH6>

      <Box sx={{mx: 5, my: 5, height: 20}}>
        <Typography align="center">{props.subtext}</Typography>
      </Box>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <SqTextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            variant="outlined"
            onChange={(e: any) => props.setEmail(e.target.value)}
            value={props.email}
            disabled={props.disabled}
            style={{width: '60%'}}
            onKeyUp={(e: any) => {
              if (e.keyCode === 13) {
                create();
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SqTextField
            autoFocus
            margin="dense"
            id="pw"
            label="Password"
            type="password"
            variant="outlined"
            style={{width: '60%'}}
            onChange={(e: any) => {
              props.setPw(e.target.value);
              checkPw(e.target.value, props.pwc);
            }}
            value={props.pw}
            disabled={props.disabled}
            onKeyUp={(e: any) => {
              if (e.keyCode === 13) {
                create();
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SqTextField
            type="password"
            autoFocus
            margin="dense"
            id="pwc"
            label="Confirm Password"
            variant="outlined"
            style={{width: '60%'}}
            onChange={(e: any) => {
              props.setPwc(e.target.value);
              checkPw(props.pw, e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getNoMatchVis()}
                </InputAdornment>
              ),
            }}
            value={props.pwc}
            disabled={props.disabled}
            onKeyUp={(e: any) => {
              if (e.keyCode === 13) {
                create();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Checkbox
              sx={{}}
              checked={age}
              onChange={(e) => {
                setAge(e.target.checked);
              }}
            />
            <Typography sx={{}}> I am over 16 years of age. </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Checkbox
              sx={{mb: 3}}
              checked={tac}
              onChange={(e) => setTac(e.target.checked)}
            />
            <Typography sx={{}}>
              I accept the Terms and Conditions, the End-User License Agreement,
              <br />
              the Privacy Policy, and the Cookies Policy.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Button size="large" onClick={() => create()} disabled={!check()}>
            Create Account
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button size="large" onClick={() => props.enableLogin()}>
            Back to Login
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
