import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import PagePaper from './PagePaper';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Divider from '@mui/material/Divider';

import {TypeOcrH4, TypeOcrH5} from './Types';
import {PAGE_FAQS, PAGE_CONTACT} from '../const';
import {LoginButton} from './Breadcrumbs';
import ResponsiveContainer from './ResponsiveContainer';

interface TabSupportProps {
  setCurrentTab: (s: string) => void;
}

export default function TabSupport(props: TabSupportProps) {
  return (
    <>
      <PagePaper sx={{mb: 2, mt: 3}}>
        <ResponsiveContainer>
          <Box
            bgcolor="info.main"
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 2,
              width: '100%',
            }}
            sx={{minHeight: 200, mt: 3, mb: 5}}
          >
            <TypeOcrH4
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="error.main"
            >
              Customer Support
            </TypeOcrH4>
          </Box>
        </ResponsiveContainer>
        <ResponsiveContainer>
          <Grid container spacing={4} columns={{xs: 4, sm: 4, md: 12, xl: 12}}>
            <Grid xs={4}>
              <Button
                sx={{mt: 2, mb: 2, height: 200, width: 350}}
                variant="contained"
                color="warning"
                disableElevation
                style={{borderRadius: 0}}
                onClick={(e: any) => props.setCurrentTab(PAGE_FAQS)}
              >
                <Stack alignItems="start">
                  <HelpOutlineIcon sx={{mt: 0, mb: 3}}></HelpOutlineIcon>{' '}
                  <TypeOcrH5 textAlign="left" textTransform="none">
                    FAQs
                  </TypeOcrH5>
                  <Divider
                    component="div"
                    role="presentation"
                    style={{width: '100%'}}
                    sx={{bgcolor: 'info.main', mb: 2}}
                  />
                  <Typography textAlign="left" textTransform="none">
                    {' '}
                    Click here for answers to common questions.
                  </Typography>
                </Stack>
              </Button>
            </Grid>

            <Grid xs={4}>
              <Button
                sx={{mt: 2, mb: 2, height: 200, width: 350}}
                variant="contained"
                color="warning"
                disableElevation
                style={{borderRadius: 0}}
                onClick={(e: any) => props.setCurrentTab(PAGE_CONTACT)}
              >
                {' '}
                <Stack alignItems="start">
                  {' '}
                  <MailOutlineIcon sx={{mt: 0, mb: 3}}></MailOutlineIcon>{' '}
                  <TypeOcrH5 textAlign="left" textTransform="none">
                    Contact Us
                  </TypeOcrH5>
                  <Divider
                    component="div"
                    role="presentation"
                    style={{width: '100%'}}
                    sx={{bgcolor: 'info.main', mb: 2}}
                  />
                  <Typography textAlign="left" textTransform="none">
                    {' '}
                    Still have questions? We'd love to hear from you. Talk to
                    us!{' '}
                  </Typography>
                </Stack>
              </Button>
            </Grid>
            <Grid xs={4}>
              <Button
                sx={{mt: 2, mb: 2, height: 200, width: 350}}
                variant="contained"
                color="warning"
                disableElevation
                style={{borderRadius: 0}}
                onClick={() => {
                  window.open('https://manuals.noiseengineering.us/', '_blank');
                }}
              >
                <Stack justifyContent="left" alignItems="left">
                  {' '}
                  <MenuBookIcon sx={{mt: 0, mb: 3}}></MenuBookIcon>{' '}
                  <TypeOcrH5 textAlign="left" textTransform="none">
                    Manuals
                  </TypeOcrH5>
                  <Divider
                    component="div"
                    role="presentation"
                    style={{width: '100%'}}
                    sx={{bgcolor: 'info.main', mb: 2}}
                  />
                  <Typography textAlign="left" textTransform="none">
                    {' '}
                    All your manual and patchbook needs in one place.{' '}
                  </Typography>
                </Stack>
              </Button>
            </Grid>
          </Grid>
          <LoginButton setCurrentTab={props.setCurrentTab}></LoginButton>
        </ResponsiveContainer>
      </PagePaper>
    </>
  );
}
