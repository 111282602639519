import {behGetByNum} from './UiBehavior';

export default class UiOutput {
  behavior: number;
  channel: number;
  filter: number;
  delay: number;
  scale: number;
  offset: number;
  smooth: number;
  volume: number;

  constructor() {
    this.behavior = 0;
    this.filter = 255;
    this.channel = 0;
    this.delay = 0;
    this.scale = 0;
    this.offset = 36;

    this.smooth = 0;
    this.volume = 0;
  }

  static fromObj(obj: any) {
    const o = new UiOutput();
    o.behavior = obj.behavior;
    o.filter = obj.filter;
    o.channel = obj.channel;
    o.delay = obj.delay;
    o.scale = obj.scale;
    o.offset = obj.offset;
    o.smooth = obj.smooth;
    o.volume = obj.volume;
    return o;
  }

  differs(o: UiOutput): boolean {
    return (
      this.behavior !== o.behavior ||
      this.channel !== o.channel ||
      this.filter !== o.filter ||
      this.delay !== o.delay ||
      this.scale !== o.scale ||
      this.offset !== o.offset ||
      this.smooth !== o.smooth ||
      this.volume !== o.volume
    );
  }

  static toArray(state: UiOutput, offset: number, array: Uint8Array) {
    array[offset + 0] = state.behavior;
    array[offset + 1] = state.channel === 255 ? 0 : state.channel;
    array[offset + 2] = state.filter;
    array[offset + 3] = state.delay;
    array[offset + 4] = state.scale;
    array[offset + 5] = state.offset;
    array[offset + 6] = state.smooth;
    array[offset + 7] = state.volume;

    return 8;
  }

  static fromArray(state: UiOutput, offset: number, array: Uint8Array) {
    state.behavior = array[offset + 0];
    state.channel = array[offset + 1];
    state.filter = array[offset + 2];
    state.delay = array[offset + 3];
    state.scale = array[offset + 4];
    state.offset = array[offset + 5];
    state.smooth = array[offset + 6];
    state.volume = array[offset + 7];

    return 8;
  }

  defaults(beh: number) {
    const b = behGetByNum(beh);

    var that: any = this;

    for (const k of Object.keys(b.default)) {
      //console.log(k, b.default[k]);
      that[k] = b.default[k];
    }
  }
}
