import {saveAs} from 'file-saver';

export interface StringMap {
  [key: string]: string;
}

export function parseUrl(params: string): StringMap {
  var d: StringMap = {};

  if (!params.includes('?')) {
    return d;
  }

  const bits = params.split('?');

  const pieces = bits[bits.length < 2 ? 0 : 1].split('&');

  for (const pair of pieces) {
    const s = pair.split('=');
    if (s.length < 2) continue;
    d[s[0]] = s[1];
  }

  return d;
}

export async function saveUrl(url: string, filename: string) {
  const f = await fetch(url);
  const a = await f.arrayBuffer();
  const u = new Uint8Array(a);
  const b = new Blob([u]);

  saveAs(b, filename);
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function flip(obj: any) {
  const jbo: any = {};

  for (const x of Object.keys(obj)) {
    jbo[obj[x]] = x;
  }

  return jbo;
}

export function uniq(a: any[]) {
  return [...new Set(a)];
}

export function objMap(obj: any, cb: (key: any, val: any) => {}) {
  const res: any = {};
  for (const k of Object.keys(obj)) {
    const v: any = obj[k];
    const r: any = cb(k, v);
    for (const nk of Object.keys(r)) {
      res[nk] = r[nk];
    }
  }
  return res;
}
