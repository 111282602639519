import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import SqTextField from './SqTextField';
import UiLayoutConst from './UiLayoutConst';
import ControlledTooltip from './ControlledTooltip';
import {BENDAMTTOOL} from '../const';

interface UiBendAmountProps {
  bend: number;
  setBend: (s: number) => void;
  tooltipEnabled: boolean;
}

function UiBendAmountItem(ms: number) {
  return (
    <MenuItem key={ms + 'bend'} value={ms}>
      {ms}
    </MenuItem>
  );
}

function UiBendAmount(props: UiBendAmountProps) {
  const lay = new UiLayoutConst();

  return (
    <ControlledTooltip
      title={BENDAMTTOOL}
      placement="top"
      arrow={true}
      tooltipEnabled={props.tooltipEnabled}
    >
      <SqTextField
        select
        label="Bend Amt"
        size="small"
        onChange={(e: any) => {
          props.setBend(e.target.value);
        }}
        value={props.bend}
        sx={{mx: lay.mx, my: lay.my, width: 5 * 17}}
      >
        {UiBendAmountItem(0)}
        {UiBendAmountItem(1)}
        {UiBendAmountItem(2)}
        {UiBendAmountItem(3)}
        {UiBendAmountItem(4)}
        {UiBendAmountItem(5)}
        {UiBendAmountItem(6)}
        {UiBendAmountItem(7)}
        {UiBendAmountItem(8)}
        {UiBendAmountItem(9)}
        {UiBendAmountItem(10)}
        {UiBendAmountItem(11)}
        {UiBendAmountItem(12)}
      </SqTextField>
    </ControlledTooltip>
  );
}

export default UiBendAmount;
