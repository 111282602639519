import webmidi from './midi';
import {Port} from './midi';
import nedfu from './dfu';
import {sleep} from './util';
import UiConfig from './UiConfig';
import Firmware from '../ostium/Firmware';

import UiDevice, {
  isSysexUiCmd,
  isSysexUi,
  isSysex,
  fromSysexPong,
  UI_UID,
  getSysexAddr,
} from './UiDevice';

class UiMidiRouter {
  idx2out: Map<number, string>;
  addr2uid: Map<number, number>;
  uis: Map<number, UiDevice>;

  firstUi: number;

  updateState: () => void;

  blinkUi: UiDevice | undefined;

  sequence: number;

  upgradeConfig: UiConfig | null;
  upgradeUid: number | null;

  learn: any;

  constructor() {
    this.updateState = () => {};

    this.uis = new Map<number, UiDevice>();
    this.idx2out = new Map<number, string>();
    this.addr2uid = new Map<number, number>();

    this.blinkUi = undefined;
    this.firstUi = 0;
    this.sequence = 0;
    this.upgradeConfig = null;
    this.upgradeUid = null;
    this.learn = (
      type: string,
      channel: number,
      data1: number,
      data2: number
    ) => {
      //console.log(type, channel, data1, data2);
    };

    this.sense();
  }

  setLearnCallback(callback: any) {
    this.learn = callback;
  }

  clearState() {
    this.idx2out.clear();
    this.addr2uid.clear();
    this.uis.clear();
    this.firstUi = 0;
    this.blinkUi = undefined;
    webmidi.disable();
    this.updateState();
  }

  async scan() {
    console.log('new midi scan');

    this.clearState();

    await sleep(1000);

    webmidi.setCallback(
      (p: Port) => {
        if (p.port.type === 'input') {
          this.setupInput(p);
        }
        if (p.port.type === 'output') {
          console.log('found new output', p.port);
          this.setupOutput(p);
        }
      },
      (p: Port, message: WebMidi.MIDIMessageEvent) => {
        this.inputMessage(p, message);
      }
    );

    await webmidi.enable();

    webmidi.forEach('input', (port: Port) => {
      this.setupInput(port);
    });

    webmidi.forEach('output', (port: Port) => {
      this.setupOutput(port);
    });

    this.updateState();
  }

  forEach(cb: (ui: UiDevice) => void) {
    this.uis.forEach((ui) => {
      cb(ui);
    });
  }

  map(cb: (ui: UiDevice) => any) {
    const out: any[] = [];

    this.forEach((ui) => {
      out.push(cb(ui));
    });

    return out;
  }

  getSequence() {
    return this.sequence;
  }

  setupInput(p: Port) {
    console.log('setup input:', p.id, p.port.name);

    p.port.open();

    p.getInput().onmidimessage = (message) => {
      this.inputMessage(p, message);
    };

    webmidi.forEach('output', (port: Port) => {
      UiDevice.ping(port);
    });

    this.updateState();
  }

  setupOutput(p: Port) {
    console.log('setup output:', p.id, p.port.name);

    p.port.open();

    if (!this.idx2out.has(p.index)) {
      this.idx2out.set(p.index, p.id);
    }

    UiDevice.ping(p);
    this.updateState();
  }

  learnMesage(p: Port, message: WebMidi.MIDIMessageEvent) {
    if (!this.learn) {
      return;
    }

    var command = message.data[0] >> 4;
    var channel = message.data[0] & 0x0f;
    var data1 = message.data[1];
    var data2 = message.data[2];

    switch (command) {
      case 0x8:
        this.learn('note off', channel, data1, data2);
        break;
      case 0x9:
        this.learn(data2 === 0 ? 'note off' : 'note on', channel, data1, data2);
        break;
      case 0xa:
        this.learn('note touch', channel, data1, data2);
        break;
      case 0xb:
        if (data1 < 120) {
          this.learn('cc', channel, data1, data2);
        } else {
          switch (data1) {
            case 120:
              this.learn('silence', channel, data1, data2);
              break;
            case 121:
              this.learn('all control off', channel, data1, data2);
              break;
            case 122:
              this.learn('local control', channel, data1, data2);
              break;
            case 123:
              this.learn('all notes off', channel, data1, data2);
              break;
            case 124:
              this.learn('omni off', channel, data1, data2);
              break;
            case 125:
              this.learn('omni on', channel, data1, data2);
              break;
            case 126:
              this.learn('mono on', channel, data1, data2);
              break;
            case 127:
              this.learn('poly on', channel, data1, data2);
              break;
          }
        }
        break;
      case 0xc:
        this.learn('program', channel, data1, data2);

        break;
      case 0xd:
        this.learn('touch', channel, data1, data2);
        break;
      case 0xe:
        this.learn('bend', channel, data1, data2);
        break;
      case 0xf:
        switch (channel) {
          default:
          case 0x0:
            break;
          case 0x1:
            break;
          case 0x2:
            this.learn('spp', channel, data1, data2);
            break;
          case 0x3:
            this.learn('song', channel, data1, data2);
            break;
          case 0x4:
            break;
          case 0x5:
            break;
          case 0x6:
            this.learn('tune request', channel, data1, data2);
            break;
          case 0x7:
            break;
          case 0x8:
            break; //clock, ignored
          case 0x9:
            break;
          case 0xa:
            this.learn('start', channel, data1, data2);
            break;
          case 0xb:
            this.learn('resume', channel, data1, data2);
            break;
          case 0xc:
            this.learn('stop', channel, data1, data2);
            break;
          case 0xd:
            break;
          case 0xe:
            break; //sense
          case 0xf:
            this.learn('reset', channel, data1, data2);
            break;
        }
        break;
    }
  }

  inputMessage(p: Port, message: WebMidi.MIDIMessageEvent) {
    if (!isSysex(message.data)) {
      if (message.data[0] !== 240) {
        this.learnMesage(p, message);
      }
      return;
    }

    if (!isSysexUi(message.data)) {
      console.log('non UI message', p, message);
      return;
    }

    //console.log(message);

    this.sequence++;

    if (isSysexUiCmd(UI_UID, message.data)) {
      console.log('pong', p.id, p.port.name);

      const pong = fromSysexPong(message.data);

      console.log(pong);

      if (!this.uis.has(pong.uid)) {
        const outKey = this.idx2out.get(pong.index);

        if (!outKey) {
          console.log(pong);
          throw Error('Missing outkey for ' + p.id);
        }

        const ui = new UiDevice(
          p.id,
          p.port?.name || 'unknown',
          outKey,
          webmidi.getPort(outKey)?.port?.name || 'unknown',
          pong.index,
          pong.version,
          pong.firmware,
          pong.uid
        );
        console.log('found ui:', ui);

        this.uis.set(pong.uid, ui);
        this.addr2uid.set(ui.addr, ui.uid);

        if (ui.uid === this.upgradeUid && this.upgradeConfig) {
          console.log('updating to previous config', ui);
          ui.setConfig(this.upgradeConfig);
          this.upgradeConfig = null;
          this.upgradeUid = null;
        }

        if (this.firstUi === 0) {
          this.firstUi = ui.uid;
        }
      }

      const ui = this.uis.get(pong.uid);

      if (ui) {
        ui.setAddr();
        ui.getConfig();
      }

      this.updateState();
    } else {
      const addr = getSysexAddr(message.data);

      const uid = this.addr2uid.get(addr);

      if (!uid) {
        return;
      }

      const ui = this.uis.get(uid);

      if (!ui) {
        throw Error('invalid uid ' + uid);
      }

      if (ui.sysex(p, message.data)) {
        this.updateState();
      }
    }
  }

  setCallbacks(updateState: () => void) {
    this.updateState = updateState;
  }

  getUis(): UiDevice[] {
    var uis: UiDevice[] = [];

    this.uis.forEach((value) => {
      uis.push(value);
    });

    return uis;
  }

  get(key: number) {
    return this.uis.get(key);
  }

  has(key: number) {
    return this.uis.get(key);
  }

  hasAny() {
    return this.uis.size !== 0;
  }

  blink(uid: number | undefined) {
    this.blinkUi = uid ? this.uis.get(uid) : undefined;
  }

  sense() {
    setTimeout(() => {
      var scan = false;
      this.uis.forEach((ui, uid) => {
        const age = Date.now() - ui.aliveTime;

        ui.blink(this.blinkUi?.uid === uid);
        //console.log('blink', age, this.uis.size, ui);

        if (age > 5000 && !ui.needsUpgrade(0)) {
          console.log('keepalive fail:', age, this.uis.size, ui.uid, ui);
          scan = true;
        }
      });

      if (scan) {
        this.scan();
      }

      this.sense();
    }, 1000);
  }

  async upgradeFirmware(
    ui: UiDevice,
    fw: Firmware,
    setComplete: (b: number) => void,
    setStatusText: (s: string) => void
  ) {
    nedfu.init(
      (step: string, done: number, total: number) => {
        if (step === 'erase') {
          setComplete((40 * done) / total);
        } else if (step === 'write') {
          setComplete((40 * done) / total + 40);
        } else {
          console.log('unknown step:', step);
        }
      },
      (msg: string) => {},
      () => {},
      () => {}
    );

    setComplete(0);
    setStatusText('Rebooting Device');

    this.upgradeUid = ui.uid;
    this.upgradeConfig = ui.config;

    ui.dfu();

    setComplete(10);
    setStatusText('Waiting on reboot');

    this.clearState();
    this.updateState();

    await sleep(500);

    const pm = nedfu.connect();

    setComplete(10);
    setStatusText('Waiting on reboot');
    await sleep(1000);

    setComplete(20);
    setStatusText('Connecting');
    await pm;

    setComplete(30);
    setStatusText('Fetching firmware');
    const r = await fw.getBin();

    if (!r) {
      throw Error('no firmware!');
    }

    setComplete(40);
    setStatusText('Flashing firmware');
    await nedfu.flash(r);

    setComplete(80);
    setStatusText('Rebooting');
    await sleep(1000);

    setComplete(90);
    setStatusText('Connecting');

    if (!this.hasAny()) {
      await this.scan();

      for (var i = 0; i < 20; i++) {
        await sleep(1000);

        if (this.hasAny()) {
          break;
        }

        console.log('waiting on midi connect');
      }
    }

    if (!this.hasAny()) {
      setStatusText('Where did you go?');
    } else {
      setComplete(100);
      setStatusText('');
      setComplete(0);
    }
  }
}

const router: UiMidiRouter = new UiMidiRouter();

export default router;
