import Container from '@mui/material/Container';
import SqPaper from './SqPaper';

export default function PagePaper(props: any) {
    return (
        <>
            <Container style={{

                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                flexShrink: 2,

            }}>
                <SqPaper elevation={10}>
                    {props.children}
                </SqPaper>
            </Container>
        </>
    );
}