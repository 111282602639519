import {UI_BEHAVIORS} from '../const';
import {uniq} from './util';

export default class UiBehavior {
  category: string;
  name: string;
  code: number;
  active: boolean;
  align: number;

  hasChannel: boolean;
  hasStart: boolean;
  hasNoteFilter: boolean;
  hasDelay: boolean;
  hasBendScale: boolean;
  hasClockDiv: boolean;
  hasFilterCC: boolean;
  default: any;
  tooltip: string;
  tooltipCategory: string;
  hasCChannel: string;
  usedInChained: boolean;
  hasShape: boolean;
  hasMinLength: boolean;
  hasMaxLength: boolean;
  hasChannelMin: boolean;

  constructor(obj: any) {
    this.name = obj.name;
    this.category = obj.category;
    this.code = obj.code;
    this.active = obj.active;
    this.align = obj.align;
    this.hasChannel = obj.hasChannel;
    this.hasStart = obj.hasStart;
    this.hasNoteFilter = obj.hasNoteFilter;
    this.hasDelay = obj.hasDelay;
    this.hasBendScale = obj.hasBendScale;
    this.hasClockDiv = obj.hasClockDiv;
    this.hasFilterCC = obj.hasFilterCC;
    this.default = obj.default;
    this.tooltip = obj.tooltip;
    this.tooltipCategory = obj.tooltipCategory;
    this.hasCChannel = obj.hasCChannel;
    this.usedInChained = obj.usedInChained;
    this.hasShape = obj.hasShape;
    this.hasMinLength = obj.hasMinLength;
    this.hasMaxLength = obj.hasMaxLength;
    this.hasChannelMin = obj.hasChannelMin;
  }

  is(c: string, n: string) {
    if (!n && this.category === c) return true;
    if (!c && this.name === n) return true;
    if (c === this.category && n === this.name) return true;
    return false;
  }
}

export const BEHAVIORS: UiBehavior[] = UI_BEHAVIORS.filter((b: any) => {
  return b.active;
}).map((obj: any) => {
  return new UiBehavior(obj);
});

export function behGetByNum(n: number): UiBehavior {
  for (const x of BEHAVIORS) {
    if (x.code === n) {
      return x;
    }
  }
  return BEHAVIORS[0];
}

export function behGetFirstInCat(c: string): UiBehavior {
  for (const x of BEHAVIORS) {
    if (x.category === c) {
      return x;
    }
  }
  return BEHAVIORS[0];
}

export function behGetFromCat(c: string): UiBehavior[] {
  return BEHAVIORS.filter((b) => {
    return b.category === c;
  });
}
export function behGetFromCatIncludes(c: string): UiBehavior[] {
  return BEHAVIORS.filter((b) => {
    return b.category.includes(c);
  });
}
export function behGetCatNames(test: (name: UiBehavior) => boolean): string[] {
  return uniq(
    BEHAVIORS.filter(test).map((b) => {
      return b.category;
    })
  );
}
