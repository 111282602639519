import * as React from 'react';

import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyIcon from '@mui/icons-material/Key';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';

import ResponsiveContainer from './ResponsiveContainer';
import FaqAccordion from './FaqAccordion';
import gcf from '../ostium/functions';
import getUserInfo from '../ostium/UserInfo';
import If from './If';
import Installers from './Installers';
import NeedHelp from './NeedHelp';
import PagePaper from './PagePaper';
import SqButton from './SqButton';
import SqTextField from './SqTextField';
import Title from './Title';

import {getPluginUrl} from '../const';
import {TypeOcrH6} from './Types';

function messageFormat(text: string) {
  return (
    <Typography component={'div'} variant="body1" sx={{m: 1}}>
      {text}
    </Typography>
  );
}

function SimpleAccordion() {
  return (
    <>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          maxWidth: '85%',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 2,
        }}
      >
        <FaqAccordion name="What if I can't find the plugins in Live?">
          In Ableton Live, you may need to tell it to look for VST3 plugins and
          rescan. You can do this in the menu under
          Options/Preferences/Plug-Ins.
        </FaqAccordion>
        <FaqAccordion name="Where do the plugins and presets install?">
          <Typography>
            {' '}
            Our installer installs the plugins to the following locations
            specified by the formats. The format standards specify where to put
            files, and in turn, where DAWs should look for said files. You
            should not need to move them, but if you do, the installer will not
            be able to update them in a new location. Note that AAX and AU files
            may not be moved.
            <br />
            <br />
          </Typography>
          <Typography style={{fontSize: 14, fontFamily: 'OCR A Std'}}>
            Mac: AAX: /Library/Application Support/Avid/Audio/Plug-Ins/Noise
            Engineering/Product Name.aaxplugin <br />
            AU2: Library/Audio/Plug-Ins/Components/ <br />
            VST3: /Library/Audio/Plug-Ins/VST3/
            <br />
            <br />
            PC: AAX: C:/Program Files/Common Files/Avid/Audio/Plug-Ins/Noise
            Engineering/ <br />
            VST3: C:/Program Files/Common Files/VST3/Noise Engineering/
            <br />
            <br />
          </Typography>
          Presets save to the following locations:
          <br />
          <br />
          <Typography style={{fontSize: 14, fontFamily: 'OCR A Std'}}>
            Mac: /Users/[name]/Library/Audio/Presets/Noise Engineering/[Product
            Name]
            <br />
            <br />
            PC: C:/Users/Public/Documents/Noise Engineering/[Product Name]
          </Typography>
        </FaqAccordion>
        <FaqAccordion name="What if the installer can't connect?">
          If the installer is having an issue connecting, please turn off pop-up
          blockers and firewalls. These can block your internet traffic, which
          prevent the installer from reaching our database and registering your
          products. If you have tried that and still can't connect, please get
          in touch at the link at the top of the page. Attach a screenshot of
          the developer console if you can: this can help us debug problems
          quickly.
        </FaqAccordion>
      </Container>
    </>
  );
}

interface PluginProps {}

function Redeem(props: any) {
  const [message, setMessage] = React.useState('');
  const [active, setActive] = React.useState(true);
  const [error, setError] = React.useState(false);

  async function onRedeem() {
    setActive(false);

    try {
      setMessage('Redeeming...');
      var k = props.getKeyValue().trim();

      if (k.length > 15) {
        var res: any = await gcf.redeem(props.getKeyValue());
        await getUserInfo().refresh();
        setMessage(res.message);
        setError(res.status !== 'success');
        props.setKey('');
        console.log(getUserInfo().plugins);
      } else {
        setMessage('Invalid key, too short');
        setError(true);
      }
    } finally {
      setActive(true);
    }
  }

  return (
    <>
      <ResponsiveContainer>
        <SqTextField
          margin="dense"
          id="key"
          name="key"
          label="Enter Product Key"
          type="text"
          variant="outlined"
          onChange={(e: any) => props.setKey(e.target.value)}
          value={props.getKeyValue()}
          sx={{mx: 2, my: 2, mt: 2}}
          style={{minWidth: 400}}
          onKeyUp={(e: any) => {
            if (e.keyCode === 13) {
              onRedeem();
            }
          }}
        />
        <br />
        <SqButton
          style={{minWidth: 200}}
          sx={{mx: 1, mt: 0, mb: 1}}
          onClick={() => onRedeem()}
          disabled={!active}
        >
          Redeem
        </SqButton>

        {messageFormat(message)}
        <If value={error}>
          <NeedHelp />
        </If>
      </ResponsiveContainer>
    </>
  );
}

export default function TabPlugin(props: PluginProps) {
  //return ();

  const [keyValue, setKey] = React.useState('');

  const pluginNamesDict: any = {};
  for (const p of getUserInfo().plugins) {
    pluginNamesDict[p.name] = p.name;
  }
  const pluginNames: string[] = Object.keys(pluginNamesDict);
  pluginNames.sort();
  //const pluginList = pluginNames.join(", ");

  const pluginNamesL: string[] = pluginNames.filter((a: string) => {
    return pluginNames.indexOf(a) % 2 === 0;
  });
  const pluginNamesR: string[] = pluginNames.filter((a: string) => {
    return pluginNames.indexOf(a) % 2 !== 0;
  });

  <TypeOcrH6>('Your Plugins')</TypeOcrH6>;
  return (
    <>
      <Title>Plugins</Title>
      <PagePaper>
        <Stack justifyContent="center" alignItems="center">
          <Stack direction="row" sx={{mt: 3}}>
            <Installers></Installers>
          </Stack>

          <hr />
          <Stack sx={{mb: 3}} justifyContent="center" alignItems="center">
            <Stack direction="row" sx={{mt: 2}}>
              <KeyIcon sx={{mt: 0.5}} />
              <TypeOcrH6>{'Redeem Key'}</TypeOcrH6>
              <KeyIcon sx={{mt: 0.5}} />
            </Stack>
            <Redeem
              getKeyValue={() => {
                return keyValue;
              }}
              setKey={setKey}
            ></Redeem>
          </Stack>
          <hr />

          <Stack
            sx={{mt: 3, mb: 0}}
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row">
              <QueueMusicIcon sx={{mt: 0.5}} />
              <TypeOcrH6>{'Your Plugins'}</TypeOcrH6>
              <QueueMusicIcon sx={{mt: 0.5}} />
            </Stack>
          </Stack>

          <p>Click links for product manuals</p>

          <Stack direction="row" justifyContent="center" alignItems="center">
            <Stack
              sx={{mx: 4}}
              direction="column"
              justifyContent="center"
              alignItems="center"
              display="inline-block"
            >
              {pluginNamesL.map((plug: any) => {
                return (
                  <Box key={plug.toString()}>
                    {' '}
                    <Link
                      href={getPluginUrl(plug)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {plug}
                    </Link>
                  </Box>
                );
              })}
            </Stack>

            <Stack
              sx={{mx: 4}}
              justifyContent="center"
              alignItems="center"
              display="inline-block"
            >
              {pluginNamesR.map((plug: any) => {
                return (
                  <Box key={plug.toString()}>
                    {' '}
                    <Link
                      href={getPluginUrl(plug)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {plug}
                    </Link>
                  </Box>
                );
              })}
            </Stack>
          </Stack>

          <SqButton
            sx={{my: 4}}
            onClick={() => {
              window.open(
                'https://www.noiseengineering.us/collections/software',
                '_blank'
              );
            }}
          >
            See all NE Plugins
          </SqButton>
          <Box sx={{mt: 1}}></Box>

          <Grid
            container
            style={{justifyContent: 'center', alignItems: 'center'}}
            columns={{xs: 3, sm: 6, md: 12}}
            sx={{mb: 2}}
          >
            <Grid xs={3}>
              <ResponsiveContainer maxWidth="85%">
                <Typography component="div" sx={{m: 3, mr: 5}} align="center">
                  <TypeOcrH6 align="center"> Troubleshoot</TypeOcrH6>
                  You have questions? We have answers. Still not sorted? Get in
                  touch with the button at the top of the page.
                </Typography>
              </ResponsiveContainer>
            </Grid>
            <Grid xs={9}>
              <SimpleAccordion></SimpleAccordion>
            </Grid>
          </Grid>
        </Stack>
      </PagePaper>
    </>
  );
}
