import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import SqTextField from './SqTextField';
import UiLayoutConst from './UiLayoutConst';
import ControlledTooltip from './ControlledTooltip';
import {CLOCKDIVTOOL} from '../const';

const lay = new UiLayoutConst();

interface UiClockDivProps {
  bend: number;
  setBend: (s: number) => void;
  tooltipEnabled: boolean;
}

function UiClockDivItem(ms: number, div: string) {
  return (
    <MenuItem key={ms + 'bend'} value={ms}>
      {div}
    </MenuItem>
  );
}

function UiClockDiv(props: UiClockDivProps) {
  //format: 0bxxyyzzzz -> 5^x * 3^y * 2*z
  return (
    <ControlledTooltip
      title={CLOCKDIVTOOL}
      placement="bottom"
      arrow={true}
      tooltipEnabled={props.tooltipEnabled}
    >
      <SqTextField
        select
        label={'Clock Div'}
        size="small"
        onChange={(e: any) => {
          props.setBend(e.target.value);
        }}
        value={props.bend}
        sx={{mx: lay.mx, my: lay.my, width: 6 * 17}}
      >
        {UiClockDivItem(0x00, '24ppqn')}
        {UiClockDivItem(0x01, '12ppqn')}
        {UiClockDivItem(0x10, '1/32')}
        {UiClockDivItem(0x11, '1/16')}
        {UiClockDivItem(0x03, '1/8T')}
        {UiClockDivItem(0x20, '1/16D')}
        {UiClockDivItem(0x12, '1/8')}
        {UiClockDivItem(0x04, '1/4T')}
        {UiClockDivItem(0x21, '1/8D')}
        {UiClockDivItem(0x13, '1/4')}
        {UiClockDivItem(0x05, '1/2T')}
        {UiClockDivItem(0x22, '1/4D')}
        {UiClockDivItem(0x14, '1/2')}
        {UiClockDivItem(0x06, '1/1T')}
        {UiClockDivItem(0x23, '1/2D')}
        {UiClockDivItem(0x15, '1/1')}
        {UiClockDivItem(0x16, '2/1')}
        {UiClockDivItem(0x25, '3/1')}
        {UiClockDivItem(0x17, '4/1')}
        {UiClockDivItem(0x18, '8/1')}
      </SqTextField>
    </ControlledTooltip>
  );
}

export default UiClockDiv;
