import React from 'react';
import TextField from '@mui/material/TextField';
import {TextFieldProps} from '@mui/material/TextField';
import {PropsWithChildren} from 'react';

const SqTextField = React.forwardRef(
  (p: PropsWithChildren<TextFieldProps>, ref) => {
    var propsOld: any = p;
    var props: any = {};

    Object.keys(propsOld).forEach((k) => {
      props[k] = propsOld[k];
    });

    if (props.style) {
      props.style = {};
      Object.keys(propsOld.style).forEach((k) => {
        props.style[k] = propsOld.style[k];
      });
    } else {
      props.style = {};
    }

    const sp: any = {};

    if (propsOld.height) {
      sp.height = propsOld.height;
      props.height = undefined;
    }

    if (propsOld.width) {
      sp.width = propsOld.width;
      props.width = undefined;
    }

    props.SelectProps = {style: sp};
    /*
    if (!props.sx) {
      props.sx = {color: '#FF0000'};
    } else {
      props.sx['color'] = '#DD0000';
    }
    */

    if (propsOld.SelectProps) {
      Object.keys(propsOld.SelectProps).forEach((k) => {
        props.SelectProps[k] = propsOld.SelectProps[k];
      });
      //props.SelectProps.sx = {color: '#FF0000'};
    }

    props.size = 'small';
    //console.log(props);

    // props.InputProps = { classes: { input: { fontSize: 100 } } }
    // props.InputProps = { style: { fontSize: 40 } }
    // props.InputLabelProps = { style: { fontSize: 40 } }

    //overrides
    props.style.borderRadius = 0;
    //props.style.color = '#FF0000';

    return (
      <div className="SqTextField">
        <TextField {...props} ref={ref}>
          {props.children}
        </TextField>
      </div>
    );
  }
);

export default SqTextField;
