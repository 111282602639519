import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {TypeOcrB2} from './Types';

interface FooterProps {
  setTanc: () => void;
  setEula: () => void;
  setPriv: () => void;
  setCook: () => void;
  setPage: (s: string) => void;
}

export default function Footer(props: FooterProps) {
  return (
    <>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
        sx={{mt: 3}}
      >
        <Grid
          container
          spacing={2}
          columns={{xs: 3, sm: 6, md: 12}}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Button
              sx={{m: 1}}
              variant="text"
              size="small"
              color="primary"
              className="float-right"
              onClick={() => props.setTanc()}
            >
              <TypeOcrB2>Terms & Conditions</TypeOcrB2>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              sx={{m: 1}}
              variant="text"
              size="medium"
              color="primary"
              className="float-right"
              onClick={() => props.setEula()}
            >
              <TypeOcrB2>EULA</TypeOcrB2>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              sx={{m: 1}}
              variant="text"
              size="medium"
              color="primary"
              className="float-right"
              onClick={() => props.setPriv()}
            >
              <TypeOcrB2>Privacy Policy</TypeOcrB2>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              sx={{m: 1}}
              variant="text"
              size="medium"
              color="primary"
              className="float-right"
              onClick={() => props.setCook()}
            >
              <TypeOcrB2>Cookies Policy</TypeOcrB2>
            </Button>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="center">
          <IconButton
            aria-label="instagram.com"
            onClick={() => window.open('https://www.instagram.com/noiseeng')}
          >
            <InstagramIcon fontSize="large" />
          </IconButton>

          <IconButton
            aria-label="youtube.com"
            onClick={() =>
              window.open('https://www.youtube.com/noiseengineering')
            }
          >
            <YouTubeIcon fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="twitter.com"
            onClick={() => window.open('https://twitter.com/noiseeng')}
          >
            <TwitterIcon fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="facebook.com"
            onClick={() => window.open('https://www.facebook.com/noiseeng')}
          >
            <FacebookIcon fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="instagram.com"
            onClick={() =>
              window.open('https://www.instagram.com/noiseengplugins/')
            }
          >
            <InstagramIcon fontSize="large" />
          </IconButton>
        </Stack>
      </Container>
      <Typography>Powered by Google Analytics</Typography>{' '}
    </>
  );
}
