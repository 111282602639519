

import { PUBLIC_RELEASE, PLATFORM } from '../const';

export default class Platform {
    code: string;
    name: string;
    image: string;
    helpImage: string;
    desc: string;
    shopUrl: string;
    shortDesc: string;

    constructor(
        code: string,
        name: string,
        image: string,
        helpImage: string,
        desc: string,
        shopUrl: string,
        shortDesc: string,
    ) {
        this.code = code;
        this.name = name;
        this.image = image;
        this.helpImage = helpImage;
        this.desc = desc;
        this.shopUrl = shopUrl;
        this.shortDesc = shortDesc;
    }

    toString() { return `platform ${this.code} ${this.name}`; }

    static getName(format: string) {
        if (PLATFORM[format]) {
            return PLATFORM[format].name;
        }
        return format;
    }

    static getDescription(format: string) {
        if (PLATFORM[format]) {
            return PLATFORM[format].desc;
        }
        return format;
    }

    static getShopUrl(format: string) {
        if (PLATFORM[format]) {
            return PLATFORM[format].shopUrl;
        }
        return format;
    }

    static getShortDesc(format: string) {
        if (PLATFORM[format]) {
            return PLATFORM[format].shortDesc;
        }
        return format;
    }
    static getFromFirmwares(firmwares: any): Platform[] {
        let idx: any = {};
        let plats: Platform[] = [];

        for (let x of firmwares) {
            if (x.os === "lacerti" && !idx[x.format]) {
                idx[x.format] = x.format;
                const name = Platform.getName(x.format);
                const desc = Platform.getDescription(x.format);
                const shopUrl = Platform.getShopUrl(x.format);
                const shortDesc = Platform.getShortDesc(x.format);
                plats.push(
                    new Platform(
                        x.format,
                        name,
                        "panels/" + name.toLowerCase() + ".png",
                        "panels/help_" + name.toLowerCase() + ".png",
                        desc,
                        shopUrl,
                        shortDesc,
                    ));
            }
        }

        return plats;
    }
    static getPublic(): Platform[] {
        return (Platform.getFromFirmwares(PUBLIC_RELEASE))
    }
};
