import SqTextField from './SqTextField';
import UiLayoutConst from './UiLayoutConst';

import ControlledTooltip from './ControlledTooltip';
import {CCTOOL} from '../const';

interface UiFilterCCProps {
  filter: number;
  setFilter: (n: number) => void;
  tooltipEnabled: boolean;
}

function UiFilterCC(props: UiFilterCCProps) {
  const lay = new UiLayoutConst();

  return (
    <ControlledTooltip
      title={CCTOOL}
      placement="top"
      arrow={true}
      tooltipEnabled={props.tooltipEnabled}
    >
      <SqTextField
        autoFocus
        type="number"
        label="CC#"
        size="small"
        onChange={(e: any) => {
          var value = e.target.value === '' ? -1 : parseInt(e.target.value);

          if (value >= 120) value = 119;
          if (value <= -1) value = 0;
          if (value !== props.filter) {
            props.setFilter(value);
            console.log(value, props.filter);
          }
        }}
        sx={{mx: lay.mx, my: lay.my, width: 6 * 17}}
        value={props.filter === -1 ? '' : props.filter}
      ></SqTextField>
    </ControlledTooltip>
  );
}

export default UiFilterCC;
