import MenuItem from '@mui/material/MenuItem';
import SqTextField from './SqTextField';
import UiLayoutConst from './UiLayoutConst';

import {STARTOCTTOOL} from '../const';

import ControlledTooltip from './ControlledTooltip';

function UiOctaveSelectItem(ms: number) {
  return (
    <MenuItem key={ms + 'oct'} value={ms}>
      {ms - 2}
    </MenuItem>
  );
}
interface UiOctaveSelectProps {
  allowAny: boolean;
  suffix: string;
  octave: number;
  setOctave: (s: number) => void;
  tooltipEnabled: boolean;
}
function UiOctaveSelect(props: UiOctaveSelectProps) {
  const lay = new UiLayoutConst();

  return (
    <>
      <ControlledTooltip
        title={STARTOCTTOOL}
        placement="top"
        arrow={true}
        tooltipEnabled={props.tooltipEnabled}
      >
        <SqTextField
          select
          label={props.suffix + ' Oct'}
          size="small"
          onChange={(e: any) => {
            props.setOctave(e.target.value);
          }}
          value={props.octave}
          sx={{mx: lay.mx, my: lay.my, width: 6 * 13}}
        >
          {UiOctaveSelectItem(0)}
          {UiOctaveSelectItem(1)}
          {UiOctaveSelectItem(2)}
          {UiOctaveSelectItem(3)}
          {UiOctaveSelectItem(4)}
          {UiOctaveSelectItem(5)}
          {UiOctaveSelectItem(6)}
          {UiOctaveSelectItem(7)}
          {UiOctaveSelectItem(8)}
          {UiOctaveSelectItem(9)}
          {props.allowAny && (
            <MenuItem key={'any oct'} value={255}>
              Any
            </MenuItem>
          )}
        </SqTextField>
      </ControlledTooltip>
    </>
  );
}

export default UiOctaveSelect;
