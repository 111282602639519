
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import AppleIcon from '@mui/icons-material/Apple';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import getUserInfo from "../ostium/UserInfo";
import { ReactComponent as Microsoft } from "./microsoft.svg";
import { saveUrl } from '../ostium/util';


function headerSmall(text: string) {
    return (<Typography noWrap component={'div'} variant="h6" className="ne-font" sx={{ m: 1 }}>{text}</Typography>);
}

function ocrh6(text: string) {
    return (<Typography component={'div'} variant="h6" className="ne-font">{text}</Typography>);
}



export default function Installers() {



    const pluginNamesDict: any = {};
    for (const p of getUserInfo().plugins) {
        pluginNamesDict[p.name] = p.name;
    }
    const pluginNames: string[] = Object.keys(pluginNamesDict);
    pluginNames.sort();
    //const pluginList = pluginNames.join(", ");


    const installers = getUserInfo().installers;
    installers.sort();

    const installersMac = installers.filter((inst) => { return inst.os === 'osx' });
    const installersWin = installers.filter((inst) => { return inst.os === 'w64' });
    return (
        <Stack justifyContent="center"   >
            <Stack direction="row" sx={{ mt: 0 }} justifyContent="center">
                <DownloadIcon sx={{ mt: 1.4 }}></DownloadIcon>{headerSmall("Get Installer")} <DownloadIcon sx={{ mt: 1.4 }}></DownloadIcon>
                </Stack><br></br>


            <Stack direction="row" sx={{ mt: 2 }} justifyContent="center"><AppleIcon sx={{ mx: 0.5 }}>Mac</AppleIcon>{ocrh6("Mac")}</Stack><br></br>
            {installersMac.map((inst: any) => {
                return (
                    <Box sx={{ m: 1 }} key={inst.toString()}>
                        <Link onClick={async () => { saveUrl(inst.url, inst.filename); }} component="button">
                            {inst.toString()}
                        </Link>
                        <br />
                    </Box>
                );
            })}
            <Stack direction="row" sx={{ mt: 2 }} justifyContent="center">
                <SvgIcon sx={{ mx: 0.5 }} ><Microsoft /></SvgIcon>{ocrh6(" Windows")}</Stack><br></br>
            {installersWin.map((inst: any) => {
                return (
                    <Box sx={{ m: 1 }} key={inst.toString()}>
                        <Link onClick={async () => { saveUrl(inst.url, inst.filename); }} component="button">
                            {inst.toString()}
                        </Link>
                        <br />
                    </Box>
                );
            })}


        </Stack>

    );
}