
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography';

interface TypeProps extends TypographyProps<"div", {}> { }

export function TypeOcrB1(props: TypeProps) {
    return (<Typography component={ 'div' } variant="body1" className="ne-font" { ...props }>{ props.children }</Typography>);
}

export function TypeOcrB2(props: TypeProps) {
    return (<Typography component={ 'div' } variant="body2" className="ne-font" { ...props }>{ props.children }</Typography>);
}

export function TypeOcrH6(props: TypeProps) {
    return (<Typography component={ 'div' } variant="h6" className="ne-font" { ...props }>{ props.children }</Typography>);
}

export function TypeOcrH5(props: TypeProps) {
    return (<Typography component={ 'div' } variant="h5" className="ne-font" { ...props }>{ props.children }</Typography>);
}

export function TypeOcrH4(props: TypeProps) {
    return (<Typography component={ 'div' } variant="h4" className="ne-font" { ...props }>{ props.children }</Typography>);
}

export function TypeOcr(props: TypeProps) {
    return (<Typography component={ 'div' } className="ne-font" { ...props }>{ props.children }</Typography>);
}

export function TypeSubTitle(props: TypeProps) {
    return (
        <Typography component={ 'div' } variant="h4" className="ne-font" sx={ { mx: 0, my: 0 } } align='center' { ...props }>
            { props.children }
        </Typography>
    );
}