export default class UiLayoutConst {
  mx: number;
  my: number;
  sw: number;

  constructor() {
    this.mx = 0.5;
    this.my = 0.5;
    this.sw = 90;
  }
}
