import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
//import useMediaQuery from '@mui/material/useMediaQuery';
//import {useTheme} from '@mui/material/styles';

import ControlledTooltip from './ControlledTooltip';
import UiPresets from './UiPresets';
import SqButton from './SqButton';
import SqPaper from './SqPaper';
import If from './If';
import UiOutputEdit from './UiOutputEdit';
import SqTextField from './SqTextField';
import {TypeOcrH6, TypeOcrH5} from './Types';

import {behGetByNum} from '../ostium/UiBehavior';
import Firmware from '../ostium/Firmware';
import router from '../ostium/UiMidiRouter';
import UiConfig from '../ostium/UiConfig';
import UiOutput from '../ostium/UiOutput';
import UiDevice from '../ostium/UiDevice';
import getUserInfo from '../ostium/UserInfo';
import UiPolyBendAmount from './UiPolyBendAmount';

import {
  LOADDEVTOOL,
  LOADFILETOOL,
  SAVEDEVTOOL,
  SAVESYXTOOL,
  SAVEJSONTOOL,
  FINDTOOL,
  CHAINEDTOOL,
  USBMIDITOOL,
  MIDITHRUTOOL,
  TRIGLENTOOL,
  CLOCKLENTOOL,
  CHAINCHANTOOL,
  VOICECOUNTTOOL,
  TOOLTIPTOOL,
} from '../const';

function SelectItems(hasAny: boolean) {
  if (hasAny) {
    return router.map((ui: UiDevice) => {
      return (
        <MenuItem key={'ui' + ui.uid} value={ui.uid}>
          {ui.toString()}
        </MenuItem>
      );
    });
  } else {
    return (
      <MenuItem key="ui select none" value={0}>
        No UI Connected
      </MenuItem>
    );
  }
}

/*<If value={isShowImg}>
              <Image alt="UI" src="panels/ui.png" width={imageWidth} />
            </If>*/

interface UiConfigEditProps {}

export default function UiConfigEdit(props: UiConfigEditProps) {
  const [sequence, setSequence] = React.useState(0);
  const [selectedUi, setSelectedUi] = React.useState(0);
  const [chained, setChained] = React.useState(false);
  const [triggerMs, setTriggerMs] = React.useState(3);
  const [clockMs, setClockMs] = React.useState(3);
  const [usb2midi, setUsb2midi] = React.useState(true);
  const [chChannel, setChChannel] = React.useState(15);
  const [midiThru, setMidiThru] = React.useState(false);
  const [editName, setEditName] = React.useState('Ui is cool');
  const [mcpTestText, setMcpTestText] = React.useState('');
  const [midiTestText, setMidiTestText] = React.useState('');
  const [statusText, setStatusText] = React.useState('');
  const [complete, setComplete] = React.useState(0);
  const [tooltips, setTooltips] = React.useState(false);

  const [bendScale, setBendScale] = React.useState(48);

  const hasAny = router.hasAny();

  var notPoly = true;

  const outputs: any = [];
  const setOutputs: any = [];
  for (var i = 0; i < 16; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [output, setOutput] = React.useState(new UiOutput());
    outputs.push(output);
    setOutputs.push(setOutput);
    if (behGetByNum(output.behavior).category.includes('MPE')) {
    }
    if (
      behGetByNum(output.behavior).category.includes('MPE') ||
      behGetByNum(output.behavior).category.includes('Poly')
    ) {
      notPoly = false;
    }
  }

  const config = UiConfig.fromObj({
    name: editName,
    outputs: outputs,
    clock_ms: clockMs,
    trig_ms: triggerMs,
    chained: chained,
    usb2midi: usb2midi,
    midiThru: midiThru,
    chChannel: chChannel,
    mpeCount: 0,
    bendScale: bendScale,
  });

  var mpeCount = config.getMpeCount();

  const setConfig = (c: UiConfig | null) => {
    if (c !== null) {
      setChained(c.isChained());
      setEditName(c.name);
      setMidiThru(c.isMidiThru());
      setUsb2midi(c.isUsbMidi());
      setTriggerMs(c.trig_ms);
      setClockMs(c.clock_ms);
      setChChannel(c.chChannel);
      setBendScale(c.bendScale);

      for (let i = 0; i < 16; i++) {
        setOutputs[i](c.outputs[i]);
      }
    }
  };
  const getOut = (x: number) => {
    return outputs[x];
  };
  const getSetOut = (x: number) => {
    return setOutputs[x];
  };

  const getUi = () => {
    const ui = router.get(selectedUi);

    if (ui == null) {
      console.log('invalid ui, rescanning');
      router.scan();
      throw Error('missing ui');
    }

    return ui;
  };

  const loadArray = (array: Uint8Array) => {
    setConfig(UiConfig.fromSysex(array));
  };
  const loadJson = (array: Uint8Array) => {
    var str = new TextDecoder().decode(array);
    var json = JSON.parse(str);
    setConfig(UiConfig.fromObj(json));
  };
  const saveDevice = () => {
    getUi().setConfig(config);
    updateState();
  };
  const loadDevice = () => {
    setConfig(getUi().config);
  };
  const saveFileSyx = () => {
    config.saveAsSyx();
  };
  const saveFileJson = () => {
    config.saveAsJson();
  };
  const loadFile = () => {
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = '.syx,.json';
    input.onchange = (e: any) => {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (readerEvent: any) => {
        if (file.name.endsWith('syx')) {
          loadArray(new Uint8Array(readerEvent.target.result));
        } else {
          loadJson(new Uint8Array(readerEvent.target.result));
        }
      };
    };
    input.click();
  };
  const getVoiceNumber = (index: number, behavior: number) => {
    if (
      !behGetByNum(behavior).category.includes('MPE') &&
      !behGetByNum(behavior).category.includes('Poly')
    ) {
      return 0;
    }
    var count = 0;
    for (var i = 0; i < 16; i++) {
      if (outputs[i].behavior === behavior) {
        count = count + 1;
      }
      if (i === index) break;
    }
    return count;
  };
  const uiOutEdit = (x: number, out: UiOutput, set: (o: UiOutput) => void) => {
    return (
      <UiOutputEdit
        index={x + 1}
        behavior={out.behavior}
        channel={out.channel}
        filter={out.filter}
        delay={out.delay}
        scale={out.scale}
        offset={out.offset}
        smooth={out.smooth}
        volume={out.volume}
        setOutput={set}
        tooltipEnabled={tooltips}
        disabled={selectedUi === 0 || disabled}
        voiceNumber={getVoiceNumber(x, out.behavior)}
      />
    );
  };
  const uiOutGrid = (x: number, out: UiOutput, set: (o: UiOutput) => void) => {
    return (
      <Grid item xs={6} style={{display: 'flex', justifyContent: 'center'}}>
        {uiOutEdit(x, out, set)}
      </Grid>
    );
  };

  const uiFws = getUserInfo().firmwares.filter((fw: Firmware) => {
    return fw.code === 'ui' && fw.release === 'public';
  });

  const uiFwVer = uiFws.length > 0 ? parseInt(uiFws[0].version) : 0;
  const hasUi = router.has(selectedUi);
  const firmwareMatch = router.get(selectedUi)?.firmware === +uiFwVer;

  const needsUpgrade = router.has(selectedUi)
    ? getUi().needsUpgrade(uiFwVer)
    : false;

  const updateState = () => {
    if (needsUpgrade && hasUi) {
      setStatusText('Firmware out of date ');
    }
    if (router.hasAny() && selectedUi === 0) {
      setSelectedUi(router.firstUi);
    } else if (!router.hasAny() && selectedUi !== 0) {
      setSelectedUi(0);
    } else if (router.hasAny()) {
      const ui = getUi();
      if (ui?.saveConfirm) {
        ui.saveConfirm = false;
        setStatusText('Successful save to UI');
      }
    }

    setSequence(sequence + 1);
  };

  router.setCallbacks(() => {
    updateState();
  });

  router.blink(selectedUi);

  const mx = 0.5;
  const my = 0.5;

  const TimeItem = (ms: number) => {
    return (
      <MenuItem key={ms + 'delay'} value={ms}>
        {ms + ' ms'}
      </MenuItem>
    );
  };

  const width = '80%';
  const width2 = '40%';
  const disabled = complete !== 0 || needsUpgrade;

  const getUpdateMessage = () => {
    if (needsUpgrade) {
      return 'Update Firmware';
    }
    if (selectedUi !== 0) {
      return 'Up To Date';
    } else {
      return 'No Ui Found';
    }
  };

  const channels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const channelItems = () => {
    return channels.map((value: any) => {
      return (
        <MenuItem key={'key' + value} value={value}>
          {value + 1}
        </MenuItem>
      );
    });
  };

  const ChainChannel = () => {
    return (
      <ControlledTooltip
        title={CHAINCHANTOOL}
        placement="top"
        arrow={true}
        tooltipEnabled={tooltips}
      >
        <span>
          <SqTextField
            select
            label="Chain Channel"
            onChange={(e: any) => {
              setChChannel(e.target.value);
            }}
            value={chChannel}
            sx={{ml: 0, mr: 0.5, mt: 2}}
            style={{width: '8rem'}}
            disabled={!chained}
          >
            {channelItems()}
          </SqTextField>
        </span>
      </ControlledTooltip>
    );
  };

  const VCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const VCountItems = () => {
    return VCount.map((value: any) => {
      return (
        <MenuItem key={'key' + value} value={value}>
          {value}
        </MenuItem>
      );
    });
  };

  const VoiceCount = () => {
    return (
      <ControlledTooltip
        title={VOICECOUNTTOOL}
        placement="top"
        arrow={true}
        tooltipEnabled={tooltips}
      >
        <span>
          <SqTextField
            label="Last MPE Channel"
            value={mpeCount + 1}
            sx={{mx: 2, mt: 2}}
            style={{width: '8.5rem'}}
            disabled={true}
            variant="standard"
          >
            {VCountItems()}
          </SqTextField>
        </span>
      </ControlledTooltip>
    );
  };

  return (
    <>
      <UiPresets loadPreset={loadArray} />

      <SqPaper variant="outlined" sx={{m: 2, mt: 1}} component="fieldset">
        <legend>
          <TypeOcrH5>Preset Editor</TypeOcrH5>
        </legend>
        <SqPaper
          variant="outlined"
          sx={{m: 0, mt: -1, mb: 0}}
          component="fieldset"
        >
          <legend>
            <TypeOcrH6>Device</TypeOcrH6>
          </legend>
          <Grid
            container
            spacing={0}
            columns={{xs: 3, sm: 6, md: 12}}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={9}>
              <Box
                sx={{display: 'flex', height: 30}}
                justifyContent="center"
                alignItems="flex-end"
              >
                <LinearProgress
                  variant="determinate"
                  value={complete}
                  sx={{
                    display: complete !== 0 ? 'all' : 'none',
                    width: '93%',
                    mt: 0,
                    mb: 2,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{display: 'flex', height: 20}}
                justifyContent="center"
                alignItems="flex-start"
              >
                {statusText}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <SqTextField
                select
                label="Select Device"
                size="small"
                sx={{mx: mx, my: my, width: width}}
                value={selectedUi}
                disabled={disabled && !needsUpgrade}
                onChange={(e: any) => {
                  setSelectedUi(e.target.value as number);
                  updateState();
                }}
              >
                {SelectItems(hasAny)}
              </SqTextField>
            </Grid>
            <Grid item xs={3}>
              <SqTextField
                label="Preset Name"
                size="small"
                sx={{mx: mx, my: my, width: width}}
                onChange={(e: any) =>
                  setEditName(e.target.value.substring(0, 16))
                }
                value={editName}
                disabled={disabled}
              >
                Preset Name
              </SqTextField>
            </Grid>
            <Grid item xs={3}>
              <ControlledTooltip
                title={FINDTOOL}
                placement="top"
                arrow={true}
                tooltipEnabled={tooltips}
              >
                <SqButton
                  size="small"
                  sx={{mx: mx, my: my, width: width}}
                  onClick={async () => {
                    try {
                      await router.scan();
                    } catch (e: any) {
                      setStatusText(e.message);
                    }
                  }}
                  disabled={disabled}
                >
                  Find Connected
                </SqButton>
              </ControlledTooltip>
            </Grid>
            <Grid item xs={3}>
              <SqButton
                size="small"
                sx={{mx: mx, my: my, width: width}}
                onClick={() =>
                  router.upgradeFirmware(
                    getUi(),
                    uiFws[0],
                    setComplete,
                    setStatusText
                  )
                }
                disabled={!needsUpgrade}
              >
                {getUpdateMessage()}
              </SqButton>
            </Grid>

            <Grid item xs={3}>
              <ControlledTooltip
                title={LOADDEVTOOL}
                placement="bottom"
                arrow={true}
                tooltipEnabled={tooltips}
              >
                <SqButton
                  size="small"
                  sx={{mx: mx, my: my, width: width}}
                  onClick={() => loadDevice()}
                  disabled={selectedUi === 0 || disabled}
                >
                  Load From Device
                </SqButton>
              </ControlledTooltip>
            </Grid>
            <Grid item xs={3}>
              <ControlledTooltip
                title={SAVEDEVTOOL}
                placement="bottom"
                arrow={true}
                tooltipEnabled={tooltips}
              >
                <SqButton
                  size="small"
                  sx={{mx: mx, my: my, width: width}}
                  onClick={() => saveDevice()}
                  disabled={selectedUi === 0 || disabled}
                >
                  Save To Device
                </SqButton>
              </ControlledTooltip>
            </Grid>
            <Grid item xs={3}>
              <ControlledTooltip
                title={LOADFILETOOL}
                placement="bottom"
                arrow={true}
                tooltipEnabled={tooltips}
              >
                <SqButton
                  size="small"
                  sx={{width: width}}
                  onClick={() => loadFile()}
                  disabled={disabled}
                >
                  Load File
                </SqButton>
              </ControlledTooltip>
            </Grid>
            <Grid item xs={3}>
              <ControlledTooltip
                title={SAVESYXTOOL}
                placement="bottom"
                arrow={true}
                tooltipEnabled={tooltips}
              >
                <SqButton
                  size="small"
                  sx={{mx: mx, my: my, width: width2}}
                  onClick={() => saveFileSyx()}
                  disabled={disabled}
                >
                  Save Syx
                </SqButton>
              </ControlledTooltip>
              <ControlledTooltip
                title={SAVEJSONTOOL}
                placement="bottom"
                arrow={true}
                tooltipEnabled={tooltips}
              >
                <SqButton
                  size="small"
                  sx={{mx: mx, my: my, width: width2}}
                  onClick={() => saveFileJson()}
                  disabled={disabled}
                >
                  Save JSON
                </SqButton>
              </ControlledTooltip>
            </Grid>
            <If value={getUserInfo().isFactory()}>
              <Grid item xs={3}>
                <SqButton
                  size="small"
                  sx={{mx: mx, my: my, width: width}}
                  onClick={() => {
                    getUi().start_test(setMidiTestText, setMcpTestText);
                  }}
                  disabled={selectedUi === 0 || disabled}
                >
                  Loopback Test
                </SqButton>
              </Grid>
              <Grid item xs={3}>
                {mcpTestText}
              </Grid>
              <Grid item xs={3}>
                {midiTestText}
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  <Typography component={'div'}>
                    Current UI version {uiFwVer}
                  </Typography>
                  <If value={selectedUi !== 0 && !disabled && firmwareMatch}>
                    <Typography component={'div'}>
                      Hardware UI version {router.get(selectedUi)?.firmware}
                    </Typography>
                  </If>
                </Stack>
              </Grid>
            </If>
          </Grid>
        </SqPaper>

        <SqPaper
          variant="outlined"
          sx={{m: 0, mt: 0, mb: 0.5}}
          component="fieldset"
        >
          <legend>
            <TypeOcrH6>Globals</TypeOcrH6>
          </legend>
          <Grid
            container
            spacing={0}
            columns={{xs: 3, sm: 3, md: 5, xl: 12}}
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item xs={1}>
              <Stack>
                <ControlledTooltip
                  title={TOOLTIPTOOL}
                  placement="top"
                  arrow={true}
                  tooltipEnabled={tooltips}
                >
                  <Checkbox
                    checked={tooltips}
                    onChange={(e) => {
                      setTooltips(!tooltips);
                      console.log(tooltips);
                    }}
                    disabled={disabled}
                  />
                </ControlledTooltip>
                Tooltips
                <ControlledTooltip
                  title={USBMIDITOOL}
                  placement="top"
                  arrow={true}
                  tooltipEnabled={tooltips}
                >
                  <Checkbox
                    checked={usb2midi}
                    onChange={(e) => setUsb2midi(!usb2midi)}
                    disabled={disabled}
                  />
                </ControlledTooltip>
                USB to MIDI
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack>
                <ControlledTooltip
                  title={MIDITHRUTOOL}
                  placement="top"
                  arrow={true}
                  tooltipEnabled={tooltips}
                >
                  <Checkbox
                    checked={midiThru}
                    onChange={(e) => setMidiThru(!midiThru)}
                    disabled={disabled}
                  />
                </ControlledTooltip>
                MIDI Thru
                <ControlledTooltip
                  title={CHAINEDTOOL}
                  placement="top"
                  arrow={true}
                  tooltipEnabled={tooltips}
                >
                  <Checkbox
                    checked={chained}
                    onChange={(e) => setChained(!chained)}
                    disabled={disabled}
                  />
                </ControlledTooltip>
                Chained UI
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <ChainChannel />
            </Grid>
            <Grid item xs={1}>
              <ControlledTooltip
                title={TRIGLENTOOL}
                placement="top"
                arrow={true}
                tooltipEnabled={tooltips}
              >
                <SqTextField
                  select
                  label="Trigger Length"
                  size="small"
                  onChange={(e: any) => {
                    setTriggerMs(e.target.value);
                  }}
                  value={triggerMs}
                  style={{width: '8rem'}}
                  sx={{mx: mx, mt: 2}}
                  disabled={disabled}
                >
                  {TimeItem(2)}
                  {TimeItem(3)}
                  {TimeItem(4)}
                  {TimeItem(5)}
                  {TimeItem(10)}
                  {TimeItem(15)}
                  {TimeItem(20)}
                  {TimeItem(25)}
                </SqTextField>
              </ControlledTooltip>

              <ControlledTooltip
                title={CLOCKLENTOOL}
                placement="top"
                arrow={true}
                tooltipEnabled={tooltips}
              >
                <SqTextField
                  select
                  label="Clock Length"
                  onChange={(e: any) => {
                    setClockMs(e.target.value);
                  }}
                  value={clockMs}
                  style={{width: '8rem'}}
                  sx={{mx: mx, mt: 2}}
                  disabled={disabled}
                >
                  {TimeItem(2)}
                  {TimeItem(3)}
                  {TimeItem(4)}
                  {TimeItem(5)}
                  {TimeItem(10)}
                  {TimeItem(15)}
                  {TimeItem(20)}
                  {TimeItem(25)}
                </SqTextField>
              </ControlledTooltip>
            </Grid>
            <Grid item xs={1}>
              <UiPolyBendAmount
                label="MPE/Poly Bend Amt"
                bend={bendScale}
                setBend={setBendScale}
                tooltipEnabled={tooltips}
                disabled={notPoly}
              ></UiPolyBendAmount>

              <VoiceCount />
            </Grid>
          </Grid>
        </SqPaper>

        <SqPaper variant="outlined" sx={{m: 2, mt: 1}} component="fieldset">
          <legend>
            <TypeOcrH6>Outputs</TypeOcrH6>
          </legend>

          <Grid
            container
            spacing={1}
            columns={{xs: 6, sm: 6, md: 9, lg: 12}}
            justifyContent="center"
            alignItems="center"
          >
            {uiOutGrid(0, getOut(0), getSetOut(0))}
            {uiOutGrid(1, getOut(1), getSetOut(1))}
            {uiOutGrid(2, getOut(2), getSetOut(2))}
            {uiOutGrid(3, getOut(3), getSetOut(3))}
            {uiOutGrid(4, getOut(4), getSetOut(4))}
            {uiOutGrid(5, getOut(5), getSetOut(5))}
            {uiOutGrid(6, getOut(6), getSetOut(6))}
            {uiOutGrid(7, getOut(7), getSetOut(7))}
          </Grid>
        </SqPaper>
        <If value={chained}>
          <SqPaper variant="outlined" sx={{m: 2, mt: 1}} component="fieldset">
            <legend>
              <TypeOcrH6>Chained UI</TypeOcrH6>
            </legend>
            <Grid
              container
              spacing={1}
              columns={{xs: 6, sm: 6, md: 9, lg: 12}}
              justifyContent="center"
              alignItems="center"
            >
              {uiOutGrid(8, getOut(8), getSetOut(8))}
              {uiOutGrid(9, getOut(9), getSetOut(9))}
              {uiOutGrid(10, getOut(10), getSetOut(10))}
              {uiOutGrid(11, getOut(11), getSetOut(11))}
              {uiOutGrid(12, getOut(12), getSetOut(12))}
              {uiOutGrid(13, getOut(13), getSetOut(13))}
              {uiOutGrid(14, getOut(14), getSetOut(14))}
              {uiOutGrid(15, getOut(15), getSetOut(15))}
            </Grid>
          </SqPaper>
        </If>
      </SqPaper>
    </>
  );
}
