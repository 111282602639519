import {isWindows} from 'react-device-detect';

import React from 'react';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import FirmwareWizard from './FirmwareWizard';
import IfNotChrome from './IfNotChrome';
import PagePaper from './PagePaper';
import {Progress} from './Progress';
import SqButton from './SqButton';
import SqTextField from './SqTextField';
import Title from './Title';
import {TypeOcr, TypeOcrH5, TypeOcrH6} from './Types';

import {match} from '../ostium/database';
import nedfu from '../ostium/dfu';
import getUserInfo from '../ostium/UserInfo';
import FaqAccordion from './FaqAccordion';
import ResponsiveContainer from './ResponsiveContainer';

export const STATE_CONNECT: string = 'connect';
export const STATE_SELECT: string = 'select';
export const STATE_UPDATE: string = 'update';
export const STATE_FLASH: string = 'flash';
export const BUTTON_TEXT: any = {
  connect: 'Connect Module',
  select: 'Select Firmware',
  update: 'Change Firmware',
  flash: 'Flashing...',
};

interface FirmwareProps {}

const defaultCustomName = 'Select Custom File';

class FirmwareState {
  firmware: string;
  platform: string;
  done: number;
  total: number;
  state: string;
  buttonOn: boolean;
  productText: string;
  customUrl: string;
  customName: string;
  wizOpen: boolean;
  constructor() {
    this.firmware = '';
    this.platform = '';
    this.done = 0;
    this.total = 1;
    this.state = STATE_CONNECT;
    this.buttonOn = true;
    this.productText = '';
    this.customUrl = '';
    this.customName = defaultCustomName;
    this.wizOpen = false;
  }
}

function SimpleAccordion() {
  return (
    <>
      {' '}
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          maxWidth: '85%',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 2,
        }}
      >
        <FaqAccordion name="What do I do if I can't get my module to connect?">
          First, try a different USB cable. USB cables die all the time! Also,
          make sure your cable is connected directly to your computer (and not
          to a USB hub), and that your cable is suitable for data transfer, not
          simply a charging cable. We recommend{' '}
          <a
            href="https://www.electro-smith.com/daisy/micro-usb-cable"
            target="_blank"
            rel="external noreferrer noopener"
          >
            this one from ElectroSmith
          </a>{' '}
          but there are many that work. Next, confirm that you are following
          this procedure:{' '}
          <Typography>
            <li>Connect the module to the computer via USB.</li>
            <li>
              Follow the button press and release combo for the module you have.
              If you are not sure what the correct order is, please see the
              Wizard or video tutorial for assistance.
            </li>
            <li>
              When you release the second button, one LED on the back of the
              module should turn off, and you should be able to connect.
            </li>
            <br></br>
            If you are still struggling try the Wizard. If that still doesn't
            work, hit the Support link above and email us. Let us know what step
            of the wizard you're stuck on so we can best help you!
          </Typography>
        </FaqAccordion>
        <FaqAccordion name="Can I flash my module while it’s still in the case?">
          Unfortunately, you need to be able to access the back of the module to
          change the firmware. In order for the processor to know that it is
          receiving new firmware, there is a button combination that needs to be
          pressed to enter programmable mode. We are not able to do this from
          the front panel.
        </FaqAccordion>

        <FaqAccordion name="My UI update freezes while erasing. What do I do?">
          Some USB controllers are not compatible with the Device Firmware
          Update standard. <br />
          <br />
          This is a weird but known issue with an easy workaround: plug in a hub
          and then plug the module into the hub it will solve this problem.
        </FaqAccordion>
        <FaqAccordion name="The app gives a <No module detected> error message.">
          <Typography>
            We've seen this happen with a small number number of racing and game
            controllers with a conflicting USB driver. Run Zadig, and if a
            device other than "usbser" shows up as the original driver when
            Versio is connected, there may be an issue. <br />
            <br /> Uninstall the driver from your system, restart your computer,
            then repeat the normal Zadig procedure; your module should connect
            normally from then on!
          </Typography>
        </FaqAccordion>
      </Container>
    </>
  );
}

function cleanPath(path: string) {
  const badPrefix = 'C:\\fakepath\\';

  if (path.includes(badPrefix)) {
    path = path.replace(badPrefix, '');
  }

  return path;
}

export default class TabFirmware extends React.Component<
  FirmwareProps,
  FirmwareState
> {
  props: FirmwareProps;
  state: FirmwareState;

  hasProgress: boolean;

  fileDialog: any;

  constructor(props: FirmwareProps) {
    super(props);
    this.props = props;
    this.state = new FirmwareState();

    this.hasProgress = false;

    this.fileDialog = {};
  }

  componentDidMount() {
    nedfu.init(
      (step: string, done: number, total: number) => {
        if (step === 'erase') {
          // console.log(done, total);
          this.dfuProgress(done, 2 * total);
        } else if (step === 'write') {
          // console.log(done, total);
          this.dfuProgress(done + total, 2 * total);
        } else {
          console.log('unknown step:', step);
        }
      },
      (msg: string) => {
        this.dfuError(msg);
      },
      () => {
        this.dfuConnect();
      },
      () => {
        this.dfuDone();
      }
    );
  }

  dfuConnect() {
    this.dfuProgress(0, 100);
    if (this.is(STATE_CONNECT)) {
      if (this.state.firmware !== '') {
        this.set({state: STATE_UPDATE});
      } else {
        this.set({state: STATE_SELECT, buttonOn: false});
      }
    }
  }

  dfuDone() {
    this.set({state: STATE_CONNECT, buttonOn: true});
  }

  dfuError(msg: string) {
    this.set({productText: msg});
  }

  dfuProgress(done: number, total?: number) {
    this.set({done: done, total: total});
  }

  updateState(): void {
    this.setState(this.state);
  }

  set(obj: any) {
    let up = false;

    Object.keys(obj).forEach((k) => {
      let state: any = this.state;
      if (state[k] !== obj[k]) {
        state[k] = obj[k];
        up = true;
      }
    });

    if (up) {
      this.updateState();
    }
  }

  getProgress(): number {
    return this.state.total > 0
      ? (100 * this.state.done) / this.state.total
      : 100;
  }

  getProgressVis(): string {
    if (this.hasProgress || this.is(STATE_FLASH)) {
      this.hasProgress = true;
      return 'visible';
    } else {
      return 'hidden';
    }
  }

  getButtonVisLearn(): string {
    if (this.getShopUrl() !== '') {
      return 'visible';
    } else {
      return 'hidden';
    }
  }

  getButtonVisVid(): string {
    if (this.state.firmware === defaultCustomName) {
      return 'hidden';
    }
    if (this.state.firmware !== '') {
      return 'visible';
    } else {
      return 'hidden';
    }
  }

  is(state: string): boolean {
    return this.state.state === state;
  }

  getImage(): string {
    return this.getFirmwareImage(this.state.platform, this.state.firmware);
  }

  getFirmwareImage(pfname: string, fwname: string): string {
    if (fwname === '') {
      const pf = match('code', pfname, getUserInfo().platforms);
      return pf ? pf.image : 'panels/versio.png';
    } else {
      const fw = match('fullname', fwname, getUserInfo().firmwares);
      return fw ? fw.image : 'panels/versio.png';
    }
  }

  getPlatformFirmwareName(): string {
    const fwname = this.state.firmware;
    if (fwname === defaultCustomName) {
      return '';
    }
    if (fwname === '') {
      const pf = match('code', this.state.platform, getUserInfo().platforms);
      return pf?.name || '';
    } else {
      const fw = match('fullname', fwname, getUserInfo().firmwares);
      return fw.name;
    }
  }
  getPlatformImage(): string {
    return this.getFirmwareImage(this.state.platform, '');
  }

  getDescription(): string {
    return this.GetDescFromFw(this.state.platform, this.state.firmware);
  }

  GetDescFromFw(pfname: string, fwname: string): string {
    if (fwname === defaultCustomName) {
      return 'Use your own firmware file!';
    }
    if (fwname === '') {
      const pf = match('code', pfname, getUserInfo().platforms);
      return pf ? pf.desc : 'Choose a platform to get started.';
    } else {
      const fw = match('fullname', fwname, getUserInfo().firmwares);
      return fw ? fw.medDesc : '';
    }
  }

  getYouTubeUrl(): string {
    return this.getFwField('youTubeUrl', this.state.firmware);
  }

  getFwField(field: string, fwname: string): string {
    if (fwname === defaultCustomName) {
      return '';
    }
    if (fwname === '') {
      return '';
    } else {
      const fw = match('fullname', fwname, getUserInfo().firmwares);
      return fw ? fw[field] : '';
    }
  }

  getShopUrl(): string {
    const fwname = this.state.firmware;
    const pfname = this.state.platform;
    if (fwname === defaultCustomName) {
      return '';
    }
    if (fwname !== '') {
      const fw = match('fullname', fwname, getUserInfo().firmwares);
      return fw.shopUrl;
    }
    if (pfname !== '') {
      const pf = match('code', pfname, getUserInfo().platforms);
      return pf.shopUrl || '';
    }
    return '';
  }

  update() {
    if (this.is(STATE_CONNECT)) {
      this.set({productText: ''});
      nedfu.connect();
      this.hasProgress = false;
    } else if (this.is(STATE_SELECT) && this.state.firmware !== '') {
      this.set({state: STATE_UPDATE});
    } else if (this.is(STATE_UPDATE)) {
      this.set({state: STATE_FLASH, buttonOn: false});
      const fw = match(
        'fullname',
        this.state.firmware,
        getUserInfo().firmwares
      );

      nedfu.fetchAndFlash(async () => {
        if (this.state.customUrl) {
          return await this.getCustomBin();
        } else {
          return await fw.getBin();
        }
      });
    } else if (this.is(STATE_FLASH)) {
      this.set({state: STATE_CONNECT});
    }
  }

  async getCustomBin() {
    const res = await fetch(this.state.customUrl);
    return await res.arrayBuffer();
  }

  setFirmware(fw: string) {
    this.set({firmware: fw, customName: defaultCustomName, customUrl: ''});

    if (fw === defaultCustomName) {
      this.fileDialog.click();
    } else {
      if (this.is(STATE_SELECT) && fw !== '') {
        this.set({state: STATE_UPDATE, buttonOn: true});
      }
    }
  }

  setPlatform(plat: string) {
    this.set({platform: plat});
    const fws = getUserInfo().findFirmware(plat);

    var haveFw: boolean = false;

    if (this.is(STATE_UPDATE) || this.is(STATE_FLASH)) {
      this.set({state: STATE_SELECT, buttonOn: haveFw});
    }

    if (fws.length === 1) {
      this.setFirmware(fws[0].fullname);
    } else {
      this.set({firmware: ''});
    }
  }

  SelectBar() {
    return (
      <Grid
        container
        spacing={4}
        columns={{xs: 6, lg: 14}}
        style={{justifyContent: 'space-evenly', alignItems: 'center'}}
      >
        <Grid xs={2} lg={2}>
          <SqButton
            style={{width: 150, height: 40}}
            sx={{mx: 0, mt: 0, mb: 1}}
            onClick={() => {
              this.set({wizOpen: true});
            }}
          >
            Open Wizard
          </SqButton>
        </Grid>
        <Grid xs={4} lg={4}>
          <SqTextField
            SelectProps={{
              renderValue: (p: any) => {
                if (!p) {
                  return '';
                }
                const pl: any = match('code', p, getUserInfo().platforms);
                if (!pl) {
                  return '';
                }
                return <TypeOcr align="left">{pl.name}</TypeOcr>;
              },
            }}
            select
            size="small"
            onChange={(e: any) => {
              this.setPlatform(e.target.value as string);
            }}
            value={this.state.platform}
            style={{width: 200, height: 40}}
            sx={{mx: 0, my: 0, mt: 0, mb: 1}}
            label="Platform"
          >
            {getUserInfo().platforms.map((value) => {
              return (
                <MenuItem key={value.code} value={value.code}>
                  <Stack>
                    <TypeOcr align="left">{value.name}</TypeOcr>
                    {value.shortDesc}
                  </Stack>
                </MenuItem>
              );
            })}
          </SqTextField>
        </Grid>
        <Grid xs={4} lg={5}>
          <SqTextField
            SelectProps={{
              renderValue: (p: any) => {
                if (!p) {
                  return '';
                }
                if (p === defaultCustomName) {
                  return this.state.customName;
                }
                const fw: any = match('fullname', p, getUserInfo().firmwares);
                if (!fw) {
                  return '';
                }
                return <TypeOcr align="left">{fw.name}</TypeOcr>;
              },
            }}
            select
            size="small"
            onChange={(e: any) => {
              this.setFirmware(e.target.value);
            }}
            value={this.state.firmware}
            style={{width: 300, height: 40}}
            sx={{mx: 0, my: 0, mt: 0, mb: 1, mr: 0}}
            label="Firmware"
          >
            {getUserInfo()
              .findFirmware(this.state.platform)
              .map((value: any) => {
                return (
                  <MenuItem key={value.fullname} value={value.fullname}>
                    <Stack style={{width: 370, height: 50}}>
                      <TypeOcr>{value.fullname}</TypeOcr>
                      <Typography overflow="hidden">
                        {value.shortDesc}
                      </Typography>
                    </Stack>
                  </MenuItem>
                );
              })}
            <MenuItem key={defaultCustomName} value={defaultCustomName}>
              {this.state.customName}
            </MenuItem>
            ;
          </SqTextField>
        </Grid>
        <Grid xs={3} lg={3}>
          <SqButton
            onClick={() => this.update()}
            disabled={!this.state.buttonOn}
            style={{width: 200, height: 40}}
            sx={{mx: 0, mt: 0, mb: 1, ml: 0}}
          >
            {BUTTON_TEXT[this.state.state]}
          </SqButton>
        </Grid>
      </Grid>
    );
  }

  getTutorialUrl() {
    if (isWindows) {
      if (this.state.platform === 'vi') {
        return 'https://youtube.com/embed/-9vtpunH5w8';
      } else {
        return 'https://youtube.com/embed/gW_IgmEkz8M';
      }
    } else {
      if (this.state.platform === 'vi') {
        return 'https://youtube.com/embed/HwkTXchLvNk';
      } else {
        return 'https://youtube.com/embed/bbg3xhVx5p0';
      }
    }
  }

  render() {
    const setCustom = async (name: string, url: string) => {
      if (this.is(STATE_SELECT)) {
        this.set({state: STATE_UPDATE, buttonOn: true});
      }
      this.set({
        customName: cleanPath(name),
        customUrl: url,
        platform: '',
      });
    };

    const setFirmware = (s: string) => {
      this.setFirmware(s);
    };
    const setPlatform = (s: string) => {
      this.setPlatform(s);
    };

    return (
      <>
        <input
          id="fileSelect"
          type="file"
          ref={(ref) => (this.fileDialog = ref)}
          hidden
          onChange={(e: any) => {
            if (e.target.value) {
              setCustom(e.target.value, URL.createObjectURL(e.target.files[0]));
            }
          }}
        />

        <FirmwareWizard
          isOpen={() => {
            return this.state.wizOpen;
          }}
          close={() => {
            this.set({wizOpen: false});
          }}
          getFirmware={() => {
            return this.state.firmware;
          }}
          setFirmware={(f: string) => {
            setFirmware(f);
          }}
          getPlatform={() => {
            return this.state.platform;
          }}
          setPlatform={(p: string) => {
            setPlatform(p);
          }}
          getButtonOn={() => {
            return this.state.buttonOn;
          }}
          getButtonText={() => {
            return BUTTON_TEXT[this.state.state];
          }}
          goButton={() => {
            this.update();
          }}
          getConnectState={() => {
            return this.state.state;
          }}
          getProgress={() => {
            return this.getProgress();
          }}
          getProgressVis={() => {
            return this.getProgressVis();
          }}
          getErrorText={() => {
            return this.state.productText;
          }}
        />

        <Title>Firmware</Title>

        <PagePaper>
          <ResponsiveContainer>
            <Container
              sx={{m: 5, mt: 3}}
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 2,
                maxWidth: '85%',
              }}
            >
              Click the Wizard button for a step-by-step guide or check out our
              handy video.
              <br />
              Click on the Firmware dropdown to upload custom firmware.
              <br />
              <IfNotChrome>
                <b>
                  This feature is only supported on Google Chrome and other
                  Chromium-based browsers that support webUSB.
                </b>
              </IfNotChrome>
            </Container>

            {this.SelectBar()}

            <Box sx={{mx: 0, my: 0, width: '70%'}}>
              <Progress
                value={this.getProgress()}
                visibility={this.getProgressVis()}
              />
            </Box>
          </ResponsiveContainer>

          <Grid
            container
            spacing={1}
            style={{justifyContent: 'center', alignItems: 'center'}}
          >
            <Grid xs={3} sx={{mx: 2, my: 2, width: 300}}>
              <img
                alt="Panel for selected product"
                src={this.getImage()}
                height="400"
              />
            </Grid>
            <Grid sx={{mx: 0, my: 0, mt: 0, mr: 0}} width={500}>
              <Typography
                component="div"
                sx={{m: 0, fontStyle: 'italic'}}
                align="center"
              >
                {this.state.productText}
              </Typography>
              <Grid
                sx={{mx: 0, my: 0, mt: 0, mr: 0}}
                style={{justifyContent: 'center'}}
              >
                <TypeOcrH5 variant="h5" sx={{m: 2}} align="center">
                  {this.getPlatformFirmwareName()}
                </TypeOcrH5>
                <Grid container spacing={0} columns={{xs: 3}}>
                  <Grid xs={3}>
                    <ResponsiveContainer maxWidth="85%">
                      <Typography
                        component="div"
                        sx={{m: 3}}
                        align="center"
                        height={'auto'}
                      >
                        {this.getDescription()}
                      </Typography>
                    </ResponsiveContainer>
                    <Grid justifyContent="space-evenly">
                      <SqButton
                        sx={{mb: 3, mr: 0, visibility: this.getButtonVisVid()}}
                        onClick={() =>
                          window.open(
                            this.getYouTubeUrl() + '?rel="noopener noreferrer"',
                            '_blank'
                          )
                        }
                      >
                        Demo Video
                      </SqButton>
                    </Grid>
                    <Grid>
                      <SqButton
                        sx={{
                          mb: 3,
                          ml: 0,
                          visibility: this.getButtonVisLearn(),
                        }}
                        onClick={() =>
                          window.open(
                            this.getShopUrl() + '?rel="noopener noreferrer"',
                            '_blank'
                          )
                        }
                      >
                        Learn More
                      </SqButton>
                    </Grid>{' '}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            style={{justifyContent: 'center', alignItems: 'center'}}
            columns={{xs: 3, sm: 6, md: 12}}
          >
            <Grid xs={6}>
              <ResponsiveContainer maxWidth="85%">
                <Typography component="div" align="center" sx={{mx: 1, mt: 2}}>
                  <TypeOcrH6 align="center">Video Tutorial</TypeOcrH6>
                  Need help swapping your firmware?
                  <br /> We put together a handy video to help.
                </Typography>
              </ResponsiveContainer>
            </Grid>

            <Grid xs={5}>
              <CardMedia
                sx={{m: 0}}
                height="315"
                component="iframe"
                image={this.getTutorialUrl()}
              />
            </Grid>
          </Grid>

          <ResponsiveContainer>
            <Box sx={{mt: 3}}></Box>
            <Grid
              container
              style={{justifyContent: 'center', alignItems: 'center'}}
              columns={{xs: 3, sm: 6, md: 12}}
              sx={{mb: 2}}
            >
              <Grid xs={3}>
                <ResponsiveContainer maxWidth="85%">
                  <Typography component="div" sx={{m: 3, mr: 5}} align="center">
                    <TypeOcrH6 align="center"> Troubleshoot</TypeOcrH6>
                    You have questions? We have answers. Still not sorted? Get
                    in touch with the button at the top of the page.
                  </Typography>
                </ResponsiveContainer>
              </Grid>
              <Grid xs={9}>
                <SimpleAccordion></SimpleAccordion>
              </Grid>
            </Grid>
          </ResponsiveContainer>
        </PagePaper>
      </>
    );
  }
}
